import { Stack, Text } from '@mantine/core';
import { usePrefillMultistepFormContext } from '../../pages/prefill/prefill-multi-step-form.context';
import { VerificationCodeInput } from '../verify-code/verify-code';
import { useAddFactorsContext } from '../../../../providers/add-factors.context';
import { formatPhoneForApi } from '@utilities/formatters/format-phone-number';
import { useInvalidateMeQuery } from '@queries/use-get-me';

export const PrefillVerifyPhoneStep = () => {
  const {
    state: { phoneVerification },
    setState,
    goToNextStep,
  } = usePrefillMultistepFormContext();

  const { verifyFactor, savePhone, resendOtpSmsVerification } =
    useAddFactorsContext();

  const { invalidate: invalidatePlatformMeQuery } = useInvalidateMeQuery();

  const handleCodeChange = async (value: string) => {
    setState({ loading: true, error: null });

    if (phoneVerification) {
      try {
        await verifyFactor(phoneVerification.methodId, 'otp', value);
        await savePhone(phoneVerification.phoneNumber);
        await invalidatePlatformMeQuery(); // repopulate the query cache before the next step
        goToNextStep({ phoneVerified: true, phoneVerification: null });
      } catch {
        handleError('The code you entered is invalid or expired.');
      }
    } else {
      handleError(
        'Code verification unavailable. Navigate to the previous step and confirm your phone number.',
      );
    }
  };

  const handleResendCodeClick = async () => {
    setState({ loading: true, error: null });
    try {
      const formattedPhone = `+1${formatPhoneForApi(
        phoneVerification!.phoneNumber,
      )}`;
      const { methodId } = await resendOtpSmsVerification({
        factorType: 'phone',
        value: formattedPhone,
      });
      setState({
        phoneVerification: {
          methodId,
          phoneNumber: phoneVerification!.phoneNumber,
        },
      });
    } catch {
      handleError(
        'Could not re-send SMS code. Navigate to the previous step and confirm your phone number.',
      );
    } finally {
      setState({ loading: false });
    }
  };

  const handleError = (error: string) => {
    setState({
      error,
      loading: false,
    });
  };

  return (
    <Stack>
      <Text fz="0.875rem" fw={500}>
        A 6 digit verification code has been sent to the phone number ending
        in&nbsp;
        {phoneVerification?.phoneNumber?.slice(-4) || '****'}
      </Text>
      <VerificationCodeInput
        onCodeSubmit={handleCodeChange}
        onResendCodeClick={handleResendCodeClick}
      />
    </Stack>
  );
};
