import {
  Box,
  Button,
  createStyles,
  Group,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { CloseIcon, PlusSignIcon, SearchIcon } from 'assets/svg';
import { useMediaQuery } from '@mantine/hooks';
import { isTruthyString } from '@utilities/validators/validate-string';
import { useNavigate } from 'react-router-dom';
import { IoFunnelOutline } from 'react-icons/io5';
import { FilterConfig, FilterModal } from '@common/filter/filter-modal';
import useModal from '@common/modal/modal-hook';
import { useEffect, useRef } from 'react';
import { ActiveFiltersReturnType } from '@common/filter/filters';
import { UserInfoState } from 'states/user/user-info';
import { useRecoilValue } from 'recoil';
import { PaymentWithInvoiceNumber } from '../payments/payments';

type Props = {
  placeholder: string;
  data?: PaymentWithInvoiceNumber[];
  filtersData?: FilterConfig[];
  showFilters?: boolean;
  useFilters: () => ActiveFiltersReturnType<any>;
  useFiltersModal: () => ActiveFiltersReturnType<any>;
};

const Header = ({
  placeholder,
  data,
  filtersData = [],
  useFilters,
  useFiltersModal,
}: Props) => {
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const user = useRecoilValue(UserInfoState);
  const isBookkeeper = user.roles.includes('ACCOUNTANT');

  const {
    removeFilter,
    getFilterByKey,
    addFilter,
    removeAllFilters,
    activeFilters: activeTableFilters,
    activeFiltersArray: activeTableFiltersArray,
    applyAllFilters: applyAllTableFilters,
  } = useFilters();

  const {
    activeFilters: activeModalFilters,
    applyAllFilters: applyAllModalFilters,
  } = useFiltersModal();

  const activeModalFiltersRef = useRef(activeModalFilters);

  useEffect(() => {
    activeModalFiltersRef.current = activeModalFilters;
  }, [activeModalFilters]);

  const modal = useModal();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const handleCreateInvoice = () => {
    navigate('/bill-pay/new');
  };

  const handleSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (item) => {
        const normalizedFilterText = value.toLowerCase();
        const strValues = Object.values(item).filter(isTruthyString);
        return strValues
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const inputWidth = useMobileView ? '100%' : 368;

  const handleModalSave = () => {
    applyAllTableFilters(activeModalFiltersRef.current);
    modal.closeAllModals();
  };

  const handleModalCancel = () => {
    modal.closeAllModals();
  };

  const filterChips = activeTableFiltersArray.filter((f) => f.showChip);

  const openModal = () => {
    applyAllModalFilters(activeTableFilters);

    screen.width <= 767
      ? modal.openFullModal(
          <FilterModal
            filters={filtersData}
            onSave={handleModalSave}
            onCancel={handleModalCancel}
          />,
        )
      : modal.openRightModal(
          <FilterModal
            filters={filtersData}
            onSave={handleModalSave}
            onCancel={handleModalCancel}
          />,
        );
  };

  return (
    <>
      <Group position="apart" mb="md">
        <Box
          sx={() => ({
            display: 'flex',
            gap: theme.spacing.md,
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          })}
          mb="md"
        >
          <Box
            sx={() => ({
              display: 'flex',
              gap: theme.spacing.md,
              [theme.fn.smallerThan('sm')]: {
                flexDirection: 'column',
                width: '100%',
              },
            })}
          >
            <TextInput
              aria-label={placeholder}
              w={inputWidth}
              placeholder={placeholder}
              icon={<SearchIcon width={20} height={20} />}
              value={getFilterByKey('search')?.filterValue || ''}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
            <Button
              variant="outline"
              rightIcon={<IoFunnelOutline />}
              onClick={() => openModal()}
              disabled={data?.length === 0}
            >
              Filters
            </Button>
          </Box>
        </Box>
        {!isBookkeeper && (
          <Group>
            <Button
              type="button"
              bg={'primarySecondarySuccess.6'}
              leftIcon={<PlusSignIcon width={12} height={12} />}
              onClick={handleCreateInvoice}
            >
              Add New Bill
            </Button>
          </Group>
        )}
      </Group>
      {filterChips.length > 0 && (
        <Box
          sx={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}
          mih={30}
          mb="md"
        >
          {filterChips.map((p) => (
            <Box
              sx={() => ({
                height: 30,
                borderRadius: theme.radius.xs,
                border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
                backgroundColor: theme.fn.themeColor('neutral', 2),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                padding: `0px ${theme.spacing.md}`,
                gap: 4,
                [theme.fn.smallerThan('md')]: {
                  height: 'auto',
                  padding: `0px ${theme.spacing.xl}`,
                },
              })}
              onClick={() => {
                removeFilter(p.key);
              }}
              key={p.key}
            >
              <Text color="black" fz={14} fw={500}>
                {p.label}
              </Text>
              <CloseIcon color="black" style={{ marginLeft: 'auto' }} />
            </Box>
          ))}
          <Text
            className={classes.clearFilters}
            onClick={() => {
              removeAllFilters();
            }}
          >
            Clear filters
          </Text>
        </Box>
      )}
    </>
  );
};

export default Header;

const useStyles = createStyles((theme) => ({
  clearFilters: {
    cursor: 'pointer',
    color: theme.fn.themeColor('primarySecondarySuccess', 2),
  },
}));
