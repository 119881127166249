import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/filter/filters';
import { CreditTransactionsTableRow } from '../credit-transactions-table-helpers';

const CreditTransactionsFilterState = atom<
  FilterFnMap<CreditTransactionsTableRow>
>({
  key: 'credit_transactions_filter_state',
  default: {},
});

const CreditTransactionsFilterStateModal = atom<
  FilterFnMap<CreditTransactionsTableRow>
>({
  key: 'credit_transactions_filter_state_modal',
  default: {},
});

export function useCreditTransactionFilters(): ActiveFiltersReturnType<CreditTransactionsTableRow> {
  return createUseFiltersHook<CreditTransactionsTableRow>(
    CreditTransactionsFilterState,
  );
}

export function useCreditTransactionModalFilters(): ActiveFiltersReturnType<CreditTransactionsTableRow> {
  return createUseFiltersHook<CreditTransactionsTableRow>(
    CreditTransactionsFilterStateModal,
  );
}

const ChargeCardTransactionsFilterState = atom<
  FilterFnMap<CreditTransactionsTableRow>
>({
  key: 'charge_card_transactions_filter_state',
  default: {},
});

const ChargeCardTransactionsFilterStateModal = atom<
  FilterFnMap<CreditTransactionsTableRow>
>({
  key: 'charge_card_transactions_filter_state_modal',
  default: {},
});

export function useChargeCardTransactionFilters(): ActiveFiltersReturnType<CreditTransactionsTableRow> {
  return createUseFiltersHook<CreditTransactionsTableRow>(
    ChargeCardTransactionsFilterState,
  );
}

export function useChargeCardTransactionModalFilters(): ActiveFiltersReturnType<CreditTransactionsTableRow> {
  return createUseFiltersHook<CreditTransactionsTableRow>(
    ChargeCardTransactionsFilterStateModal,
  );
}
