import { TableProps } from 'react-data-table-component/dist/DataTable/types';
import SkeletonLoading from 'components/loading/skeleton-loading';
import DataTable from 'react-data-table-component';
import { baseTableStyles } from 'components/styles';
import { ArrowDownIcon } from 'assets/svg';
import { useEffect, useState } from 'react';
import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { FlexbaseTableBulkActions } from './components/bulk-actions';
import {
  FlexbaseTableBulkActionsConfig,
  FlexbaseTableSelectedState,
} from './flexbase-table.models';

type FlexbaseTableProps<T> = {
  keyField?: TableProps<T>['keyField'];
  columns: TableProps<T>['columns'];
  data: TableProps<T>['data'];
  title?: TableProps<T>['title'];
  striped?: TableProps<T>['striped'];
  disabled?: TableProps<T>['disabled'];
  sortFunction?: TableProps<T>['sortFunction'];
  selectableRows?: TableProps<T>['selectableRows'];
  fixedHeader?: TableProps<T>['fixedHeader'];
  fixedHeaderScrollHeight?: TableProps<T>['fixedHeaderScrollHeight'];
  subHeader?: TableProps<T>['subHeader'];
  pagination?: TableProps<T>['pagination'];
  customStyles?: TableProps<T>['customStyles'];
  highlightOnHover?: TableProps<T>['highlightOnHover'];
  noDataComponent?: TableProps<T>['noDataComponent'];
  onRowClicked?: TableProps<T>['onRowClicked'];
  defaultSortAsc?: TableProps<T>['defaultSortAsc'];
  conditionalRowStyles?: TableProps<T>['conditionalRowStyles'];
  pointerOnHover?: TableProps<T>['pointerOnHover'];
  selectableRowsComponent?: TableProps<T>['selectableRowsComponent'];
  selectableRowsComponentProps?: TableProps<T>['selectableRowsComponentProps'];
  onSelectedRowsChange?: TableProps<T>['onSelectedRowsChange'];
  clearSelectedRows?: TableProps<T>['clearSelectedRows'];
  dense?: TableProps<T>['dense'];
  noHeader?: TableProps<T>['noHeader'];
  noTableHead?: TableProps<T>['noTableHead'];
  defaultSortFieldId?: TableProps<T>['defaultSortFieldId'];
  expandableRows?: TableProps<T>['expandableRows'];
  expandableRowsComponent?: TableProps<T>['expandableRowsComponent'];
  expandOnRowClicked?: TableProps<T>['expandOnRowClicked'];
  onChangeRowsPerPage?: TableProps<T>['onChangeRowsPerPage'];
  onChangePage?: TableProps<T>['onChangePage'];
  onSort?: TableProps<T>['onSort'];
  paginationComponent?: TableProps<T>['paginationComponent'];
  paginationComponentOptions?: TableProps<T>['paginationComponentOptions'];
  bulkActionsConfig?: FlexbaseTableBulkActionsConfig<T>;
  withTabs?: boolean;
  isFetchingData?: boolean;
  errorMessage?: string;
};

export const FlexbaseTable = <T,>(props: FlexbaseTableProps<T>) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [clearSelectedRows, setClearSelectedRows] = useState(
    props.clearSelectedRows ?? false,
  );
  const [selectedState, setSelectedState] = useState<
    FlexbaseTableSelectedState<T>
  >({
    allSelected: false,
    selectedCount: 0,
    selectedRows: [],
  });

  const defaultPaginationOptions: TableProps<T>['paginationComponentOptions'] =
    isMobile
      ? {
          noRowsPerPage: true,
          rowsPerPageText: 'Rows/Pg',
          rangeSeparatorText: 'of',
          selectAllRowsItem: false,
        }
      : {};

  const clearSelection = () => {
    setClearSelectedRows((x) => !x);

    if (selectedState.selectedCount > 0) {
      setSelectedState({
        allSelected: false,
        selectedCount: 0,
        selectedRows: [],
      });
    }
  };

  const handleSelectedRowsChange: TableProps<T>['onSelectedRowsChange'] = (
    args,
  ) => {
    setSelectedState(args);
    props.onSelectedRowsChange?.(args);
  };

  const handleBulkActionsClose = () => {
    clearSelection();
  };

  useEffect(() => {
    clearSelection();
  }, [props.clearSelectedRows]);

  return props.isFetchingData ? (
    <SkeletonLoading withTabs={props.withTabs || false}></SkeletonLoading>
  ) : (
    <>
      <DataTable
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        onChangePage={props.onChangePage}
        onSort={props.onSort}
        paginationComponent={props.paginationComponent}
        paginationComponentOptions={
          props.paginationComponentOptions || defaultPaginationOptions
        }
        title={props.title}
        columns={props.columns}
        data={props.data}
        striped={props.striped || false}
        disabled={props.disabled}
        sortFunction={props.sortFunction}
        selectableRows={props.selectableRows}
        selectableRowsComponent={props.selectableRowsComponent}
        selectableRowsComponentProps={props.selectableRowsComponentProps}
        onSelectedRowsChange={handleSelectedRowsChange}
        clearSelectedRows={clearSelectedRows}
        fixedHeader={props.fixedHeader}
        fixedHeaderScrollHeight={props.fixedHeaderScrollHeight}
        subHeader={props.subHeader}
        pagination={props.pagination ?? true}
        customStyles={props.customStyles || baseTableStyles(theme)}
        highlightOnHover={props.highlightOnHover}
        noDataComponent={props.noDataComponent}
        onRowClicked={props.onRowClicked}
        defaultSortAsc={props.defaultSortAsc}
        sortIcon={
          <ArrowDownIcon
            style={{
              width: '12px',
              height: '12px',
              marginLeft: '4px',
              marginTop: '0.3rem',
            }}
            fill="#5F5F5F"
          />
        }
        conditionalRowStyles={props.conditionalRowStyles}
        dense={props.dense}
        noHeader={props.noHeader}
        noTableHead={props.noTableHead}
        pointerOnHover={props.pointerOnHover}
        defaultSortFieldId={props.defaultSortFieldId}
        expandableRows={props.expandableRows}
        expandableRowsComponent={props.expandableRowsComponent}
        expandOnRowClicked={props.expandOnRowClicked}
        keyField={props.keyField}
      />
      <FlexbaseTableBulkActions
        selectedState={selectedState}
        label={props.bulkActionsConfig?.label}
        actions={props.bulkActionsConfig?.actions}
        onClose={handleBulkActionsClose}
      />
    </>
  );
};
export default FlexbaseTable;
