import {
  ExpensesStatusEnum,
  SyncedExpenses,
} from '@flexbase-eng/types/dist/accounting';
import { showNotification } from '@mantine/notifications';
import { useSyncTransactionsMutation } from '@queries/use-integrations';
import { SyncTransactionPayload } from '../credit-transactions.reducer';

type Props = {
  connectionId: string;
  syncedExpenses: SyncedExpenses | undefined;
};

type SyncTransactionsResult = {
  success: boolean;
  error?: Error;
  data: { transactionId: string; success: boolean }[];
};

export const useSyncTransactionExpenses = ({
  connectionId,
  syncedExpenses,
}: Props) => {
  const { mutateAsync: syncTransactionsMutateAsync, isPending } =
    useSyncTransactionsMutation();

  const syncTransactions = async (
    transactionsToSync: SyncTransactionPayload[],
    options?: {
      successMessage?: ({
        results,
      }: {
        results: SyncTransactionsResult[];
      }) => string;
      partialSuccessMessage?: ({
        results,
      }: {
        results: SyncTransactionsResult[];
      }) => string;
    },
  ) => {
    const syncRequests = createSyncRequests(transactionsToSync);
    const syncResults = await Promise.all(syncRequests);

    if (syncResults.length && syncResults.every((result) => !result.success)) {
      const errString = syncResults.map((result) => result.error);
      console.error('Failed to sync transactions:', errString);

      showNotification({
        title: 'Error',
        color: 'red',
        message: 'Sync failed. Please try again later.',
      });

      return syncResults;
    }

    const noChanges = !syncResults.length;
    const allSuccess = syncResults.every((result) => result.success);

    if (noChanges) {
      showNotification({
        title: 'Info',
        message: 'No changes to sync',
      });
    } else if (allSuccess) {
      showNotification({
        title: 'Success!',
        color: 'flexbase-teal',
        message:
          options?.successMessage?.({ results: syncResults }) ||
          'Sync started successfully',
      });
    } else {
      showNotification({
        title: 'Error',
        color: 'red',
        message:
          options?.partialSuccessMessage?.({ results: syncResults }) ||
          'Some transactions failed to sync. Please try again later.',
      });
    }

    return syncResults;

    /**
     * Group transactions into POST or PATCH requests based on whether the
     * transaction is being synced for the first time or not.
     */
    function createSyncRequests(transactions: SyncTransactionPayload[]) {
      const createTxs: SyncTransactionPayload[] = [];
      const updateTxs: SyncTransactionPayload[] = [];
      const requests: Promise<SyncTransactionsResult>[] = [];

      const syncedStatusMap =
        syncedExpenses?.expenses?.reduce<
          Partial<Record<string, ExpensesStatusEnum>>
        >((acc, syncedTx) => {
          acc[syncedTx.transactionId] = syncedTx.status;
          return acc;
        }, {}) || {};

      transactions.forEach((transaction) => {
        const syncStatus = syncedStatusMap[transaction.transactionId];

        if (syncStatus === ExpensesStatusEnum.NotSynced) {
          createTxs.push(transaction);
        } else if (syncStatus) {
          updateTxs.push(transaction);
        }
      });

      if (createTxs.length) {
        requests.push(makeSyncRequest(createTxs, 'post'));
      }

      if (updateTxs.length) {
        requests.push(makeSyncRequest(updateTxs, 'patch'));
      }

      return requests;
    }

    function makeSyncRequest(
      transactions: SyncTransactionPayload[],
      method: 'post' | 'patch',
    ) {
      const toResultData =
        (success: boolean) =>
        ({ transactionId }: SyncTransactionPayload) => ({
          transactionId,
          success,
        });

      return syncTransactionsMutateAsync({
        connectionId,
        transactions,
        method,
      }).then<SyncTransactionsResult, SyncTransactionsResult>(
        () => ({
          success: true,
          data: transactions.map(toResultData(true)),
        }),
        (error: Error) => ({
          success: false,
          error,
          data: transactions.map(toResultData(false)),
        }),
      );
    }
  };

  return {
    /**
     * Given a list of transaction payloads, group them into those that are
     * syncing for the first time vs those that are being updated, then makes
     * POST and/or PATCH calls as needed.
     *
     * Payloads that are unable to be synced are ignored.
     */
    syncTransactions,
    isPending,
  };
};
