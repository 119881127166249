import { Box, Flex, Image } from '@mantine/core';
import { useInvoiceWizard } from '../../invoice-wizard';
import { ReactNode, useEffect, useMemo } from 'react';
import { useWizardStyles } from '@common/wizard/wizard.styles';

const UploadInvoicePreview = ({
  hideIfNoImage,
}: {
  hideIfNoImage?: boolean;
}) => {
  const { state } = useInvoiceWizard();
  const { uploadedDocument, existingDocument } = state;
  const document = uploadedDocument || existingDocument;

  const blobUrl = useMemo(() => {
    if (!document) return null;
    return URL.createObjectURL(document);
  }, [document]);

  useEffect(() => {
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [blobUrl]);

  if (!document) {
    if (hideIfNoImage) {
      return null;
    }
    return (
      <Wrapper>
        <Flex justify="center" align="center" h="300px">
          Bill image will appear here
        </Flex>
      </Wrapper>
    );
  }

  const imageUrl = URL.createObjectURL(document);
  if (document.type === 'application/pdf') {
    return (
      <Wrapper>
        <object
          data={`${blobUrl}#toolbar=0`}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Image src={imageUrl} alt="invoice preview" />
    </Wrapper>
  );
};

const Wrapper = ({ children }: { children?: ReactNode }) => {
  const { cx, classes } = useWizardStyles();
  return (
    <Box className={cx(classes.previewPanel, classes.scrollContainer)}>
      <Box h="100%" sx={{ zIndex: 10 }}>
        {children}
      </Box>
    </Box>
  );
};

export default UploadInvoicePreview;
