import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    padding: '0.75rem',
    backgroundColor: theme.colors.neutral[0],
    borderRadius: theme.defaultRadius,
    maxWidth: '85.3rem',
    margin: 'auto',
  },
  header: {
    borderBottom: 'solid',
    borderBottomColor: theme.colors.flexbaseGrey[0],
    borderBottomWidth: '0.031rem',
    paddingBottom: '1rem',
    paddingTop: '0.75rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      gap: 15,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));
