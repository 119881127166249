import { filterDirectionToLabel } from '@common/filter/amount-filter';
import { formatCurrency } from '@flexbase-eng/web-components';
import { QuantityFilter } from '@common/filter/quantity-filter';
import { ActiveFiltersReturnType } from '@common/filter/filters';
import { CreditTransactionsTableRow } from '../../credit-transactions-table-helpers';
import {
  useChargeCardTransactionModalFilters,
  useCreditTransactionModalFilters,
} from '../use-credit-transaction-filters';

type CreditTransactionsAmountFilterProps = {
  filterHook: ActiveFiltersReturnType<CreditTransactionsTableRow>;
};

const CreditAmountFilter = ({
  filterHook,
}: CreditTransactionsAmountFilterProps) => {
  const { addFilter, getFilterByKey } = filterHook;
  return (
    <QuantityFilter
      value={
        getFilterByKey('amount')?.filterValue || {
          amount: '',
          direction: 'equal',
        }
      }
      onChange={(val) =>
        addFilter('amount', {
          label: `Amount - ${filterDirectionToLabel(
            val.direction,
          )} ${formatCurrency(val.amount)}`,
          key: 'amount',
          fn: (row) => {
            const amountNum = Math.abs(+row.amount);
            const filterNum = +val.amount;
            switch (val.direction) {
              case 'greaterThan':
                return amountNum > filterNum;
              case 'lessThan':
                return amountNum < filterNum;
              case 'equal':
              default:
                return amountNum === filterNum;
            }
          },
          filterValue: val,
          showChip: true,
        })
      }
    />
  );
};

export const CreditTransactionsAmountFilter = () => {
  const filterHook = useCreditTransactionModalFilters();

  return <CreditAmountFilter filterHook={filterHook} />;
};

export const ChargeCardTransactionsAmountFilter = () => {
  const filterHook = useChargeCardTransactionModalFilters();

  return <CreditAmountFilter filterHook={filterHook} />;
};
