import { showNotification } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { PersonUpdate } from 'flexbase-client';
import type { FlexbaseClientPerson } from 'flexbase-client/dist/clients/FlexbaseClient.Person';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import { platformClient } from '@services/platform/platform-client';

const QUERY_KEY = 'users';

// infer a useful type for callers from the client itself
export type User = Awaited<ReturnType<FlexbaseClientPerson['getEmployees']>>[0];

const fetchEmployees = async () => {
  return await flexbaseOnboardingClient.getEmployees();
};

export const usePrefetchUsers = async () => {
  const queryClient = useQueryClient();

  await queryClient.prefetchQuery({
    queryKey: [QUERY_KEY],
    queryFn: fetchEmployees,
  });
};

// get all of the users associated with the caller's company
export const useGetUsers = () =>
  useQuery({
    queryKey: [QUERY_KEY],
    queryFn: fetchEmployees,
    meta: {
      errorMessage: 'Unable to retrieve user info at this time',
    },
  });

// get a single user by ID from the previously-cached set of all users
// this should reduce duplication in the cache and keep the full list of users
// more up-to-date at the cost of (worst-case) iterating over all of a company's users
export const useGetUser = (id: string) => {
  const { data: users, ...rest } = useGetUsers();
  const user = users?.find((u) => u.id === id);
  return { data: user, ...rest };
};

type UpdatePerson = { id: string; person: PersonUpdate };

const updatePerson = async ({ id, person }: UpdatePerson) => {
  return await flexbaseClient.updatePerson(id, person);
};

export const useUpdatePerson = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePerson,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY],
      });

      showNotification({
        color: 'flexbase-teal',
        title: 'Success!',
        message: 'role successfully updated',
      });
    },
    onError: () => {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'role could not be updated',
      });
    },
  });
};

const deleteUser = async (id: string) => {
  return await flexbaseOnboardingClient.deleteUser(id);
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY],
      });

      showNotification({
        color: 'flexbase-teal',
        title: 'Success!',
        message: 'user successfully deleted',
      });
    },
    onError: () => {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'user could not be deleted',
      });
    },
  });
};

const updateUserStatus = async ({
  id,
  status,
}: {
  id: string;
  status: string;
  successMessage: string;
}) => {
  return await flexbaseOnboardingClient.updateUserStatus(id, status);
};

/*
Since the suspend user and rescind invite functionality is the same, we can use this function for both,
but the error message will be different.
 */
export const useUpdateUserStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateUserStatus,
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      showNotification({
        color: 'flexbase-teal',
        title: 'Success',
        message: variables.successMessage,
      });
    },
    onError: (error, variables) => {
      showNotification({
        color: 'red',
        title: 'Error',
        message: `${variables.successMessage} ${error.message}`,
      });
    },
  });
};

export const useListPlatformInvites = (accountId?: string) => {
  return useQuery({
    queryKey: [accountId, 'invites'],
    queryFn: async () => {
      if (accountId) {
        return await platformClient.listInvitations(accountId);
      }
    },
    enabled: !!accountId,
  });
};

export const useResendPlatformInviteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: { accountId: string; personId: string }) =>
      platformClient.resendInvitation(variables.accountId, variables.personId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [variables.accountId, 'invites'],
      });
      showNotification({
        color: 'flexbase-teal',
        title: 'Success',
        message:
          'Invite successfully resent. Invites can only be resent once per hour',
        autoClose: 8000,
      });
    },
    onError: () => {
      showNotification({
        color: 'red',
        title: 'Error',
        message: `Invite resend failed. Please note that you can only resend an invite once per hour, so if you recently resent, wait and try again.`,
        autoClose: 10000,
      });
    },
  });
};
