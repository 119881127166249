import { useCreditLines } from '@queries/use-credit-lines';

export const useActiveCreditPrograms = () => {
  const { data } = useCreditLines();
  const linesOfCredit = data ? data.lines : [];

  const subRouteLabel = {
    lithic: 'Net-60 card',
    unit: 'Charge card',
  };

  const subRouteLocation = {
    lithic: '/cards',
    unit: '/charge-card',
  };

  const activeCreditPrograms = linesOfCredit
    .filter((item) => item.status !== 'Closed')
    .map((item) => ({
      label: subRouteLabel[item.issuer],
      location: subRouteLocation[item.issuer],
    }));

  return activeCreditPrograms;
};
