import { flexbaseBankingClient } from '@services/flexbase-client';
import {
  CreateBeneficiary,
  GetBeneficiaryParameters,
  GetCompanyBeneficiariesParameters,
} from '@services/flexbase/banking.model';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AvailableCurrencies,
  IndividualOrCompany,
  InternationalRecipient,
} from 'areas/payments/components/send-payment/international-payments/util/types';
import { getDerivedName } from 'areas/payments/components/send-payment/send-payment';
import { omit } from 'underscore';

const BENEFICIARY_REQUIREMENTS_QUERY_KEY = 'beneficiaryRequirements';
const BENEFICIARIES_QUERY_KEY = 'beneficiaries';

type BeneficiaryRequirementsParams = {
  currency: AvailableCurrencies | undefined;
  countryOfRecipientsBank: string | undefined;
  countryOfRecipient: string | undefined;
  entityType: IndividualOrCompany | undefined;
};

const parseBeneficiaryRequirementsParams = ({
  currency,
  countryOfRecipientsBank,
  countryOfRecipient,
  entityType,
}: BeneficiaryRequirementsParams): GetBeneficiaryParameters => {
  if (
    !currency ||
    !countryOfRecipientsBank ||
    !countryOfRecipient ||
    !entityType
  ) {
    // should never happen, but just in case...
    throw new Error('Invalid beneficiary requirements params');
  }

  return {
    currency,
    bankCountry: countryOfRecipientsBank,
    country: countryOfRecipient,
    entityType,
  };
};

export const useGetBeneficiaryRequirements = ({
  currency,
  countryOfRecipientsBank,
  countryOfRecipient,
  entityType,
}: BeneficiaryRequirementsParams) => {
  const enabled =
    !!currency &&
    !!countryOfRecipientsBank &&
    !!countryOfRecipient &&
    !!entityType;

  return useQuery({
    queryKey: [
      BENEFICIARY_REQUIREMENTS_QUERY_KEY,
      currency,
      countryOfRecipientsBank,
      countryOfRecipient,
      entityType,
    ],
    queryFn: async () => {
      return await flexbaseBankingClient.getBeneficiaryRequirements(
        parseBeneficiaryRequirementsParams({
          currency,
          countryOfRecipientsBank,
          countryOfRecipient,
          entityType,
        }),
      );
    },
    meta: {
      errorMessage: 'Unable to get additional requirements for your recipient',
    },
    enabled,
  });
};

export const parseCreateBeneficiaryParams = (
  values: InternationalRecipient,
): CreateBeneficiary => {
  const {
    accountHolder,
    address,
    city,
    stateOrProvince,
    postcode,
    currency,
    countryOfRecipient,
    countryOfRecipientsBank,
    recipientId,
  } = values;

  const propertiesToFilterOut = [
    'name',
    'accountHolder',
    'dob',
    'address',
    'city',
    'stateOrProvince',
    'postcode',
    'currency',
    'countryOfRecipient',
    'countryOfRecipientsBank',
    'type',
    'entityType',
    'recipientType',
    'firstName',
    'lastName',
  ];

  const allOtherValues = omit(values, propertiesToFilterOut);

  // 'address' values are based on the recipient, not the recipient's bank.
  const formattedAddress = {
    address: address!,
    city: city!,
    state: stateOrProvince,
    postalCode: postcode!,
    country: countryOfRecipient,
  };

  return {
    name: getDerivedName(values),
    accountHolder,
    address: formattedAddress,
    currency,
    recipientId: recipientId!,
    country: countryOfRecipientsBank,
    ...(allOtherValues as Record<string, string>),
  };
};

const createBeneficiaryMutation = async (postBody: CreateBeneficiary) => {
  return await flexbaseBankingClient.createBeneficiary(postBody);
};

export const useCreateInternationalPaymentsBeneficiary = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createBeneficiaryMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BENEFICIARIES_QUERY_KEY] });
    },
  });
};

export const useGetBeneficiaries = (
  params: GetCompanyBeneficiariesParameters,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: [BENEFICIARIES_QUERY_KEY, params],
    queryFn: async () => {
      return await flexbaseBankingClient.getBeneficiaries(params);
    },
    enabled,
    meta: {
      errorMessage:
        'Unable to retrieve international wire beneficiaries at this time.',
    },
  });
};
