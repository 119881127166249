import { createStyles, PasswordInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import PasswordStrengthPopover from './password-strength-popover';
import AuthContainer from 'components/login/auth-page';
import AuthContent from 'components/login/auth-content';
import { NewPasswordValidator } from 'utilities/validators/validate-password';
import AuthLoader from 'components/login/auth-loader';
import { useMagicLinkContext } from './use-magic-link';
import { IsSuccessResponse } from '@services/platform/models/authorize.models';
import { PasswordError } from './password-error';

type PasswordResetForm = {
  password: string;
  confirmPassword: string;
};

/**
 * This is the page where a user changes their password following a reset request
 */
const PasswordResetPage = () => {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<ReactNode>('');
  const navigator = useNavigate();

  const { updatePassword } = useMagicLinkContext();

  const resetForm = useForm<PasswordResetForm>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: NewPasswordValidator(),
      confirmPassword: (val, values) =>
        val === values?.password ? null : 'Passwords do not match',
    },
  });

  const doReset = async () => {
    setErrorMsg('');
    const validateForm = resetForm.validate();
    if (!validateForm.hasErrors) {
      setLoading(true);
      const formValues = resetForm.values;
      const response = await updatePassword(formValues.password);
      if (!IsSuccessResponse(response)) {
        const passwordError = response.error?.errors?.password;

        if (passwordError && typeof passwordError === 'string') {
          setErrorMsg(passwordError);
        } else if (passwordError && Array.isArray(passwordError)) {
          setErrorMsg(<PasswordError errors={passwordError} />);
        } else {
          setErrorMsg(
            'We had an issue resetting your password. Please try again.',
          );
        }
      } else {
        showNotification({
          color: 'flexbase-teal',
          message:
            "Congrats, you've reset your password! Please login to continue.",
          autoClose: 8000,
        });
        navigator('/login', { replace: true });
      }
      setLoading(false);
    }
  };

  return (
    <AuthContainer>
      {loading && <AuthLoader />}
      <form onSubmit={resetForm.onSubmit(() => doReset())}>
        <AuthContent
          errorMsg={errorMsg}
          onSubmit={doReset}
          title="Reset password"
          screen="reset-password"
          submitButtonLabel="Reset password"
          onGoback={() => navigator('/login')}
        >
          <PasswordStrengthPopover inputValue={resetForm.values.password}>
            <PasswordInput
              placeholder="Create a password"
              {...resetForm.getInputProps('password')}
              id="password"
              label="New Password"
              classNames={classes}
            />
          </PasswordStrengthPopover>
          <PasswordInput
            label="Confirm Password"
            placeholder="Confirm password"
            value={resetForm.values.confirmPassword}
            onChange={(event) =>
              resetForm.setFieldValue(
                'confirmPassword',
                event.currentTarget.value,
              )
            }
            error={
              resetForm.errors.confirmPassword &&
              'Password confirmation needs to match'
            }
            classNames={classes}
          />
        </AuthContent>
      </form>
    </AuthContainer>
  );
};

export default PasswordResetPage;

const useStyles = createStyles((theme) => ({
  label: {
    color: theme.fn.themeColor('neutral', 0),
  },
  error: {
    color: theme.colors.critical[2],
  },
}));
