import { NavTab } from 'components/tabs/nav-tabs';
import FlexbaseMainLayout from 'components/layouts/main';
import {
  ApplicationState,
  IsAccountant,
  IsAdmin,
} from 'states/application/product-onboarding';
import { useRecoilValue } from 'recoil';
import { Outlet, useNavigate } from 'react-router-dom';
import { NavTabContainer } from '@common/tabs/nav-tab-container';
import FlexbaseDefaultHeader from '../../components/header/current/flexbase-header';
import { Box } from '@mantine/core';
import { NoCreditPage } from './components/no-credit.page';

const adminTabs = [
  { label: 'Overview', route: '/cards/overview' },
  { label: 'Credit cards', route: '/cards/details' },
  { label: 'Credit payment', route: '/cards/pay' },
  { label: 'Statements', route: '/cards/statements' },
];

const tabs: NavTab[] = [
  { label: 'Overview', route: '/cards/overview' },
  { label: 'Credit cards', route: '/cards/details' },
];

const Credit = () => {
  const navigate = useNavigate();
  const { excludedProducts } = useRecoilValue(ApplicationState);
  const isAdmin = useRecoilValue(IsAdmin);
  const isAccountant = useRecoilValue(IsAccountant);
  const onboardingState = useRecoilValue(ApplicationState);
  const appStatus = onboardingState.productStatus.credit.appStatus;
  const creditLimit = onboardingState.productStatus.credit.creditLimit
    ? parseFloat(onboardingState.productStatus.credit.creditLimit)
    : 0;
  const hasCreditLimit = creditLimit > 0;
  const showMainApp = appStatus === 'approved' || hasCreditLimit;

  if (excludedProducts.includes('CREDIT')) {
    if (!excludedProducts.includes('BANKING')) {
      navigate('/banking', { replace: true });
    }
    return false;
  }

  return (
    <FlexbaseMainLayout>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      >
        <FlexbaseDefaultHeader title={showMainApp ? 'Credit' : undefined} />
        <Box sx={{ flex: 1 }}>
          {showMainApp ? (
            <NavTabContainer tabs={isAdmin || isAccountant ? adminTabs : tabs}>
              <Outlet />
            </NavTabContainer>
          ) : (
            <NoCreditPage />
          )}
        </Box>
      </Box>
    </FlexbaseMainLayout>
  );
};

export default Credit;
