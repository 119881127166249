import { Box, Grid, LoadingOverlay } from '@mantine/core';
import ChargeAutoPay from './charge-auto-pay';
import ChargePaymentsTable from './charge-payments-table';
import ChargePaymentCard from '../overview/charge-payment';
import { useGetChargeCardAccounts } from '@queries/use-charge-card-accounts';

const ChargePayments = () => {
  const {
    data: chargeAccountsData,
    isError,
    isLoading,
    refetch: refetchChargeAccounts,
  } = useGetChargeCardAccounts();
  const activeChargeAccounts =
    chargeAccountsData?.accounts.filter((acc) => acc.status !== 'Closed') ?? [];

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <Box>
      <Grid mb="xl">
        <Grid.Col md={6}>
          <ChargeAutoPay
            isChargeAccountsError={isError}
            // for v1 the company will have one charge account
            chargeAccount={activeChargeAccounts[0]}
            refetchChargeAccounts={refetchChargeAccounts}
          />
        </Grid.Col>
        <Grid.Col md={6}>
          <ChargePaymentCard
            isError={isError}
            // for v1 the company will have one charge account
            chargeBalance={activeChargeAccounts[0]}
            refetchChargeAccounts={refetchChargeAccounts}
          />
        </Grid.Col>
      </Grid>
      <ChargePaymentsTable />
    </Box>
  );
};

export default ChargePayments;
