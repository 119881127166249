import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { platformClient } from '@services/platform/platform-client';
import {
  CreatePlatformBusinessAddressRequest,
  CreatePlatformPersonPhoneRequest,
  PlatformBusiness,
} from '@services/platform/models/identity.model';

const QUERY_KEY = 'businesses';

export const useGetPlatformBusiness = (
  accountId?: string,
  businessId?: string,
) => {
  return useQuery({
    queryKey: ['accounts', accountId, QUERY_KEY, businessId],
    enabled: !!accountId && !!businessId,
    queryFn: async () => {
      if (accountId && businessId) {
        return await platformClient.getBusiness(accountId, businessId);
      }
    },
  });
};

type UpdatePlatformBusinessArgs = {
  accountId: string;
  businessId: string;
  request: Partial<Omit<PlatformBusiness, 'id' | 'accountId'>>;
};

export const useUpdatePlatformBusinessMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      accountId,
      businessId,
      request,
    }: UpdatePlatformBusinessArgs) =>
      platformClient.updateBusiness(accountId, businessId, request),
    onSuccess: (business, variables) => {
      queryClient.setQueryData(
        ['accounts', variables.accountId, QUERY_KEY, variables.businessId],
        business,
      );
    },
  });
};

type UpdateBusinessResourceBaseArgs = {
  accountId: string;
  businessId: string;
};

export const useCreatePlatformBusinessPhoneMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      accountId,
      businessId,
      phone,
    }: UpdateBusinessResourceBaseArgs & {
      phone: CreatePlatformPersonPhoneRequest;
    }) => platformClient.createBusinessPhone(accountId, businessId, phone),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          'accounts',
          variables.accountId,
          QUERY_KEY,
          variables.businessId,
        ],
      });
    },
  });
};

export const useGetPlatformBusinessOwners = (
  accountId?: string,
  businessId?: string,
) => {
  return useQuery({
    queryKey: ['accounts', accountId, QUERY_KEY, businessId, 'owners'],
    enabled: !!accountId && !!businessId,
    queryFn: async () => {
      if (accountId && businessId) {
        return await platformClient.getBusinessOwners(accountId, businessId);
      }
    },
  });
};

type CreatePlatformBusinessOwnerArgs = {
  accountId: string;
  businessId: string;
  personId: string;
  percentage: number;
};

export const useCreatePlatformBusinessOwnerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      accountId,
      businessId,
      personId,
      percentage,
    }: CreatePlatformBusinessOwnerArgs) => {
      return await platformClient.createBusinessOwner(accountId, businessId, {
        personId,
        percentage,
      });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          'accounts',
          variables.accountId,
          QUERY_KEY,
          variables.businessId,
          'owners',
        ],
      });
    },
  });
};

export const useUpdatePlatformBusinessOwnerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      accountId,
      businessId,
      personId,
      percentage,
    }: CreatePlatformBusinessOwnerArgs) => {
      return await platformClient.updateBusinessOwner(
        accountId,
        businessId,
        personId,
        percentage,
      );
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          'accounts',
          variables.accountId,
          QUERY_KEY,
          variables.businessId,
          'owners',
        ],
      });
    },
  });
};

export const useDeactivePlatformBusinessOwnerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      accountId,
      businessId,
      personId,
    }: Omit<CreatePlatformBusinessOwnerArgs, 'percentage'>) => {
      return await platformClient.deactivateBusinessOwner(
        accountId,
        businessId,
        personId,
      );
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          'accounts',
          variables.accountId,
          QUERY_KEY,
          variables.businessId,
          'owners',
        ],
      });
    },
  });
};

export const getPlatformBusinessAddressesQueryOptions = ({
  accountId,
  businessId,
}: {
  accountId?: string;
  businessId?: string;
} = {}) => {
  return queryOptions({
    queryKey: ['accounts', accountId, QUERY_KEY, businessId, 'addresses'],
    queryFn: () => {
      if (accountId && businessId) {
        return platformClient.getBusinessAddresses(accountId, businessId);
      }
    },
    enabled: !!accountId && !!businessId,
  });
};

export const usePlatformBusinessAddresses = (
  accountId: string | undefined,
  businessId: string | undefined,
) => {
  return useQuery(
    getPlatformBusinessAddressesQueryOptions({ accountId, businessId }),
  );
};

export const useCreatePlatformBusinessAddress = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      accountId,
      businessId,
      request,
    }: {
      accountId: string;
      businessId: string;
      request: CreatePlatformBusinessAddressRequest;
    }) => {
      return platformClient.createBusinessAddress(
        accountId,
        businessId,
        request,
      );
    },
    onSuccess: async (_, { accountId, businessId }) => {
      await queryClient.invalidateQueries(
        getPlatformBusinessAddressesQueryOptions({ accountId, businessId }),
      );
    },
  });
};

export const useUpdatePlatformBusinessAddress = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      accountId,
      businessId,
      addressId,
      request,
    }: UpdateBusinessResourceBaseArgs & {
      addressId: string;
      request: CreatePlatformBusinessAddressRequest;
    }) => {
      return platformClient.updateBusinessAddress(
        accountId,
        businessId,
        addressId,
        request,
      );
    },
    onSuccess: async (_, { accountId, businessId }) => {
      await queryClient.invalidateQueries(
        getPlatformBusinessAddressesQueryOptions({ accountId, businessId }),
      );
    },
  });
};
