import {
  Box,
  Button,
  Menu,
  Text,
  TextInput,
  createStyles,
} from '@mantine/core';
import { useEffect, useRef } from 'react';
import { CloseIcon, SearchIcon } from '../../../../../assets/svg';
import {
  ChargeCardTransactionsDateRangeFilter,
  CreditTransactionsDateRangeFilter,
} from './filters/credit-date-range-filter';
import {
  ChargeCardTransactionsStatusFilter,
  CreditTransactionsStatusFilter,
} from './filters/credit-status-filter';
import {
  ChargeCardTransactionsTypeFilter,
  CreditTransactionsTypeFilter,
} from './filters/credit-type-filter';
import {
  ChargeCardTransactionsAmountFilter,
  CreditTransactionsAmountFilter,
} from './filters/credit-transactions-amount.filter';
import {
  ChargeCardTransactionsCardsFilter,
  CreditTransactionsCardsFilter,
} from './filters/credit-transactions-cards.filter';
import {
  ChargeCardTransactionsAttachmentsFilter,
  CreditTransactionsAttachmentsFilter,
} from './filters/credit-transactions-attachments.filter';
import {
  ChargeCardTransactionsNameFilter,
  CreditTransactionsNameFilter,
} from './filters/credit-transactions-name.filter';
import { isTruthyString } from 'utilities/validators/validate-string';
import { IoFunnelOutline } from 'react-icons/io5';
import { MdOutlineFileDownload } from 'react-icons/md';
import { FilterModal } from '@common/filter/filter-modal';
import useModal from '@common/modal/modal-hook';
import { CompanyTransactionResponse } from '@services/flexbase/flexbase-onboarding-client';
import { ActiveFiltersReturnType } from '@common/filter/filters';
import { CreditTransactionsTableRow } from '../credit-transactions-table-helpers';
import { LineOfCredit } from '@services/flexbase/credit.model';

type Props = {
  onDownloadCSVClick: () => void;
  creditTransactionsData?: CompanyTransactionResponse;
  lineOfCredit: LineOfCredit;
  useFilters: () => ActiveFiltersReturnType<CreditTransactionsTableRow>;
  useModalFilters: () => ActiveFiltersReturnType<CreditTransactionsTableRow>;
};

/**
 * This component is a replacement for the old header that maintains the same signature
 * @constructor
 */
export const CreditTransactionsTableHeader = ({
  onDownloadCSVClick,
  lineOfCredit,
  creditTransactionsData,
  useFilters,
  useModalFilters,
}: Props) => {
  const modal = useModal();
  const { classes, theme } = useStyles();

  const {
    applyAllFilters: applyAllModalFilters,
    activeFilters: activeModalFilters,
  } = useModalFilters();

  const {
    applyAllFilters: applyAllTableFilters,
    removeAllFilters,
    activeFilters: activeTableFilters,
    activeFiltersArray: activeTableFiltersArray,
    getFilterByKey,
    addFilter,
    removeFilter,
  } = useFilters();

  const activeModalFiltersRef = useRef(activeModalFilters);

  useEffect(() => {
    activeModalFiltersRef.current = activeModalFilters;
  }, [activeModalFilters]);

  const filterConfig = [
    {
      key: 'date',
      header: 'Date',
      component:
        lineOfCredit === 'unit' ? (
          <ChargeCardTransactionsDateRangeFilter />
        ) : (
          <CreditTransactionsDateRangeFilter />
        ),
    },
    {
      key: 'status',
      header: 'Status',
      component:
        lineOfCredit === 'unit' ? (
          <ChargeCardTransactionsStatusFilter />
        ) : (
          <CreditTransactionsStatusFilter />
        ),
    },
    {
      key: 'type',
      header: 'Type',
      component:
        lineOfCredit === 'unit' ? (
          <ChargeCardTransactionsTypeFilter />
        ) : (
          <CreditTransactionsTypeFilter />
        ),
    },
    {
      key: 'amount',
      header: 'Amount',
      component:
        lineOfCredit === 'unit' ? (
          <ChargeCardTransactionsAmountFilter />
        ) : (
          <CreditTransactionsAmountFilter />
        ),
    },
    {
      key: 'cards',
      header: 'Cards',
      component:
        lineOfCredit === 'unit' ? (
          <ChargeCardTransactionsCardsFilter />
        ) : (
          <CreditTransactionsCardsFilter />
        ),
    },
    {
      key: 'name',
      header: 'Name',
      component:
        lineOfCredit === 'unit' ? (
          <ChargeCardTransactionsNameFilter />
        ) : (
          <CreditTransactionsNameFilter />
        ),
    },
    {
      key: 'attachments',
      header: 'Attachments',
      component:
        lineOfCredit === 'unit' ? (
          <ChargeCardTransactionsAttachmentsFilter />
        ) : (
          <CreditTransactionsAttachmentsFilter />
        ),
    },
  ];

  const onSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(transaction)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const handleModalSave = () => {
    applyAllTableFilters(activeModalFiltersRef.current);
    modal.closeAllModals();
  };

  const handleModalCancel = () => {
    modal.closeAllModals();
  };

  const filterChips = activeTableFiltersArray.filter((f) => f.showChip);

  const openModal = () => {
    applyAllModalFilters(activeTableFilters);

    screen.width <= 767
      ? modal.openFullModal(
          <FilterModal
            filters={filterConfig}
            onSave={handleModalSave}
            onCancel={handleModalCancel}
          />,
        )
      : modal.openRightModal(
          <FilterModal
            filters={filterConfig}
            onSave={handleModalSave}
            onCancel={handleModalCancel}
          />,
        );
  };

  return (
    <>
      <Box
        sx={() => ({
          display: 'flex',
          gap: theme.spacing.md,
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        })}
        mb="md"
      >
        <Box
          sx={() => ({
            display: 'flex',
            gap: theme.spacing.md,
            [theme.fn.smallerThan('sm')]: {
              flexDirection: 'column',
              width: '100%',
            },
          })}
        >
          <TextInput
            placeholder="Search transactions"
            w={368}
            value={getFilterByKey('search')?.filterValue || ''}
            onChange={(e) => onSearchChange(e.target.value)}
            icon={<SearchIcon width={20} height={20} />}
          />
          <Button
            variant="outline"
            rightIcon={<IoFunnelOutline />}
            onClick={() => openModal()}
            disabled={creditTransactionsData?.transactions.length === 0}
          >
            Filters
          </Button>

          <Menu>
            <Menu.Target>
              <Button
                variant="outline"
                leftIcon={<MdOutlineFileDownload size={18} />}
              >
                CSV
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={onDownloadCSVClick}>Download CSV</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Box>
      </Box>
      {filterChips.length > 0 && (
        <Box
          sx={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}
          mih={30}
          mb="md"
        >
          {filterChips.map((p) => (
            <Box
              sx={() => ({
                height: 30,
                borderRadius: theme.radius.xs,
                border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
                backgroundColor: theme.fn.themeColor('neutral', 2),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                padding: `0px ${theme.spacing.md}`,
                gap: 4,
                [theme.fn.smallerThan('md')]: {
                  height: 'auto',
                  padding: `0px ${theme.spacing.xl}`,
                },
              })}
              onClick={() => {
                removeFilter(p.key);
              }}
              key={p.key}
            >
              <Text color="black" fz={14} fw={500}>
                {p.label}
              </Text>
              <CloseIcon color="black" style={{ marginLeft: 'auto' }} />
            </Box>
          ))}
          <Text
            className={classes.clearFilters}
            onClick={() => {
              removeAllFilters();
            }}
          >
            Clear filters
          </Text>
        </Box>
      )}
    </>
  );
};

const useStyles = createStyles((theme) => ({
  clearFilters: {
    cursor: 'pointer',
    color: theme.fn.themeColor('primarySecondarySuccess', 2),
  },
}));
