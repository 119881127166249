import { formatCurrency } from '@flexbase-eng/web-components';
import { Avatar, Box, Card, Flex, Text, useMantineTheme } from '@mantine/core';
import { BankingIcon } from 'assets/svg';
import { getInitialsOfNames } from '@utilities/extensions/object';
import { InvoiceWizard, useInvoiceWizard } from '../../invoice-wizard';
import { formatDate } from '@utilities/formatters/format-datetime';
import { DateTime } from 'luxon';
import GetPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters/format-strings';

const ReviewPayStep = () => {
  const theme = useMantineTheme();
  const { state } = useInvoiceWizard();

  const { sendFrom } = state.amountSource || {};
  const { recipientAccount } = state || {};

  const recipientName =
    state.recipient.name ??
    `${state.recipient.firstName} ${state.recipient.lastName}`;

  const sendOnDate = formatDate(
    DateTime.fromJSDate(state.amountSource!.sendOn).toSQL() || '',
  );

  return (
    <InvoiceWizard.Step hideBack hideNext>
      <Card p="xxl" bg="#ddd4c3">
        <Text size="md">
          {recipientAccount?.type.toUpperCase()} to {recipientName}
        </Text>
        <Text size="2.5rem" style={{ lineHeight: '2.5rem' }}>
          {state.invoiceTotal?.formatted}
        </Text>
      </Card>

      <Text mt="xl" weight="500" size="xl">
        Recipient details
      </Text>
      <Text size="sm" mt="md" mb="xxs">
        Recipient
      </Text>
      <Card p="md">
        <Flex align="center">
          <Avatar radius="xl" mr="sm" w="2.3rem" h="2.3rem">
            {getInitialsOfNames(recipientName)?.slice(0, 1).toUpperCase()}
          </Avatar>
          {recipientName}
        </Flex>
      </Card>

      <Text size="sm" mt="md" mb="xxs">
        Send to
      </Text>

      <Card p="md">
        <Flex align="center">
          <Avatar radius="xl" mr="sm" w="2.3rem" h="2.3rem">
            <BankingIcon />
          </Avatar>
          <Box>
            <Text>
              {recipientAccount?.nickName ?? recipientAccount?.name} via{' '}
              {recipientAccount?.type.toUpperCase()}
            </Text>
            <Text size="sm" color={theme.colors.neutral[6]}>
              Account{' '}
              {GetPaddedAccountMask(recipientAccount?.accountNumber ?? '', 2)} |
              Routing{' '}
              {GetPaddedAccountMask(recipientAccount?.routingNumber ?? '', 2)}
            </Text>
          </Box>
        </Flex>
      </Card>

      <Box mt="xxl">
        <Text mt="xl" weight="500" mb="md" size="xl">
          Transferring from
        </Text>

        <Text size="sm" mt="md" mb="xxs">
          Pay from
        </Text>
        <Card p="md">
          <Flex align="center">
            <Avatar radius="xl" mr="sm" w="2.3rem" h="2.3rem">
              <BankingIcon />
            </Avatar>
            <Box>
              {sendFrom?.plaidOrDeposit === 'deposit' ? (
                <>
                  <Text>{sendFrom.nickName ?? sendFrom.name}</Text>
                  <Text size="sm" color={theme.colors.neutral[6]}>
                    {formatCurrency(sendFrom.available)} available *{' '}
                    {capitalizeOnlyFirstLetter(sendFrom.accountType)}
                  </Text>
                </>
              ) : (
                <>
                  <Text>{sendFrom?.accountName}</Text>
                  <Text size="sm" color={theme.colors.neutral[6]}>
                    {sendFrom?.bankName} -{' '}
                    {`${GetPaddedAccountMask(sendFrom?.last4 ?? '', 4)}`}
                  </Text>
                </>
              )}
            </Box>
          </Flex>
        </Card>

        <Flex justify="space-between" align="center" mt="xl">
          <Box>
            <Text size="sm">Sending on</Text>
            <Text size="md" weight="500">
              {sendOnDate}
            </Text>
          </Box>
          <Box>
            <Text size="sm" mt="xxs" color={theme.colors.neutral[7]}>
              ETA 1 day
            </Text>
          </Box>
        </Flex>

        <Text mt="xxl" weight="500" size="xl">
          Memo
        </Text>
        <Text size="md" w="80%">
          {state.memo}
        </Text>
      </Box>
    </InvoiceWizard.Step>
  );
};

ReviewPayStep.stepId = 'review-pay';

export default ReviewPayStep;
