import { useBillPayFeatureFlag } from '@utilities/feature-flags';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Recipient } from 'states/recipient/recipient';
import { InvoiceWizard, LineItemInput } from './invoice-wizard';
import { useBillpayInvoiceById } from '../use-bill-pay';
import { LoadingOverlay } from '@mantine/core';
import ReviewPayStep from './steps/review-pay-step/review-pay-step';
import SelectRecipientStep from './steps/select-recipient/select-recipient-step';
import UploadInvoiceStep from './steps/upload-invoice/upload-invoice-step';
import UploadInvoicePreview from './steps/upload-invoice/upload-invoice-preview';
import AddLineItemsStep from './steps/add-line-items/add-line-items-step';
import InvoiceWizardFooter from './invoice-wizard-footer';
import AmountSourceStep from './steps/amount-source-step/amount-source-step';
import InvoiceWizardBody from './invoice-wizard-body';
import AdditionalInformationStep from './steps/additional-information-step';
import PaymentSubmittedStep from './steps/payment-submitted-step/payment-submitted-step';
import { useMemo } from 'react';
import { formatCurrency } from '@flexbase-eng/web-components';
import { LineItemStatus } from 'states/bill-pay/bill-pay';
import { MoneyMovement } from '@services/flexbase/banking.model';
import { UserInfoState } from 'states/user/user-info';
import { useRecoilValue } from 'recoil';
import InvoiceWizardHeader from './invoice-wizard-header';

const steps = [
  {
    id: SelectRecipientStep.stepId,
    title: 'Who are you paying?',
    element: <SelectRecipientStep />,
    previewElement: <UploadInvoicePreview hideIfNoImage />,
  },
  {
    id: UploadInvoiceStep.stepId,
    title: 'Upload your bill to Flex',
    element: <UploadInvoiceStep />,
    previewElement: <UploadInvoicePreview />,
  },
  {
    id: AddLineItemsStep.stepId,
    title: 'Add line items',
    element: <AddLineItemsStep />,
    previewElement: <UploadInvoicePreview hideIfNoImage />,
  },
  {
    id: AmountSourceStep.stepId,
    title: 'Amount and source',
    element: <AmountSourceStep />,
    previewElement: <UploadInvoicePreview hideIfNoImage />,
  },
  {
    id: AdditionalInformationStep.stepId,
    title: 'Additional information',
    element: <AdditionalInformationStep />,
    previewElement: <UploadInvoicePreview hideIfNoImage />,
  },
  {
    id: ReviewPayStep.stepId,
    title: 'Review and Pay',
    element: <ReviewPayStep />,
    previewElement: <UploadInvoicePreview hideIfNoImage />,
  },
  {
    id: PaymentSubmittedStep.stepId,
    title: '',
    element: <PaymentSubmittedStep />,
  },
];

const CreateInvoice = () => {
  const isFeatureFlagEnabled = useBillPayFeatureFlag();
  const { id: invoiceId } = useParams();
  const navigate = useNavigate();
  const user = useRecoilValue(UserInfoState);
  const isBookkeeper = user.roles.includes('ACCOUNTANT');
  const { data: invoice, isLoading } = useBillpayInvoiceById(invoiceId || '');
  const initialLineItems = invoice?.lineItems?.length
    ? (invoice.lineItems as LineItemInput[])
    : [
        {
          id: undefined,
          description: undefined,
          quantity: undefined,
          total: undefined,
          glCode: undefined,
          timestamp: Date.now(),
          status: 'active' as LineItemStatus,
        },
      ];

  const invoiceTotal = useMemo(() => {
    // sum up line items
    if (!initialLineItems.length) {
      return undefined;
    }
    const lineItemsTotal = initialLineItems
      .filter((item) => item?.status !== 'inactive')
      ?.reduce(
        (acc, item) =>
          acc + (Number(item.total) || 0) * (Number(item.quantity) || 0),
        0,
      );
    return {
      formatted: formatCurrency(lineItemsTotal),
      cents: Math.round(lineItemsTotal * 100),
    };
  }, []);

  const initialState = {
    existingInvoiceId: invoiceId,
    isInvoiceDraft:
      invoice?.status === 'drafted' || invoice?.status === undefined,
    recipient: invoice?.recipient || ({} as Recipient),
    existingDocument: invoice?.document as File,
    existingDocumentId: invoice?.documentId || undefined,
    invoiceDetails: {
      lineItems: initialLineItems,
      credits: 0,
      tax: 0,
      dueDate: invoice?.dueDate,
    },
    isNextEnabled: false,
    invoiceNumber: invoice?.invoiceNumber || undefined,
    memo: invoice?.description || '',
    notes: undefined,
    paymentSubmissionDetails: {} as MoneyMovement,
    invoiceTotal,
    isActionDisabled:
      invoice?.status === 'pending' ||
      invoice?.status === 'failed' ||
      invoice?.status === 'succeeded' ||
      isBookkeeper,
    isBookkeeper,
    recipientAccount: undefined,
  };

  if (!isFeatureFlagEnabled) {
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <InvoiceWizard
      steps={steps}
      initialState={initialState}
      onCancel={() => navigate('/bill-pay')}
      footer={<InvoiceWizardFooter />}
      body={<InvoiceWizardBody />}
      header={<InvoiceWizardHeader />}
    />
  );
};

export default CreateInvoice;
