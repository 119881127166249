import {
  rem,
  Text,
  Group,
  Alert,
  Button,
  Divider,
  createStyles,
  Anchor,
} from '@mantine/core';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

import { RedAlertIcon } from 'assets/svg';
import useModal from 'components/modal/modal-hook';
import { UNSERVED_CREDIT_STATES } from 'states/business/constants';
import { ProductOnboardingBtnLoaderState } from 'states/application/product-onboarding';

export type UnqualifiedReason = 'unserved-state' | 'sole-prop';

type Props = {
  errorMessage: string | null;
  onCancelApplication: () => void;
  reason: UnqualifiedReason;
  onApplyForBanking: () => void;
};

const OfferBankingProduct = ({
  onCancelApplication,
  onApplyForBanking,
  errorMessage,
  reason,
}: Props) => {
  const { classes } = useStyles();
  const [error, setError] = useState<string | null>(errorMessage);
  const { closeAllModals, closeModalById, openConfirmationModal } = useModal();
  const [loading, setLoading] = useRecoilState(ProductOnboardingBtnLoaderState);

  const openModalCancelApplication = async () => {
    openConfirmationModal({
      title: 'Cancel application?',
      confirmText: 'Cancel application',
      cancelText: 'Back',
      onConfirm: () => onCancelApplication(),
      onCancel: () => closeModalById('cancel-application'),
      id: 'cancel-application',
      content: (
        <Text size={14}>
          Are you sure you want to cancel? This action cannot be undone.
        </Text>
      ),
      classNames: { confirmButton: classes.root },
    });
  };

  const continueWithBanking = async () => {
    onApplyForBanking();
  };

  const onBack = () => {
    setLoading(false);
    closeAllModals();
  };

  return (
    <div>
      <div className={classes.header}>Flex Banking Offer</div>
      <Divider />
      <div className={classes.body}>
        {reason === 'sole-prop' ? (
          <Text>
            We are unable to offer credit cards to sole proprietorships; if you
            have a registered business entity, please click back to reselect
            your legal structure.
          </Text>
        ) : (
          <>
            <Text>
              We are unable to offer Flex credit cards to companies with their
              primary headquarters located in:
            </Text>
            <div className={classes.cardContainer}>
              {UNSERVED_CREDIT_STATES.map(({ label, value }, index) => (
                <div
                  className={
                    index < 3 ? classes.cardItem40 : classes.cardItem60
                  }
                  key={value}
                >
                  {label}
                </div>
              ))}
            </div>
            <Text>
              If your primary headquarters location is not listed above, please
              click the back button and update your business address.
            </Text>
          </>
        )}
        <Text my="lg">
          We are pleased to offer you Flex Banking, which comes with a 1%
          cashback debit card and free domestic ACH & wires, all with no annual
          fee.
        </Text>

        <Text>
          See{' '}
          <Anchor
            target="_blank"
            color="primarySecondarySuccess.2"
            href="https://home.flex.one/legal"
          >
            terms & conditions
          </Anchor>{' '}
          for details.
        </Text>

        {error && (
          <Alert
            mt={20}
            icon={<RedAlertIcon />}
            color="red"
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        )}

        <Group mt="lg" position="apart">
          <Button sx={{ width: '48%' }} variant="outline" onClick={onBack}>
            Back
          </Button>
          <Button
            variant="light"
            loading={loading}
            sx={{ width: '48%' }}
            onClick={continueWithBanking}
          >
            Continue with Flex Banking
          </Button>
        </Group>
        <div
          className={classes.cancelButton}
          onClick={openModalCancelApplication}
        >
          Cancel application
        </div>
      </div>
    </div>
  );
};

export default OfferBankingProduct;

const useStyles = createStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: `${rem(20)} 0`,
    padding: `${rem(12)} ${rem(30)}`,
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.fn.themeColor('neutral', 2),
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  cardItem40: {
    flexBasis: '40%',
  },
  cardItem60: {
    flexBasis: '60%',
  },
  header: {
    fontWeight: 500,
    padding: rem(20),
    fontSize: rem(20),
  },
  body: {
    padding: rem(20),
  },
  cancelButton: {
    display: 'flex',
    fontWeight: 500,
    fontSize: rem(14),
    cursor: 'pointer',
    marginTop: rem(20),
    justifyContent: 'center',
    color: theme.fn.themeColor('neutral', 6),
  },
  root: {
    background: theme.fn.themeColor('critical', 2),
    borderColor: theme.fn.themeColor('critical', 2),
    '&:not([data-disabled])': theme.fn.hover({
      backgroundColor: theme.fn.themeColor('critical', 2),
    }),
  },
}));
