export const PREVIEW_STACK_PY = 20;
export const PREVIEW_STACK_SPACING = 0;

export type Easing =
  | 'EaseOutCubic'
  | 'EaseOutQuint'
  | 'InOutCubic'
  | 'InOutQuint'
  | 'EaseOutExpo';

/**
 * Formulas from https://easings.net
 *
 * Add more if we need them.
 */
export const Easings: Record<Easing, (t: number) => number> = {
  EaseOutCubic: (t: number) => 1 - Math.pow(1 - t, 3),
  InOutCubic: (t: number) =>
    t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2,
  EaseOutQuint: (t: number) => 1 - Math.pow(1 - t, 5),
  InOutQuint: (t: number) =>
    t < 0.5 ? 16 * Math.pow(t, 5) : 1 - Math.pow(-2 * t + 2, 5) / 2,
  EaseOutExpo: (t: number) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
};
