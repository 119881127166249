import { TermsOfServiceType } from '@services/flexbase/flexbase-onboarding-client';
import {
  createMultiStepFormRouteContext,
  RouteStep,
} from '../../../../providers/multi-step-form-provider';

export type PrefillFormStep = RouteStep<PrefillFormState> & {
  metadata: {
    description?: string;
    disclosures?: TermsOfServiceType[];
  };
};

export type PrefillFormState = {
  dateOfBirthProvided: boolean;
  phoneVerification: { phoneNumber: string; methodId: string } | null;
  phoneVerified: boolean;
  loading: boolean;
  error: string | null;
  prefilled: boolean;
  businessPrefillAvailable: boolean;
};

export const [PrefillProvider, usePrefillMultistepFormContext] =
  createMultiStepFormRouteContext<PrefillFormState, PrefillFormStep>();
