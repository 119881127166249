import { MultiSelect } from '@mantine/core';
import { useMemo } from 'react';
import { uniq } from 'underscore';
import { useBillpayPaymentsModalFilters } from 'areas/billpay/payments/use-billpay-payments-filters';
import { PaymentWithInvoiceNumber } from 'areas/billpay/payments/payments';

type Props = {
  payments: PaymentWithInvoiceNumber[];
};
export const PaymentVendorFilter = ({ payments }: Props) => {
  const who = useMemo(() => {
    if (payments) {
      return uniq(payments.map((t) => t.payCtrParty).filter((t) => !!t));
    }
    return [];
  }, [payments]);

  const { addFilter, removeFilter, getFilterByKey } =
    useBillpayPaymentsModalFilters();

  return (
    <MultiSelect
      data={who}
      placeholder="Vendor"
      searchable
      nothingFound="Nothing found"
      value={getFilterByKey('vendor')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('vendor', {
            key: 'vendor',
            filterValue: val,
            fn: (row) => val.includes(row.payCtrParty),
            label: `Vendor: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('vendor');
        }
      }}
    />
  );
};
