import { createStyles, rem } from '@mantine/core';

type Props = {
  hasDelinquentAmount?: boolean;
};

export const useStyles = createStyles(
  (theme, { hasDelinquentAmount }: Props) => ({
    creditContainer: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      borderRadius: theme.defaultRadius,
      backgroundColor: theme.fn.themeColor('neutral', 0),
      border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
      height: rem(200),
      minWidth: rem(420),
      '@media(max-width: 767px)': {
        width: '100%',
        minWidth: 'unset',
      },
      ...(hasDelinquentAmount && {
        border: '1px solid rgba(48, 44, 255, 0.03)',
      }),
    },
    totalBalanceContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing.xl,
      justifyContent: 'space-between',
      opacity: hasDelinquentAmount ? '0.3' : '',
      borderColor: theme.fn.themeColor('neutral', 3),
    },
  }),
);
