import { useMutation, useQuery } from '@tanstack/react-query';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from '@services/flexbase-client';
import {
  MakePaymentPlaid,
  MakePaymentUnit,
} from '@services/flexbase/flexbase-onboarding-client';

const QUERY_KEY = 'companyBalance';

export const useGetCompanyBalance = (companyId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, companyId],
    queryFn: async () => {
      return await flexbaseClient.getCompanyBalance(companyId);
    },
    meta: {
      errorMessage: 'Unable to get company balance',
    },
  });
};

export const useMakePaymentPlaid = () => {
  return useMutation({
    mutationFn: async ({ amount, plaidTokenId }: MakePaymentPlaid) => {
      return await flexbaseOnboardingClient.makePaymentPlaid({
        amount,
        plaidTokenId,
      });
    },
  });
};

export const useMakePaymentUnit = () => {
  return useMutation({
    mutationFn: async (request: MakePaymentUnit) => {
      return await flexbaseOnboardingClient.makePaymentUnit(request);
    },
  });
};
