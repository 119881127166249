import wretch, { Wretcher } from 'wretch';
import {
  RefreshTokenMiddleware,
  TokenMiddleware,
} from '@services/token-middleware';
import {
  AccountingSettings,
  Category,
  CreateSpendPlan,
  CreateSpendPlan200Response,
  DecisionFundsRequest200Response,
  ExpenseLink,
  ExpenseLink200Response,
  ExpenseLinkRequest,
  ExpenseLinks200Response,
  ExpenseSyncedRequest,
  GetAccountingAdsLinks200Response,
  GetAccountingCommerceLinks200Response,
  GetExpensesCategoryToCard200Response,
  GetExpensesCategoryToCardId200Response,
  InvoiceSpendPlan,
  LinkCardToSpendPlanRequest,
  PatchAccountingCoaRequestAccountsInner,
  PatchExpensesCategoryToCardRequest,
  PatchExpenseSynced200Response,
  PatchExpenseSyncedRequest,
  PostExpensesCategoryToCardRequest,
  PostExpenseSync200Response,
  PostExpenseSyncRequest,
  SpendPlanAdminView,
  SpendPlanLimit,
  SpendPlanRequestFunds,
  SpendPlanView,
  SyncedExpenses,
  UpdateSpendPlan,
  UpdateSpendPlan200Response,
} from '@flexbase-eng/types/dist/accounting';

import {
  CreatePlatformBusinessAddressRequest,
  CreatePlatformPersonPhoneRequest,
  CrupdatePlatformBusinessRequest,
  ListApplicationsResponse,
  ListBusinessesResponse,
  ListPlatformInvitationsResponse,
  ListPlatformProductsResponse,
  PersonPrefillResponse,
  PlatformAddress,
  PlatformBusiness,
  PlatformBusinessAddress,
  PlatformBusinessOwner,
  PlatformInviteResponse,
  PlatformListResponse,
  PlatformPerson,
  PlatformPhone,
  PrefillBusinessResponse,
  PromoCode,
  UpdatePlatformAddressRequest,
  UpdatePlatformPersonRequest,
  UpdatePlatformPersonResponse,
  UserAccountsResponse,
} from '@services/platform/models/identity.model';
import {
  SpendPlanCardsResponse,
  TransactionData,
} from '@services/flexbase/spend-plans.model';
import { CardDetails } from '../../areas/invite-user/content/invite-user';
import { PlaidOnExit } from './models/events.model';
import { PersonsApiListPersonsPhonesRequest } from '@flexbase-eng/types/dist/identity/types/ObjectParamAPI';
import {
  StatementInfo,
  StatementsQueryParams,
} from '@services/flexbase/statements.model';
import { UploadInvoiceResponse } from '@services/flexbase/banking.model';

class PlatformClient {
  private readonly client: Wretcher;

  constructor(baseUrl: string) {
    /**
     * Intentionally not calling .content('application/json') on this.client.
     * Instead let it get handled by Wretch / browser.
     * If you need to set one explicitly, do it on a per-request basis.
     *
     * If you set Content-Type here you can't unset it without ugly workarounds.
     * This messes up posting multipart/form-data where we want the browser to
     * handle the formdata boundary value.
     *
     * See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest_API/Using_FormData_Objects#sending_files_using_a_formdata_object
     */
    this.client = wretch(baseUrl)
      .accept('application/json')
      .middlewares([RefreshTokenMiddleware, TokenMiddleware]);
  }

  async getMonthlyStatementPdf(
    companyId: string,
    accountId: string,
    params: {
      endDate: string;
      startDate: string;
    },
  ): Promise<any> {
    return await this.client
      .url(`/pdf/accounts/${accountId}/businesses/${companyId}/generate`)
      .query(params)
      .get()
      .blob();
  }

  async createExpenseReceipt({
    connectionId,
    invoiceId,
    file,
  }: {
    connectionId: string;
    invoiceId: string;
    file: File;
  }): Promise<Record<string, never>> {
    return await this.client
      .url(`/accounting/expenses/${connectionId}/receipt`)
      .query({ invoiceId })
      .formData({ file })
      .post()
      .json();
  }

  async getExpenseLinks(): Promise<ExpenseLinks200Response> {
    return await this.client.url('/accounting/expenses/links').get().json();
  }

  async getAdsLinks(): Promise<GetAccountingAdsLinks200Response> {
    return await this.client.url('/accounting/ads/links').get().json();
  }

  async getCommerceLinks(): Promise<GetAccountingCommerceLinks200Response> {
    return await this.client.url('/accounting/commerce/links').get().json();
  }

  async getChartOfAccountsByConnectionId(
    connectionId: string,
  ): Promise<Category[]> {
    return await this.client
      .url(`/accounting/expenses/connections/${connectionId}/coa`)
      .get()
      .json();
  }

  async deleteExpenseLink(
    connectionId: string,
  ): Promise<Record<string, never>> {
    return await this.client
      .url(`/accounting/expenses/connections/${connectionId}`)
      .delete();
  }

  async exchangeIntegrationToken(
    request: ExpenseLinkRequest,
  ): Promise<ExpenseLink200Response> {
    return await this.client
      .url('/accounting/expenses/exchange')
      .post(request)
      .json();
  }

  async createBankFeedConnection(): Promise<ExpenseLink> {
    return await this.client.url(`/accounting/feeds/connection`).post().json();
  }

  async getBankFeedOtp(
    connectionId: string,
  ): Promise<{ otp: string; expiresAt: string }> {
    return await this.client
      .url(`/accounting/connections/${connectionId}/bankfeeds/otp`)
      .get()
      .json();
  }

  async querySyncedTransactions(
    connectionId: string,
    transactionIds: string[],
  ): Promise<SyncedExpenses> {
    const request: ExpenseSyncedRequest = {
      transactionIds,
    };
    return await this.client
      .url(`/accounting/expenses/synced/${connectionId}`)
      .post(request)
      .json();
  }

  async syncTransactions(
    connectionId: string,
    request: PostExpenseSyncRequest | PatchExpenseSyncedRequest,
    method: 'post' | 'patch',
  ): Promise<PostExpenseSync200Response | PatchExpenseSynced200Response> {
    const url = this.client.url(`/accounting/expenses/sync/${connectionId}`);

    if (method === 'post') {
      return await url.post(request).json();
    } else {
      return await url.patch(request).json();
    }
  }

  async updateAccountingSettings({
    connectionId,
    request,
  }: {
    connectionId: string;
    request: AccountingSettings;
  }): Promise<unknown> {
    const url = this.client.url(
      `/accounting/expenses/connections/${connectionId}/settings`,
    );

    return await url.patch(request).json();
  }

  async createPersonPhone(
    accountId: string,
    personId: string,
    phone: CreatePlatformPersonPhoneRequest,
  ): Promise<PlatformPhone> {
    return await this.client
      .url(`/identity/accounts/${accountId}/persons/${personId}/phones`)
      .post(phone)
      .json();
  }

  async updatePerson(
    accountId: string,
    personId: string,
    request: UpdatePlatformPersonRequest,
  ): Promise<UpdatePlatformPersonResponse> {
    return await this.client
      .url(`/identity/accounts/${accountId}/persons/${personId}`)
      .patch(request)
      .json();
  }

  async updatePersonPhone(
    accountId: string,
    personId: string,
    phoneId: string,
    phone: CreatePlatformPersonPhoneRequest,
  ): Promise<PlatformPhone> {
    return await this.client
      .url(
        `/identity/accounts/${accountId}/persons/${personId}/phones/${phoneId}`,
      )
      .patch(phone)
      .json();
  }

  async listPersonPhones(
    accountId: string,
    personId: string,
    query?: PersonsApiListPersonsPhonesRequest,
  ): Promise<PlatformListResponse<PlatformPhone>> {
    return await this.client
      .url(`/identity/accounts/${accountId}/persons/${personId}/phones`)
      .query(query ?? {})
      .get()
      .json();
  }

  async createApplication(
    accountId: string,
    product: 'credit' | 'banking',
  ): Promise<void> {
    await this.client
      .url(`/identity/accounts/${accountId}/applications`)
      .post({ product });
  }

  async listApplications(): Promise<ListApplicationsResponse> {
    return await this.client.url('/identity/applications').get().json();
  }

  async listAccountProducts(
    accountId: string,
  ): Promise<ListPlatformProductsResponse> {
    return await this.client
      .url(`/identity/accounts/${accountId}/products`)
      .get()
      .json();
  }

  async getMe(): Promise<PlatformPerson> {
    return await this.client.url('/identity/me').get().json();
  }

  async createBusiness(
    accountId: string,
    business: CrupdatePlatformBusinessRequest,
  ): Promise<PlatformBusiness> {
    return await this.client
      .url(`/identity/accounts/${accountId}/businesses`)
      .post(business)
      .json();
  }

  async listBusiness(accountId: string): Promise<ListBusinessesResponse> {
    return await this.client
      .url(`/identity/accounts/${accountId}/businesses`)
      .get()
      .json();
  }

  async getCardsAndCategories(
    connectionId: string,
  ): Promise<GetExpensesCategoryToCard200Response> {
    return await this.client
      .url(`/accounting/expenses/connections/${connectionId}/cards`)
      .get()
      .json();
  }

  async getCardCategory(
    connectionId: string,
    cardId: string,
  ): Promise<GetExpensesCategoryToCardId200Response> {
    return await this.client
      .url(`/accounting/expenses/connections/${connectionId}/cards/${cardId}`)
      .get()
      .json();
  }

  async updateCardCategory(
    connectionId: string,
    categoryId: string,
    cardId: string,
  ): Promise<PatchExpensesCategoryToCardRequest> {
    return await this.client
      .url(`/accounting/expenses/connections/${connectionId}/cards`)
      .patch({ categoryId, cardId })
      .json();
  }
  async createCategoryCard(
    connectionId: string,
    categoryId: string,
    cardName: string,
    userId: string,
  ): Promise<PostExpensesCategoryToCardRequest> {
    return await this.client
      .url(`/accounting/expenses/connections/${connectionId}/cards`)
      .post({ categoryId, cardName, userId })
      .json();
  }
  async getUserAccounts(): Promise<UserAccountsResponse> {
    return await this.client.url(`/identity/me/accounts`).get().json();
  }

  async getBusiness(
    accountId: string,
    businessId: string,
  ): Promise<PlatformBusiness> {
    return await this.client
      .url(`/identity/accounts/${accountId}/businesses/${businessId}`)
      .get()
      .json();
  }

  async updateBusiness(
    accountId: string,
    businessId: string,
    request: Partial<Omit<PlatformBusiness, 'accountId' | 'id'>>,
  ): Promise<PlatformBusiness> {
    return await this.client
      .url(`/identity/accounts/${accountId}/businesses/${businessId}`)
      .patch(request)
      .json();
  }

  async createBusinessPhone(
    accountId: string,
    businessId: string,
    phone: CreatePlatformPersonPhoneRequest,
  ): Promise<PlatformPhone> {
    return await this.client
      .url(`/identity/accounts/${accountId}/businesses/${businessId}/phones`)
      .post(phone)
      .json();
  }

  async getBusinessOwners(
    accountId: string,
    businessId: string,
  ): Promise<PlatformBusinessOwner[]> {
    return await this.client
      .url(`/identity/accounts/${accountId}/businesses/${businessId}/owners`)
      .get()
      .json();
  }

  async createBusinessOwner(
    accountId: string,
    businessId: string,
    request: { personId: string; percentage: number },
  ): Promise<PlatformBusinessOwner> {
    return await this.client
      .url(`/identity/accounts/${accountId}/businesses/${businessId}/owners`)
      .post(request)
      .json();
  }

  async updateBusinessOwner(
    accountId: string,
    businessId: string,
    ownerPersonId: string,
    percentage: number,
  ): Promise<PlatformBusinessOwner> {
    return await this.client
      .url(
        `/identity/accounts/${accountId}/businesses/${businessId}/owners/${ownerPersonId}`,
      )
      .patch({ percentage })
      .json();
  }

  async deactivateBusinessOwner(
    accountId: string,
    businessId: string,
    ownerPersonId: string,
  ): Promise<void> {
    await this.client
      .url(
        `/identity/accounts/${accountId}/businesses/${businessId}/owners/${ownerPersonId}`,
      )
      .delete();
  }

  async getBusinessAddresses(
    accountId: string,
    businessId: string,
  ): Promise<PlatformListResponse<PlatformBusinessAddress>> {
    return await this.client
      .url(`/identity/accounts/${accountId}/businesses/${businessId}/addresses`)
      .get()
      .json();
  }

  async createBusinessAddress(
    accountId: string,
    businessId: string,
    request: CreatePlatformBusinessAddressRequest,
  ): Promise<PlatformBusinessAddress> {
    return await this.client
      .url(`/identity/accounts/${accountId}/businesses/${businessId}/addresses`)
      .post(request)
      .json();
  }

  async updateBusinessAddress(
    accountId: string,
    businessId: string,
    addressId: string,
    request: CreatePlatformBusinessAddressRequest,
  ) {
    return await this.client
      .url(
        `/identity/accounts/${accountId}/businesses/${businessId}/addresses/${addressId}`,
      )
      .patch(request)
      .json();
  }

  async getSpendPlanCards(
    accountId: string,
    spendPlanId: string,
  ): Promise<SpendPlanCardsResponse[]> {
    return await this.client
      .url(`/accounting/accounts/${accountId}/spendplans/${spendPlanId}/cards`)
      .get()
      .json();
  }

  async getSpendPlansByCardId(
    accountId: string,
    cardId: string,
  ): Promise<SpendPlanView[]> {
    return await this.client
      .url(`/accounting/accounts/${accountId}/cards/${cardId}/spendplans`)
      .get()
      .json();
  }

  async getSpendPlans(accountId: string): Promise<SpendPlanView[]> {
    return await this.client
      .url(`/accounting/accounts/${accountId}/spendplans`)
      .get()
      .json();
  }

  async getAdminSpendPlans(accountId: string): Promise<SpendPlanAdminView[]> {
    return await this.client
      .url(`/accounting/accounts/${accountId}/spendplans/admin/overview`)
      .get()
      .json();
  }

  async getSpendPlanDetails(
    accountId: string,
    spendPlanId: string,
  ): Promise<SpendPlanView> {
    return await this.client
      .url(`/accounting/accounts/${accountId}/spendplans/${spendPlanId}`)
      .get()
      .json();
  }

  async getAdminSpendPlanDetails(
    accountId: string,
    spendPlanId: string,
  ): Promise<SpendPlanAdminView> {
    return await this.client
      .url(`/accounting/accounts/${accountId}/spendplans/${spendPlanId}/admin`)
      .get()
      .json();
  }

  async getSpendPlanTransactions(
    accountId: string,
    spendPlanId: string,
  ): Promise<TransactionData[]> {
    return await this.client
      .url(
        `/accounting/accounts/${accountId}/spendplans/${spendPlanId}/transactions`,
      )
      .get()
      .json();
  }

  async getSpendPlanLimits(
    accountId: string,
    spendPlanId: string,
  ): Promise<SpendPlanLimit[]> {
    return await this.client
      .url(`/accounting/accounts/${accountId}/spendplans/${spendPlanId}/limits`)
      .get()
      .json();
  }

  async createSpendPlan(
    accountId: string,
    payload: CreateSpendPlan,
  ): Promise<CreateSpendPlan200Response> {
    return await this.client
      .url(`/accounting/accounts/${accountId}/spendplans`)
      .post(payload)
      .json();
  }

  async updateSpendPlan(
    accountId: string,
    spendPlanId: string,
    payload: UpdateSpendPlan,
  ): Promise<UpdateSpendPlan200Response> {
    return await this.client
      .url(`/accounting/accounts/${accountId}/spendplans/${spendPlanId}`)
      .patch(payload)
      .json();
  }

  //get spend plan name from a transaction/invoice id
  async getSpendPlanNameTransactions(
    accountId: string,
    invoiceIds: string[],
  ): Promise<InvoiceSpendPlan[]> {
    return await this.client
      .url(`/accounting/accounts/${accountId}/invoices/links`)
      .post({ invoiceIds })
      .json();
  }

  async linkCardToSpendPlan(
    accountId: string,
    spendPlanId: string,
    cardId: string,
    payload: LinkCardToSpendPlanRequest,
  ): Promise<unknown> {
    return await this.client
      .url(
        `/accounting/accounts/${accountId}/spendplans/${spendPlanId}/link/cards/${cardId}`,
      )
      .post(payload)
      .text();
  }

  async getPromoCode(promoCode: string): Promise<PromoCode> {
    return await this.client
      .url(`/servicing/promocodes/code/${promoCode}`)
      .get()
      .json();
  }

  async inviteUser(
    accountId: string,
    email: string,
    type: 'user' | 'signatory',
    apiCompat?: Partial<{
      firstName: string;
      lastName: string;
      roles: string[];
      cardDetails: CardDetails[];
      companyId: string;
    }>,
  ): Promise<PlatformInviteResponse> {
    return await this.client
      .url(`/identity/accounts/${accountId}/invite?invitationType=${type}`)
      .post({
        email,
        ...(apiCompat && { legacyApiCompatInvite: apiCompat }),
      })
      .json();
  }

  async updateExpenseLinksByCategory(
    accounts: PatchAccountingCoaRequestAccountsInner[],
    connectionId: string,
  ) {
    return await this.client
      .url(`/accounting/expenses/connections/${connectionId}/coa`)
      .patch({ accounts })
      .json();
  }

  async prefillPerson(
    accountId: string,
    personId: string,
    dateOfBirth: string,
  ): Promise<PersonPrefillResponse> {
    return await this.client
      .url(`/identity/accounts/${accountId}/persons/${personId}/prefill`)
      .post({ dateOfBirth })
      .json();
  }

  async checkPrefillEligibility(
    accountId: string,
    personId: string,
  ): Promise<{ canAccess: boolean }> {
    return await this.client
      .url(`/identity/accounts/${accountId}/persons/${personId}/prefill`)
      .get()
      .json();
  }

  async createPersonAddress(
    accountId: string,
    personId: string,
    body: UpdatePlatformAddressRequest,
  ): Promise<PlatformAddress> {
    return await this.client
      .url(`/identity/accounts/${accountId}/persons/${personId}/addresses`)
      .post(body)
      .json();
  }

  async updatePersonAddress(
    accountId: string,
    personId: string,
    addressId: string,
    body: Partial<UpdatePlatformAddressRequest>,
  ): Promise<PlatformAddress> {
    return await this.client
      .url(
        `/identity/accounts/${accountId}/persons/${personId}/addresses/${addressId}`,
      )
      .patch(body)
      .json();
  }

  async getBusinessPrefill(
    accountId: string,
    personId: string,
  ): Promise<PrefillBusinessResponse> {
    return await this.client
      .url(
        `/identity/accounts/${accountId}/persons/${personId}/business-prefill`,
      )
      .get()
      .json();
  }

  async createPlaidOnExitRecord(payload: PlaidOnExit): Promise<unknown> {
    return await this.client.url(`/events/plaid/onExit`).post(payload).text();
  }

  async getSpendPlanRequests(
    accountId: string,
    spendPlanId: string,
  ): Promise<SpendPlanRequestFunds[]> {
    return await this.client
      .url(
        `/accounting/accounts/${accountId}/spendplans/${spendPlanId}/requestfunds`,
      )
      .get()
      .json();
  }

  async approveDeclineRequests(
    body: {
      decision: 'approved' | 'declined';
      reason?: string;
    },
    accountId: string,
    spendPlanId: string,
    requestId: string,
  ): Promise<DecisionFundsRequest200Response> {
    return await this.client
      .url(
        `/accounting/accounts/${accountId}/spendplans/${spendPlanId}/requestfunds/${requestId}`,
      )
      .post(body)
      .json();
  }

  async requestFunds(
    accountId: string,
    spendPlanId: string,
    body: {
      amount: number;
      description?: string;
    },
  ): Promise<SpendPlanRequestFunds> {
    return await this.client
      .url(
        `/accounting/accounts/${accountId}/spendplans/${spendPlanId}/requestfunds`,
      )
      .post(body)
      .json();
  }

  async linkInvoiceToSpendPlan(
    accountId: string,
    spendPlanId: string,
    invoiceId: string,
  ): Promise<unknown> {
    return await this.client
      .url(
        `/accounting/accounts/${accountId}/spendplans/${spendPlanId}/link/invoices/${invoiceId}`,
      )
      .post()
      .json();
  }

  async listInvitations(
    accountId: string,
  ): Promise<ListPlatformInvitationsResponse> {
    return await this.client
      .url(`/identity/accounts/${accountId}/invite`)
      .query({ pageSize: 100 })
      .get()
      .json();
  }

  async resendInvitation(accountId: string, personId: string): Promise<void> {
    await this.client
      .url(`/identity/accounts/${accountId}/invite/${personId}`)
      .post()
      .text();
  }

  async getListOfStatements(
    accountId: string,
    queryParams: StatementsQueryParams,
  ): Promise<StatementInfo[]> {
    return await this.client
      .url(`/accounts/${accountId}/statements`)
      .query(queryParams)
      .get()
      .json();
  }

  async getMonthlyBankingStatementPdf(
    accountId: string,
    statementId: string,
  ): Promise<ArrayBuffer> {
    return await this.client
      .url(`/accounts/${accountId}/statements/${statementId}`)
      .get()
      .arrayBuffer();
  }

  async createInvoiceDocument({
    file,
    type,
  }: {
    file: File;
    type: string;
  }): Promise<UploadInvoiceResponse> {
    const formData = new FormData();
    formData.append('type', type);
    formData.append('file', file);
    return await this.client.url(`/documents`).body(formData).post().json();
  }

  async downloadPlatformDocument(docId: string): Promise<Blob> {
    return await this.client
      .url(`/documents/${docId}`)
      .query({ tofile: true })
      .get()
      .blob();
  }
}

export const platformClient = new PlatformClient(
  import.meta.env.VITE_APP_PLATFORM_URL,
);
