import FlexbaseDefaultHeader from 'components/header/current/flexbase-header';
import FlexbaseMainLayout from 'components/layouts/main';
import BillpayPage from './billpay.page';
import { useBillPayFeatureFlag } from '@utilities/feature-flags';
import { Navigate } from 'react-router-dom';

const Billpay = () => {
  const isBillpayEnabled = useBillPayFeatureFlag();

  if (!isBillpayEnabled) {
    return <Navigate to="/" />;
  }

  return (
    <FlexbaseMainLayout>
      <FlexbaseDefaultHeader title="Bill Pay" />
      <BillpayPage />
    </FlexbaseMainLayout>
  );
};

export default Billpay;
