import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { Center, LoadingOverlay, Stack } from '@mantine/core';
import { Analytics } from 'services/analytics/analytics';
import { useMakePaymentUnit } from '@queries/use-credit-payment';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import CardPaymentReview from 'areas/credit/repayment/card-payment-review';
import CardPaymentAmount from 'areas/credit/repayment/card-payment-amount';
import { useGetChargeCardAccounts } from '@queries/use-charge-card-accounts';
import type { DepositAccount } from 'areas/banking/move-funds/move-funds.model';
import { paymentAccountQuery } from 'areas/payments/components/send-payment/payment.states';
import { getInitialChipValue } from 'areas/credit/repayment/review-and-pay';

type Props = {
  closeModal: () => void;
  onSuccess: (
    paymentId: string,
    paymentAmount: number,
    paymentAccount: DepositAccount,
    paymentStatus: string,
  ) => void;
  onError: () => void;
};

const ChargeReviewAndPay = ({ closeModal, onSuccess, onError }: Props) => {
  const [reviewing, setReviewing] = useState(false);
  const [paymentAccount, setPaymentAccount] =
    useRecoilState(paymentAccountQuery);

  const { mutate, isPending } = useMakePaymentUnit();

  const {
    data: chargeAccountsData,
    isLoading: isChargeAccountsLoading,
    isError: isChargeAccountsError,
  } = useGetChargeCardAccounts();

  const {
    data: depositAccountsData,
    isLoading: isDepositAccountsLoading,
    isError: isDepositAccountsError,
  } = useGetDepositAccounts();
  const depositAccounts = depositAccountsData?.accounts ?? [];

  const chargeAccounts =
    chargeAccountsData?.accounts.filter((acc) => acc.status !== 'Closed') ?? [];

  const isLoading = isChargeAccountsLoading || isDepositAccountsLoading;
  const isError = isChargeAccountsError || isDepositAccountsError;

  // for v1 the company will have one charge account
  const currentChargeAccount = chargeAccounts[0];
  const currentBalance = Number(currentChargeAccount.balance) / 100;
  const overdueAmount =
    Number(currentChargeAccount.remainingAmountOverdue) / 100;
  const minimumDueAmount =
    Number(currentChargeAccount.remainingAmountDue) / 100;
  const currentChargePaymentAmount =
    minimumDueAmount > 0 ? minimumDueAmount : currentBalance;
  const currentPaymentAmount = currentChargePaymentAmount;
  const maxPaymentAmount = currentBalance;

  const [chip, setChip] = useState<'min' | 'max' | 'custom'>(
    getInitialChipValue(currentPaymentAmount, currentBalance, minimumDueAmount),
  );
  const [paymentAmount, setPaymentAmount] = useState(currentPaymentAmount);
  const initialCustomValue = chip === 'custom' ? paymentAmount : 0;
  const [customAmount, setCustomAmount] = useState(initialCustomValue);

  const handleReview = (data: {
    chip: 'min' | 'max' | 'custom';
    customAmount: number;
  }) => {
    setChip(data.chip);
    setReviewing(true);
    setCustomAmount(data.customAmount);
  };

  const onConfirmClick = async () => {
    const paymentAccountId = paymentAccount!.id;
    mutate(
      {
        amount: paymentAmount,
        depositAccountId: paymentAccountId,
        lineOfCredit: 'unit',
        chargeCardAccountId: currentChargeAccount.id,
      },
      {
        onSuccess(data) {
          if (data.cardPayment.status !== 'Rejected') {
            Analytics.track('Credit Payment Submitted', { paymentAmount });
            onSuccess(
              data.cardPayment.id,
              paymentAmount,
              paymentAccount as DepositAccount,
              data.cardPayment.status,
            );
          }
        },
        onError() {
          onError();
        },
      },
    );
  };

  useEffect(() => {
    if (isError) {
      onError();
    }
  }, [isError]);

  const paymentAccounts = [
    ...depositAccounts
      .filter((a) => a.status === 'Open')
      .sort((a, b) => b.balance - a.balance),
  ];

  const stack = reviewing ? (
    <CardPaymentReview
      loadingRequest={isPending}
      onConfirmClick={onConfirmClick}
      paymentAccount={paymentAccount!}
      onGoBackClick={() => setReviewing(false)}
      paymentAmount={formatCurrency(paymentAmount)}
    />
  ) : (
    <CardPaymentAmount
      isChargePay={true}
      closeModal={closeModal}
      onReviewClick={handleReview}
      paymentAmount={paymentAmount}
      minimumDue={minimumDueAmount}
      currentBalance={currentBalance}
      minPaymentAmount={overdueAmount}
      paymentAccounts={paymentAccounts}
      maxPaymentAmount={maxPaymentAmount}
      initialData={{ chip, customAmount }}
      onPaymentAmountChange={setPaymentAmount}
      onPaymentAccountChange={setPaymentAccount}
      currentAccount={paymentAccount as DepositAccount}
    />
  );

  if (isLoading) {
    return (
      <Center>
        <LoadingOverlay visible={isLoading} />
      </Center>
    );
  }

  return <Stack>{stack}</Stack>;
};

export default ChargeReviewAndPay;
