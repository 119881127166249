import { Progress } from '@mantine/core';
import { useMakePayment } from '@queries/use-payments';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import { useCreateCounterparty } from '@queries/use-counterparties';
import useModal from 'components/modal/modal-hook';
import Stepper from 'components/stepper/stepper';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Analytics } from 'services/analytics/analytics';
import { flexbaseBankingClient } from 'services/flexbase-client';
import {
  Counterparty,
  CounterpartyAch,
  CounterpartyRequest,
  CounterpartyWire,
  CreateACHMoneyMovement,
  CreateInternationalWireMoneyMovement,
  CreateWireMoneyMovement,
  MoneyMovement,
} from 'services/flexbase/banking.model';
import {
  IsAdmin,
  IsComptroller,
  RolesSelector,
} from 'states/application/product-onboarding';
import { getCurrentIsoDate, isFuturePayment } from 'utilities/dates/dates';
import { formatCurrency } from 'utilities/formatters/format-currency';
import PaymentStep from '../payment-step';
import { InternationalWiresFooter } from './international-payments/footer-international-wires';
import { useInternationalRecipientForm } from './international-payments/hooks/use-international-recipient-form';
import PaymentPurposeForm from './international-payments/payment-purpose-form';
import {
  isInternationalPayMethod,
  isInternationalRecipient,
} from './international-payments/util';
import {
  InternationalPayMethod,
  InternationalRecipient,
  PaymentPurposeFormValues,
} from './international-payments/util/types';
import PaymentAmount from './payment-amount';
import PaymentConfirmation from './payment-confirmation';
import PaymentMethod from './payment-method';
import PaymentRecipient from './payment-recipient';
import PaymentReview from './payment-review';
import {
  PayMethod,
  Recipient,
  depositInitial,
  initialMoneyMovementAmountDetails,
  recipientInitial,
} from './payment.states';
import SendPaymentLayout from './send-payment.layout';
import { useStyles } from './send-payment.styles';
import { v4 as uuidv4 } from 'uuid';
import {
  isParsedBeneficiary,
  parseBeneficiary,
} from '@utilities/custom-hooks/use-beneficiaries';
import {
  parseCreateBeneficiaryParams,
  useCreateInternationalPaymentsBeneficiary,
} from '@queries/use-beneficiaries';
import { showNotification } from '@mantine/notifications';
import { getPlatform } from '@utilities/platform/platform';
import { useQueryClient } from '@tanstack/react-query';
import {
  RECIPIENT,
  useCreateRecipient,
  useGetAllRecipients,
  useUpdateRecipient,
} from '@queries/use-recipients';
import { parseAccountsInfo } from 'areas/payments/pages/recipient-details/util';
import { ParsedAccount } from 'areas/payments/pages/recipient-details/types';
import { CreateRecipientParams } from 'states/recipient/recipient';
import RecipientForm, { RecipientTypeRequirement } from './recipient-form';

type PaymentSteps = {
  RECIPIENT: number;
  RECIPIENT_DETAILS?: number;
  PAYMENT_DETAILS: number;
  AMOUNT: number;
  PAYMENT_PURPOSE?: number;
  REVIEW: number;
  CONFIRMATION: number;
  ERROR: number;
};

const getPaymentSteps = (
  paymentMethod: PayMethod | undefined,
  shouldShowRecipientForm: boolean,
): PaymentSteps => {
  const INITIAL_PAYMENT_STEPS: PaymentSteps = {
    RECIPIENT: 0,
    PAYMENT_DETAILS: 1,
    AMOUNT: 2,
    REVIEW: 3,
    CONFIRMATION: 4,
    ERROR: 5,
  };

  if (!paymentMethod) {
    return INITIAL_PAYMENT_STEPS;
  }

  let steps = { ...INITIAL_PAYMENT_STEPS };

  if (shouldShowRecipientForm) {
    steps = {
      ...steps,
      RECIPIENT_DETAILS: 1,
      PAYMENT_DETAILS: 2,
      AMOUNT: 3,
      REVIEW: 4,
      CONFIRMATION: 5,
      ERROR: 6,
    };
  }

  if (isInternationalPayMethod(paymentMethod)) {
    steps = {
      ...steps,
      PAYMENT_PURPOSE: shouldShowRecipientForm ? 4 : 3, // Adjust based on recipient form
      REVIEW: shouldShowRecipientForm ? 5 : 4,
      CONFIRMATION: shouldShowRecipientForm ? 6 : 5,
      ERROR: shouldShowRecipientForm ? 7 : 6,
    };
  }

  return steps;
};

const getSteppers = (
  currStep: number,
  confirmationStep: number,
  isInternationalWire: boolean,
  shouldShowRecipientForm: boolean,
) => {
  const stepsData = [
    'Recipient',
    'Payment details',
    'Amount & source',
    'Payment purpose',
    'Review',
  ];

  if (!isInternationalWire) {
    const wireIndex = stepsData.indexOf('Payment purpose');
    stepsData.splice(wireIndex, 1);
  }

  if (shouldShowRecipientForm && currStep > 0) {
    stepsData.splice(1, 0, 'Recipient details');
  }

  const progressValue = ((currStep + 1) * 100) / (confirmationStep + 1);

  if (currStep < confirmationStep) {
    return {
      stepper: <Stepper activeStep={currStep} stepsData={stepsData} />,
      stepperSmall: <Progress radius="sm" value={progressValue} />,
    };
  } else {
    return {
      stepper: false,
      stepperSmall: false,
    };
  }
};

export type CounterpartyBase = Omit<Recipient, 'name'>;

// This is a helper function to derive the name of the recipient for 'individual' recipients
export const getDerivedName = (
  recipient: CounterpartyBase | InternationalRecipient,
  givenName?: string,
): string => {
  const { recipientType, firstName, lastName } = recipient;

  if (recipientType === 'individual' && firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (givenName) {
    return givenName;
  } else if (isInternationalRecipient(recipient)) {
    return recipient.name;
  } else {
    // should never occur
    return '';
  }
};

export const createCounterpartyMutationParams = (
  account: CounterpartyBase,
  method: PayMethod,
  recipientName: string,
  recipientId?: string,
): CounterpartyRequest => {
  const {
    nickName,
    routingNumber,
    accountNumber,
    city,
    state,
    postalCode,
    country,
    address,
    addressLine2,
    accountType,
  } = account;

  const derivedName = getDerivedName(account, recipientName);

  const baseCounterparty: any = {
    name: derivedName,
    nickName,
    routingNumber,
    accountNumber,
  };

  if (recipientId) {
    baseCounterparty.recipientId = recipientId;
  }

  if (method === 'wire') {
    const wireCounterparty: CounterpartyWire = {
      ...baseCounterparty,
      address: {
        address: address || '',
        addressLine2: addressLine2 || '',
        city: city || '',
        state: state || '',
        postalCode: postalCode || '',
        country: country || 'US',
      },
    };

    return {
      type: method,
      counterparty: wireCounterparty,
    } as CounterpartyRequest;
  }

  if (method === 'ach') {
    const achCounterparty: CounterpartyAch = {
      ...baseCounterparty,
      accountType,
    };

    return {
      type: method,
      counterparty: achCounterparty,
    } as CounterpartyRequest;
  }

  throw new Error('Unsupported payment method');
};

const useIsLimitedAdmin = () => {
  const isAdmin = useRecoilValue(IsAdmin);
  const isComptroller = useRecoilValue(IsComptroller);
  return isAdmin && !isComptroller;
};

const sortByHighestFrecency = (data?: ParsedAccount[]) => {
  if (!data) {
    return [];
  }

  return data.sort((a, b) => b.frecencyScore - a.frecencyScore);
};

const initialPaymentPurposeFormValues = {
  purpose: '',
  code: undefined,
  source: '',
};

const showRecipientCreationError = () => {
  showNotification({
    title: 'Error creating your new recipient',
    message: `Please make sure the information you've provided is correct.`,
    color: 'red',
  });
};

const SendPayment = () => {
  const { closeAllModals } = useModal();
  const platform = getPlatform();
  const { classes } = useStyles({ platform });
  const [searchParams, setSearchParams] = useSearchParams();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [payment, setPayment] = useState<MoneyMovement | null>(null);
  const navigate = useNavigate();
  const idempotencyToken = uuidv4();
  //NOTE: This is too many useState hooks, but is a temporary fix until we refactor this flow to be route-based. (DM)
  // Recipient State
  const [newRecipient, setNewRecipient] = useState<
    Recipient | InternationalRecipient
  >(recipientInitial);
  // recipient can be preselected via the recipients page, clicking on action Make a Payment
  const [recipientName, setRecipientName] = useState(
    searchParams.get('recipient') || '',
  );
  const [recipientId, setRecipientId] = useState(
    searchParams.get('recipientId') || '',
  );
  const [isNewRecipient, setIsNewRecipient] = useState(false);

  // MoneyMovement Details State
  const [moneyMovementDetails, setMoneyMovementDetails] = useState(
    initialMoneyMovementAmountDetails,
  );
  const [paymentMethod, setPaymentMethod] = useState<PayMethod>('ach');
  const [selectedAccountValue, setSelectedAccountValue] = useState<
    ParsedAccount | undefined
  >();

  const [intlPaymentPurpose, setIntlPaymentPurpose] =
    useState<PaymentPurposeFormValues>(initialPaymentPurposeFormValues);

  const { data: recipientsData, isLoading: recipientsDataIsLoading } =
    useGetAllRecipients();

  const accountDetailsUrlParam = searchParams.get('accountDetails');
  const paymentMethodUrlParam = searchParams.get('paymentMethod');

  useEffect(() => {
    if (accountDetailsUrlParam && paymentMethodUrlParam) {
      setSelectedAccountValue(JSON.parse(accountDetailsUrlParam));
      setPaymentMethod(paymentMethodUrlParam as PayMethod);
    }
  }, [accountDetailsUrlParam]);

  const recipients =
    recipientsData?.recipients.filter((item) => item.status === 'active') ?? [];
  const selectedRecipient = recipients.find(
    (recipient) => recipient.id === recipientId,
  );
  const filteredAccounts = selectedRecipient
    ? parseAccountsInfo([
        ...(selectedRecipient.ach ?? []),
        ...(selectedRecipient.wire ?? []),
        ...(selectedRecipient.book ?? []),
        ...(selectedRecipient.internationalWire ?? []),
        ...(selectedRecipient.internationalWireUsd ?? []),
      ])
    : [];

  const isCompany = selectedRecipient?.type === 'company';
  const missingName =
    !selectedRecipient?.firstName || !selectedRecipient?.lastName;
  const shouldShowRecipientForm =
    (!selectedRecipient?.type || (!isCompany && missingName)) &&
    !isNewRecipient;

  const PaymentSteps = getPaymentSteps(paymentMethod, shouldShowRecipientForm);

  // payment step state
  const [filter, setFilter] = useState(
    recipientName ? 'active_recipient_method' : 'active',
  );
  const [paymentStepState, setPaymentStepState] = useState(
    recipientName ? PaymentSteps.PAYMENT_DETAILS : PaymentSteps.RECIPIENT,
  );

  const roles = useRecoilValue(RolesSelector);
  const isComptroller = roles.includes('COMPTROLLER');

  const isLimitedAdmin = useIsLimitedAdmin();
  const paymentRequest = useMakePayment();

  const sortedRecipients = sortByHighestFrecency(filteredAccounts);

  const { data: depositAccountsData, refetch: refetchDepositAccount } =
    useGetDepositAccounts();
  const depositAccounts = depositAccountsData?.accounts ?? [];

  const {
    mutate: createCounterparty,
    isPending: isPendingCounterpartyCreation,
  } = useCreateCounterparty();

  const {
    mutateAsync: createRecipient,
    isPending: isPendingRecipientCreation,
  } = useCreateRecipient();

  const { mutate: updateRecipient, isPending: isPendingRecipientUpdate } =
    useUpdateRecipient();

  const { mutate: createBeneficiary, isPending: isPendingBeneficiaryCreation } =
    useCreateInternationalPaymentsBeneficiary();

  const internationalAccountForm = useInternationalRecipientForm(
    recipientName,
    recipientId,
    paymentMethod as InternationalPayMethod,
  );

  const futurePayment = isFuturePayment(
    moneyMovementDetails.scheduledFor ?? '',
  );

  const scheduledFor = futurePayment ? moneyMovementDetails.scheduledFor : null;

  const paymentStatusDetails = {
    moneyMovementDetails,
    recipientName,
    method: paymentMethod,
    counterparty: selectedAccountValue as Counterparty,
    recipient: newRecipient,
  };

  const handleSubmit = async () => {
    try {
      setIsProcessing(true);
      let paymentResult: null | MoneyMovement = null;

      const commonParams = {
        idempotencyToken,
        accountId: moneyMovementDetails.sourceAccount.id,
        direction: 'Credit',
        description: moneyMovementDetails.memo,
        notes: moneyMovementDetails?.internalNotes,
        scheduledFor,
      };

      if (!isInternationalPayMethod(paymentMethod)) {
        const paymentRequestParams = {
          ...commonParams,
          type: paymentMethod,
          amount: formatCurrency(moneyMovementDetails.amount),
        };

        paymentResult = await paymentRequest.mutateAsync({
          ...paymentRequestParams,
          counterpartyId: selectedAccountValue!.id,
        } as CreateWireMoneyMovement | CreateACHMoneyMovement);
      } else {
        const internationalPaymentRequestParams = {
          ...commonParams,
          type: 'internationalWire',
          amount: moneyMovementDetails.amount.toFixed(2),
          beneficiaryId: selectedAccountValue!.id,
          reason: intlPaymentPurpose.purpose,
          sourceOfFunds: intlPaymentPurpose.source,
          purposeCode: intlPaymentPurpose.code,
        };

        if (intlPaymentPurpose.code && intlPaymentPurpose.code.length > 0) {
          internationalPaymentRequestParams.purposeCode =
            intlPaymentPurpose.code;
        }

        paymentResult = await paymentRequest.mutateAsync(
          internationalPaymentRequestParams as CreateInternationalWireMoneyMovement,
        );
      }

      if (paymentResult?.id) {
        if (
          moneyMovementDetails.files &&
          moneyMovementDetails.files.length > 0
        ) {
          for (const file of moneyMovementDetails.files) {
            await flexbaseBankingClient.uploadPaymentDoc(paymentResult.id, {
              file,
              description: 'Banking payments docs',
            });
          }
        }

        setPayment(paymentResult);
        setPaymentStepState(PaymentSteps.CONFIRMATION);

        Analytics.track('Payments Sent a Payment', {
          success: true,
          payment: paymentResult,
        });
      }
    } catch (e) {
      console.error(`error sending money movement: ${e}`);
      setPaymentStepState(PaymentSteps.ERROR);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleReset = () => {
    setRecipientName('');
    setRecipientId('');
    setNewRecipient(recipientInitial);
    setIsNewRecipient(false);
    setPaymentStepState(PaymentSteps.RECIPIENT);
    setIntlPaymentPurpose(initialPaymentPurposeFormValues);
    internationalAccountForm.reset();

    setMoneyMovementDetails({
      amount: 0,
      memo: '',
      sourceAccount: depositInitial,
      scheduledFor: getCurrentIsoDate(),
    });
    setPaymentMethod('ach');

    refetchDepositAccount();
  };

  const handleCloseModal = () => {
    handleReset();
    closeAllModals();
    navigate(-1);
  };

  const handleCloseConfirmation = () => {
    closeAllModals();
    navigate('/payments/outgoing');
  };

  const handleRecipientSelect = (
    recipientNameSelected: string,
    recipientIdSelected?: string | null,
  ) => {
    if (!recipientNameSelected) {
      return;
    }
    setRecipientName(recipientNameSelected);
    setRecipientId(recipientIdSelected ?? '');

    setSearchParams(
      {
        recipient: recipientNameSelected,
        recipientId: recipientIdSelected ?? '',
      },
      { replace: true },
    );

    setFilter('active_recipient_method');
    if (shouldShowRecipientForm) {
      setPaymentStepState(PaymentSteps.RECIPIENT_DETAILS as number);
    } else {
      setPaymentStepState(PaymentSteps.PAYMENT_DETAILS);
    }
  };

  const handleAddRecipientFlag = (bool: boolean) => {
    setIsNewRecipient(bool);
  };

  const handleFilterAccounts = () => {
    switch (filter) {
      case 'active':
        return sortedRecipients;
      case 'active_recipient':
        return sortedRecipients.filter((item) => item.id);
      case 'active_recipient_method':
        return sortedRecipients.filter((item) => item.type === paymentMethod);
      case 'new_recipient':
        return [];
      default:
        return sortedRecipients;
    }
  };

  const createNewRecipient = async (
    currentNewRecipient: Recipient | InternationalRecipient,
  ) => {
    const isIndividualType = currentNewRecipient.recipientType === 'individual';

    const createRecipientParams: CreateRecipientParams = {
      ...(isIndividualType
        ? {
            name: `${currentNewRecipient.firstName} ${currentNewRecipient.lastName}`,
            firstName: currentNewRecipient.firstName,
            lastName: currentNewRecipient.lastName,
            type: 'individual',
            dob: currentNewRecipient.dob,
          }
        : {
            name: recipientName,
            type: 'company',
          }),
    };

    const result = await createRecipient(createRecipientParams);
    setRecipientId(result.recipient.id);
    return result.recipient;
  };

  const handleRecipientDetailsNextClick = (
    recipientTypeData: RecipientTypeRequirement,
  ) => {
    updateRecipient(
      {
        id: recipientId,
        params: {
          type: recipientTypeData?.recipientType,
          firstName: recipientTypeData.firstName,
          lastName: recipientTypeData.lastName,
        },
      },
      {
        onSuccess: (data) => {
          if (selectedRecipient) {
            selectedRecipient.firstName = data.recipient.firstName;
            selectedRecipient.lastName = data.recipient.lastName;
            selectedRecipient.type = data.recipient.type;
          }
          setPaymentStepState(PaymentSteps.PAYMENT_DETAILS);
        },
      },
    );
  };

  const queryClient = useQueryClient();

  const handlePaymentMethodNextClick = async (
    currentNewRecipient?: Recipient | InternationalRecipient,
  ) => {
    if (currentNewRecipient) {
      let currentRecipientId = recipientId;

      if (!recipientId) {
        const result = await createNewRecipient(currentNewRecipient);

        // Prevent the function from continuing to execute if the recipient is not created
        if (!result) {
          return false;
        }

        currentRecipientId = result.id;
      }

      if (isInternationalRecipient(currentNewRecipient)) {
        const params = parseCreateBeneficiaryParams({
          ...currentNewRecipient,
          recipientId: currentRecipientId,
        });

        createBeneficiary(params, {
          onSuccess: (data) => {
            const parsedBeneficiary = parseBeneficiary(data.beneficiary);
            setSelectedAccountValue(parsedBeneficiary);
            setPaymentStepState(PaymentSteps.AMOUNT);
          },
          onError: showRecipientCreationError,
        });
      } else {
        const params = createCounterpartyMutationParams(
          currentNewRecipient,
          paymentMethod,
          recipientName,
          currentRecipientId,
        );

        createCounterparty(params, {
          onSuccess: (data) => {
            setSelectedAccountValue(data.counterparty);
            setPaymentStepState(PaymentSteps.AMOUNT);

            queryClient.invalidateQueries({
              queryKey: [RECIPIENT, data.counterparty.recipientId],
            });
          },
          onError: showRecipientCreationError,
        });
      }
    } else {
      setPaymentStepState(PaymentSteps.AMOUNT);
    }
  };

  const handlePaymentAmountNextClick = (method: PayMethod) => {
    if (isInternationalPayMethod(method)) {
      setPaymentStepState(PaymentSteps.PAYMENT_PURPOSE as number);
    } else {
      setPaymentStepState(PaymentSteps.REVIEW);
    }
  };

  const handlePaymentReviewBackClick = (method: PayMethod) => {
    if (isInternationalPayMethod(method)) {
      setPaymentStepState(PaymentSteps.PAYMENT_PURPOSE as number);
    } else {
      setPaymentStepState(PaymentSteps.AMOUNT);
    }
  };

  const handlePaymentMethodBackClick = () => {
    if (shouldShowRecipientForm) {
      setPaymentStepState(PaymentSteps.RECIPIENT_DETAILS as number);
    } else {
      setPaymentStepState(PaymentSteps.RECIPIENT);
      setFilter('active');
    }
  };

  const internationalFooter = isInternationalPayMethod(paymentMethod) && (
    <InternationalWiresFooter />
  );

  const selectedIntlCurrency =
    selectedAccountValue && isParsedBeneficiary(selectedAccountValue)
      ? selectedAccountValue.currency
      : internationalAccountForm.values.currency;

  const selectedCountryOfRecipientsBank =
    selectedAccountValue && isParsedBeneficiary(selectedAccountValue)
      ? selectedAccountValue.country
      : internationalAccountForm.values.countryOfRecipientsBank;

  const defaultPaymentMethod = sortedRecipients[0]?.type;

  const getContent = () => {
    switch (paymentStepState) {
      case PaymentSteps.RECIPIENT:
        return (
          <PaymentRecipient
            onSelect={handleRecipientSelect}
            onAddRecipient={() => handleAddRecipientFlag(true)}
            recipients={recipients}
            isLoadingRecipients={recipientsDataIsLoading}
          />
        );
      case PaymentSteps.RECIPIENT_DETAILS:
        return (
          <RecipientForm
            onNextClick={handleRecipientDetailsNextClick}
            onBackClick={() => {
              setPaymentStepState(PaymentSteps.RECIPIENT);
              setFilter('active');
            }}
            setFilter={setFilter}
            recipientName={recipientName}
            selectedRecipient={selectedRecipient}
            isNewRecipient={isNewRecipient}
            setIsNewRecipient={setIsNewRecipient}
            filterAccounts={handleFilterAccounts}
            isPending={isPendingRecipientUpdate}
          />
        );
      case PaymentSteps.PAYMENT_DETAILS:
        return (
          <PaymentMethod
            accountDetailsUrlParam={accountDetailsUrlParam}
            selectedRecipient={selectedRecipient}
            internationalAccountForm={internationalAccountForm}
            newRecipient={newRecipient}
            defaultMethod={defaultPaymentMethod}
            method={paymentMethod}
            setMethod={setPaymentMethod}
            setFilter={setFilter}
            filterAccounts={handleFilterAccounts}
            recipientName={recipientName}
            setRecipient={setNewRecipient}
            isNewRecipient={isNewRecipient}
            setIsNewRecipient={setIsNewRecipient}
            accountDetails={selectedAccountValue!}
            setAccountDetails={setSelectedAccountValue}
            onNextClick={handlePaymentMethodNextClick}
            onBackClick={handlePaymentMethodBackClick}
            footer={internationalFooter}
            isPending={
              isPendingBeneficiaryCreation ||
              isPendingCounterpartyCreation ||
              isPendingRecipientCreation
            }
          />
        );
      case PaymentSteps.AMOUNT:
        return (
          <PaymentAmount
            moneyMovementDetails={moneyMovementDetails}
            setMoneyMovementDetails={setMoneyMovementDetails}
            method={paymentMethod}
            accounts={depositAccounts}
            onNextClick={() => handlePaymentAmountNextClick(paymentMethod)}
            onBackClick={() =>
              setPaymentStepState(PaymentSteps.PAYMENT_DETAILS)
            }
            selectedIntlCurrency={selectedIntlCurrency}
            footer={internationalFooter}
          />
        );
      case PaymentSteps.PAYMENT_PURPOSE:
        return (
          <PaymentStep
            titleText="Payment purpose"
            nextButtonFormId="paymentPurposeForm"
            nextButtonType="submit"
            onBackClick={() => {
              setPaymentStepState(PaymentSteps.AMOUNT);
            }}
            footer={internationalFooter}
          >
            <PaymentPurposeForm
              currency={selectedIntlCurrency}
              country={selectedCountryOfRecipientsBank}
              existingFormValues={intlPaymentPurpose}
              onSubmit={(data) => {
                setIntlPaymentPurpose(data);
                setPaymentStepState(PaymentSteps.REVIEW);
              }}
            />
          </PaymentStep>
        );
      case PaymentSteps.REVIEW:
        return (
          <PaymentReview
            recipientName={recipientName}
            selectedRecipient={selectedAccountValue!}
            recipient={newRecipient}
            onNextClick={handleSubmit}
            loading={isProcessing} //placeholder for loading state
            onBackClick={() => handlePaymentReviewBackClick(paymentMethod)}
            moneyMovementDetails={moneyMovementDetails}
            setMoneyMovementDetails={setMoneyMovementDetails}
            isLimitedAdmin={isLimitedAdmin}
            payMethod={paymentMethod}
            selectedIntlCurrency={selectedIntlCurrency}
            intlPaymentPurpose={intlPaymentPurpose}
            country={selectedCountryOfRecipientsBank}
          />
        );
      case PaymentSteps.CONFIRMATION:
        return (
          <PaymentConfirmation
            payment={payment}
            onCloseClick={handleCloseConfirmation}
            onMakeAnotherPaymentClick={handleReset}
            paymentStatusDetails={paymentStatusDetails}
            isComptroller={isComptroller}
          />
        );
      case PaymentSteps.ERROR:
        return (
          <PaymentConfirmation
            payment={payment}
            onCloseClick={handleCloseConfirmation}
            onMakeAnotherPaymentClick={handleReset}
            paymentStatusDetails={paymentStatusDetails}
            isComptroller={isComptroller}
            error={true}
          />
        );
    }
  };

  const { stepper, stepperSmall } = getSteppers(
    paymentStepState,
    PaymentSteps.CONFIRMATION,
    isInternationalPayMethod(paymentMethod),
    shouldShowRecipientForm,
  );

  const conditionalStyles =
    paymentStepState >= PaymentSteps.CONFIRMATION
      ? {
          backgroundColor: 'unset',
          width: 'unset',
          border: 'unset',
        }
      : {};

  return (
    <SendPaymentLayout
      onClose={handleCloseModal}
      stepper={stepper}
      stepperSmall={stepperSmall}
    >
      <div className={classes.innerContent} style={conditionalStyles}>
        {getContent()}
      </div>
    </SendPaymentLayout>
  );
};

export default SendPayment;
