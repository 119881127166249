import { Box, Flex, Loader, Select, Text } from '@mantine/core';
import { useGetAllRecipients } from '@queries/use-recipients';
import { FiPlusCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { Recipient } from 'states/recipient/recipient';
import { InvoiceWizard, useInvoiceWizard } from '../../invoice-wizard';
import { useEffect, useState } from 'react';
import RecipientAccountsSelect from '../amount-source-step/recipient-account-select';
import { parseAccountsInfo } from 'areas/payments/pages/recipient-details/util';
import { ParsedAccount } from 'areas/payments/pages/recipient-details/types';

type CreateLabelProps = {
  label?: string;
};

const CreateLabel = ({ label }: CreateLabelProps) => (
  <Flex align="center" gap="xs" py={'xs'}>
    <FiPlusCircle size={16} />
    <Text>Add new recipient{label ? `: ${label}` : ''}</Text>
  </Flex>
);

const getSelectOptions = (recipientList: Recipient[]) => {
  const mostFrequentlyPaid = recipientList
    .sort((a, b) => b.paymentsCount - a.paymentsCount)
    .slice(0, 3);

  const createGroupData = (data: Recipient[], group?: string) =>
    data.map((item) => ({ value: item.id, label: item.name, group }));

  const frequentlyPaidGroupData = createGroupData(
    mostFrequentlyPaid,
    'Most frequently paid',
  );

  const mostFrequentlyPaidIds = new Set(
    mostFrequentlyPaid.map((item) => item.id),
  );

  const otherAccounts = createGroupData(
    recipientList.filter((r) => !mostFrequentlyPaidIds.has(r.id)),
  );

  return [...frequentlyPaidGroupData, ...otherAccounts];
};

const useSelectOptions = () => {
  const { data, isPending } = useGetAllRecipients();
  const { setState, state } = useInvoiceWizard();
  const [selectedRecipientId, setSelectedRecipientId] = useState(
    state?.recipient?.id ?? '',
  );

  const activeRecipients =
    data?.recipients.filter((r) => r.status === 'active') ?? [];

  const handleChange = (val: string) => {
    setSelectedRecipientId(val);

    const foundRecipient = activeRecipients.find((r) => r.id === val);
    setState({ recipient: foundRecipient, recipientAccount: undefined });
  };

  const recipientAccounts = state.recipient
    ? parseAccountsInfo([
        ...(state.recipient.ach ?? []),
        ...(state.recipient.wire ?? []),
      ])
    : [];

  useEffect(() => {
    if (state?.recipient?.id && state.recipientAccount?.id) {
      setState({ isNextEnabled: true });
    } else {
      setState({ isNextEnabled: false });
    }

    if (recipientAccounts.length > 0 && !state.recipientAccount) {
      setState({ recipientAccount: recipientAccounts[0] });
    }
  }, [
    state?.recipient?.id,
    state.recipientAccount?.id,
    recipientAccounts.length,
  ]);

  const handleSelectAccount = (account: ParsedAccount) => {
    setState((prev) => ({
      ...prev,
      recipientAccount: account,
    }));
  };

  return {
    selectOptions: getSelectOptions(activeRecipients),
    isPending,
    selectedRecipientId,
    handleChange,
    recipientAccounts,
    handleSelectAccount,
  };
};

const SelectRecipientStep = () => {
  const navigate = useNavigate();
  const { state } = useInvoiceWizard();
  const { isInvoiceDraft, isActionDisabled = false } = state;
  const {
    selectOptions,
    isPending,
    handleChange,
    selectedRecipientId,
    recipientAccounts,
    handleSelectAccount,
  } = useSelectOptions();

  const handleNavigateToAddRecipientFlow = (val: string) => {
    navigate('/recipients/new');
    return val;
  };

  return (
    <InvoiceWizard.Step hideNext>
      <Select
        required
        searchable
        creatable
        disabled={!isInvoiceDraft || isActionDisabled}
        autoFocus={!selectedRecipientId}
        initiallyOpened={!selectedRecipientId}
        label="Name"
        placeholder="Search for a recipient"
        data={selectOptions}
        rightSection={isPending ? <Loader size="xs" /> : null}
        getCreateLabel={(val) => <CreateLabel label={val} />}
        onCreate={handleNavigateToAddRecipientFlow}
        onChange={handleChange}
        value={selectedRecipientId}
        data-testid={'name'}
        maxDropdownHeight={440}
        filterDataOnExactSearchMatch
        shouldCreate={() => true}
        rightSectionWidth={30}
      />

      {selectedRecipientId && (
        <Box mt="md">
          <Text size="sm">Send to</Text>
          {recipientAccounts.length > 0 ? (
            <RecipientAccountsSelect
              disabled={isActionDisabled}
              accounts={recipientAccounts}
              onSelectAccount={handleSelectAccount}
              currentRecipientAccount={state.recipientAccount}
              country={state.recipientAccount?.country ?? ''}
            />
          ) : (
            <Text size="md">No recipient accounts available</Text>
          )}
        </Box>
      )}
    </InvoiceWizard.Step>
  );
};

SelectRecipientStep.stepId = 'select-recipient-step';

export default SelectRecipientStep;
