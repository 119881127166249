import { platformClient } from '@services/platform/platform-client';
import { useQuery } from '@tanstack/react-query';

const QUERY_KEY = 'get_platform_document';

async function getPlatformDocument(docId: string) {
  return await platformClient.downloadPlatformDocument(docId);
}

export function useGetPlatformDocument(docId: string) {
  return useQuery({
    queryKey: [QUERY_KEY, docId],
    queryFn: () => getPlatformDocument(docId),
    staleTime: Infinity,
    enabled: !!docId,
  });
}
