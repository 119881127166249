import { FlexbaseTable } from '@common/table';
import { Avatar, Badge, Box, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { getInitialsOfNames } from '@utilities/extensions/object';
import { TableColumn } from 'react-data-table-component';
import { useStyles } from './payments.styles';
import { useMemo } from 'react';
import { MoneyMovement } from '@services/flexbase/banking.model';
import {
  useBillpayPaymentsFilters,
  useBillpayPaymentsModalFilters,
} from './use-billpay-payments-filters';
import { getMonthDayYear, sortDate } from '@utilities/dates/dates';
import { formatPaymentType, getPaymentStatusDisplay } from './utils/payments';
import { useBillPayPayments } from '../use-bill-pay';
import Header from '../new-header/header';
import { filterPayments } from '../new-header/filters/payments/payments';

export type PaymentWithInvoiceNumber = MoneyMovement & {
  invoiceNumber: string;
};

const Payments = () => {
  const { classes, theme } = useStyles();
  const { data, isLoading } = useBillPayPayments();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const { activeFiltersArray } = useBillpayPaymentsFilters();
  const payments: PaymentWithInvoiceNumber[] = useMemo(() => {
    return (data.payments ?? []).filter((cc) =>
      activeFiltersArray.every((filter) => filter.fn(cc)),
    );
  }, [data, activeFiltersArray]);

  const columns: TableColumn<PaymentWithInvoiceNumber>[] = [
    {
      name: 'Created on',
      selector: (payment) => payment.createdAt,
      format: (payment) => getMonthDayYear(payment.createdAt),
      sortable: true,
      sortFunction: sortDate,
    },
    {
      name: 'Vendor',
      cell: (payment) => (
        <Box className={classes.containerPayment}>
          <Avatar radius="xl" mr={15} w={45} h={45}>
            {getInitialsOfNames(payment.payCtrParty)}
          </Avatar>
          {payment.payCtrParty}
        </Box>
      ),
      sortable: true,
      selector: (payment) => payment.payCtrParty,
      grow: useMobileView ? 3 : 1.8,
    },
    {
      name: 'Payment method',
      selector: (payment) => payment.type,
      format: (payment) => formatPaymentType(payment.type),
      sortable: true,
    },
    {
      name: 'Invoice number',
      selector: (payment) => payment.invoiceNumber ?? '',
      sortable: true,
    },
    {
      name: 'Payment date',
      selector: (payment) => payment.asOf,
      format: (payment) => getMonthDayYear(payment.asOf),
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (payment) => payment.payAmount,
      sortFunction: (a, b) =>
        parseInt(a.payAmountCents, 10) - parseInt(b.payAmountCents, 10),
      sortable: true,
    },
    {
      name: 'Invoice status',
      cell: (payment) => {
        const { displayStatus, backgroundColor, textColor } =
          getPaymentStatusDisplay(payment.status);
        return (
          <Badge
            styles={{
              root: {
                backgroundColor,
              },
            }}
          >
            <Text color={textColor}>{displayStatus} </Text>
          </Badge>
        );
      },
    },
  ];

  return (
    <Box className={classes.card}>
      <Header
        data={payments}
        placeholder="Search payments"
        useFilters={useBillpayPaymentsFilters}
        useFiltersModal={useBillpayPaymentsModalFilters}
        filtersData={filterPayments(payments)}
      />
      <FlexbaseTable
        columns={columns}
        data={payments}
        isFetchingData={isLoading}
      />
    </Box>
  );
};

export default Payments;
