import { DateTime } from 'luxon';
import { useRecoilValue } from 'recoil';
import { Text, UnstyledButton } from '@mantine/core';
import { TableColumn } from 'react-data-table-component';
import { DownloadIcon } from 'assets/svg';
import FlexbaseTable from 'components/table/flexbase-table';
import { getStatement } from 'areas/banking/statements/utils';
import { StatementInfo } from '@services/flexbase/statements.model';
import { ApplicationState } from 'states/application/product-onboarding';

const getColumns = (accountId: string): TableColumn<StatementInfo>[] => [
  {
    name: 'Month',
    selector: (statement) => statement.period,
    format: (statement) =>
      DateTime.fromFormat(statement.period, 'yyyy-LL').monthLong,
    sortable: true,
  },
  {
    name: <Text mr="xxl">PDF</Text>,
    cell: (statement) => {
      const statementName = `Flex Charge Card Monthly statement ${statement.period}`;
      return (
        <UnstyledButton
          mr="xxl"
          onClick={() =>
            getStatement(statement.vendorStatementId, statementName, accountId)
          }
        >
          <DownloadIcon />
        </UnstyledButton>
      );
    },
    right: true,
  },
];

const ChargeCardsStatementsTable = ({
  statements,
  isLoading,
}: {
  isLoading: boolean;
  statements: StatementInfo[];
}) => {
  const { accountId } = useRecoilValue(ApplicationState);
  const columns = getColumns(accountId);

  return (
    <FlexbaseTable
      data={statements}
      columns={columns}
      noDataComponent={
        <Text mt="md" fw={500}>
          There are no statements to display
        </Text>
      }
      pagination={statements.length > 10}
      isFetchingData={isLoading}
    />
  );
};

export default ChargeCardsStatementsTable;
