import AccountSelection from '../../../../payments/components/account-selection';
import {
  DepositAccount,
  PlaidAccount,
} from '../../../../banking/move-funds/move-funds.model';
import { useRecoilState } from 'recoil';
import { ApplicationState } from '../../../../../states/application/product-onboarding';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';

export const RedemptionAccountContent = () => {
  const [
    {
      company: { ...company },
    },
  ] = useRecoilState(ApplicationState);

  const { data: depositAccountsData, isLoading: isDepositAccountsLoading } =
    useGetDepositAccounts();

  const plaidAccounts = company.financialInstitutions
    .filter((fi) => fi.active && !fi.unlinked)
    .map((fi) => {
      return {
        ...fi,
        plaidOrDeposit: 'plaid',
      } as PlaidAccount;
    });

  const depositAccounts =
    depositAccountsData?.accounts
      .filter((acc) => acc.status === 'Open')
      .sort((a, b) => b.balance - a.balance)
      .map((acc) => {
        return {
          ...acc,
          plaidOrDeposit: 'deposit',
        } as DepositAccount;
      }) ?? [];

  const accountsList = [...plaidAccounts, ...depositAccounts];

  return (
    <div>
      <div style={{ marginTop: '20px' }}>
        <AccountSelection
          accounts={accountsList}
          // TODO eventually figure out which account to default to. For now, selecting the first one.
          currentAccount={accountsList[0]}
          onAccountChange={(account) => {
            // this is where you set the state
            /* eslint-disable no-console */
            console.log(account);
          }}
          showAccountFilters
          disabled={isDepositAccountsLoading}
        />
      </div>
    </div>
  );
};
