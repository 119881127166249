import { flexbaseBankingClient } from '@services/flexbase-client';
import { useQuery } from '@tanstack/react-query';

const RESERVE_ACCOUNT_QUERY_KEY = 'reserveAccount';

export const useGetReserveAccountsList = () => {
  return useQuery({
    queryKey: [RESERVE_ACCOUNT_QUERY_KEY, 'list'],
    queryFn: async () => {
      return await flexbaseBankingClient.getReserveAccounts();
    },
  });
};

export const useGetReserveAccountInfo = (accountId: string) => {
  return useQuery({
    queryKey: [RESERVE_ACCOUNT_QUERY_KEY, 'info', accountId],
    queryFn: async () => {
      return await flexbaseBankingClient.getReserveAccountInfo(accountId);
    },
    enabled: !!accountId,
  });
};
