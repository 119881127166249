import { SDK } from '@flexbase-eng/sdk-typescript';
import { refreshAndStoreTokenFromUser } from '@utilities/auth/refresh-token';
import { retrieveTokenFromLocalStorage } from '@utilities/auth/store-token';

export const platformSdk = new SDK({
  serverURL: import.meta.env.VITE_APP_PLATFORM_URL,
  token: async () => {
    await refreshAndStoreTokenFromUser();
    const token = retrieveTokenFromLocalStorage();
    return token?.access_token || 'no-token';
  },
});
