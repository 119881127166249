import { MultiSelect } from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { CompanyIdState } from '../../../../../onboarding/onboarding-form.state';
import { useCreditTransactions } from '@queries/use-credit-transactions';
import { useMemo } from 'react';
import { uniq } from 'underscore';
import { CreditTransactionsTableRow } from '../../credit-transactions-table-helpers';
import { ActiveFiltersReturnType } from '@common/filter/filters';
import {
  useChargeCardTransactionModalFilters,
  useCreditTransactionModalFilters,
} from '../use-credit-transaction-filters';

type CreditTransactionsNameFilterProps = {
  filterHook: ActiveFiltersReturnType<CreditTransactionsTableRow>;
};

const CreditNameFilter = ({
  filterHook,
}: CreditTransactionsNameFilterProps) => {
  const companyId = useRecoilValue(CompanyIdState);
  const { data } = useCreditTransactions({ companyId, params: {} });

  const who = useMemo(() => {
    if (data) {
      return uniq(data.transactions.map((t) => t.who).filter((t) => !!t));
    }
    return [];
  }, [data]);

  const { addFilter, removeFilter, getFilterByKey } = filterHook;

  return (
    <MultiSelect
      data={who}
      placeholder="Name"
      searchable
      nothingFound="Nothing found"
      value={getFilterByKey('name')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('name', {
            key: 'name',
            filterValue: val,
            fn: (row) => val.includes(row.name),
            label: `Name: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('name');
        }
      }}
    />
  );
};

export const CreditTransactionsNameFilter = () => {
  const filterHook = useCreditTransactionModalFilters();

  return <CreditNameFilter filterHook={filterHook} />;
};

export const ChargeCardTransactionsNameFilter = () => {
  const filterHook = useChargeCardTransactionModalFilters();

  return <CreditNameFilter filterHook={filterHook} />;
};
