import { ActiveFiltersReturnType } from '@common/filter/filters';
import { Flex, Select, Text, useMantineTheme } from '@mantine/core';
import { isTruthyString } from '@utilities/validators/validate-string';
import { useBillpayInvoicesWithRecipients } from 'areas/billpay/use-bill-pay';
import { forwardRef, useMemo } from 'react';
import { BillpayInvoice } from 'states/bill-pay/bill-pay';

type ItemProps = {
  label: string;
  value: string;
  count: number;
} & React.ComponentPropsWithoutRef<'div'>;

const Status = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, value, count, ...others }: ItemProps, ref) => {
    const theme = useMantineTheme();

    let color = '';
    switch (value) {
      case 'drafted':
        color = theme.fn.themeColor('flexbaseGrey', 0);
        break;
      case 'pending':
        color = theme.fn.themeColor('tertiary', 0);
        break;
      case 'succeeded':
        color = theme.fn.themeColor('positive', 0);
        break;
      case 'failed':
        color = theme.fn.themeColor('critical', 0);
        break;
      default:
        color = '';
        break;
    }

    return (
      <Flex justify="space-between" align="center" {...others}>
        <div
          style={{ backgroundColor: color, padding: 4, borderRadius: 6 }}
          ref={ref}
        >
          <Text>{label}</Text>
        </div>
        <Text>{count}</Text>
      </Flex>
    );
  },
);
Status.displayName = 'BillsStatus';

type Props = {
  useFilters: () => ActiveFiltersReturnType<BillpayInvoice>;
  useModalFilters: () => ActiveFiltersReturnType<BillpayInvoice>;
};

const StatusSelect = ({ useFilters, useModalFilters }: Props) => {
  const { removeFilter, addFilter, getFilterByKey } = useFilters();
  const { removeFilter: removeFilterModal, addFilter: addFilterModal } =
    useModalFilters();
  const { data, isSuccess } = useBillpayInvoicesWithRecipients();

  const value = getFilterByKey('select')?.filterValue ?? null;

  const invoices: BillpayInvoice[] | undefined = useMemo(() => {
    if (isSuccess) {
      return data.invoices;
    } else {
      return [];
    }
  }, [data]);

  const handleSelectChange = (val: string) => {
    if (!val) {
      removeFilter('select');
      removeFilterModal('select');
      return;
    }

    if (val === 'all') {
      removeFilter('select');
      removeFilterModal('select');
      return;
    }
    addFilter('select', {
      key: 'select',
      filterValue: val,
      label: `Includes ${val}`,
      showChip: false,
      fn: (item) => {
        const normalizedFilterText = val.toLowerCase();
        const strValues = Object.values(item).filter(isTruthyString);
        return strValues
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
    addFilterModal('select', {
      key: 'select',
      filterValue: val,
      label: `Includes ${val}`,
      showChip: false,
      fn: (item) => {
        const normalizedFilterText = val.toLowerCase();
        const strValues = Object.values(item).filter(isTruthyString);
        return strValues
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const selectData = useMemo(() => {
    const countAll = invoices?.length;
    const draftedCount = invoices?.filter(
      (invoice) => invoice.status === 'drafted',
    ).length;
    const pendingCount = invoices?.filter(
      (invoice) => invoice.status === 'pending',
    ).length;
    const succeededCount = invoices?.filter(
      (invoice) => invoice.status === 'succeeded',
    ).length;
    const failedCount = invoices?.filter(
      (invoice) => invoice.status === 'failed',
    ).length;

    return [
      { value: 'all', label: 'All', count: countAll },
      { value: 'drafted', label: 'Drafted', count: draftedCount },
      { value: 'pending', label: 'Pending', count: pendingCount },
      { value: 'succeeded', label: 'Succeeded', count: succeededCount },
      { value: 'failed', label: 'Failed', count: failedCount },
    ];
  }, [invoices]);

  return (
    <Select
      data={selectData}
      placeholder="Status"
      value={value}
      itemComponent={Status}
      onChange={(val: string) => handleSelectChange(val)}
    />
  );
};

export default StatusSelect;
