import { Box, Button, Text, TextInput, createStyles } from '@mantine/core';
import { CloseIcon, SearchIcon } from 'assets/svg';
import {
  useSpendTransactionFilters,
  useSpendTransactionModalFilters,
} from './use-spend-transactions-filter';
import { isTruthyString } from '@utilities/validators/validate-string';
import { IoFunnelOutline } from 'react-icons/io5';
import { SpendNameFilter } from './filters/spend-name-filter';
import { SpendAmountFilter } from './filters/spend-amount-filter';
import { SpendDateRangeFilter } from './filters/spend-date-filter';
import { SpendAttachmentsFilter } from './filters/spend-attachments-filter';
import { SpendCardsFilter } from './filters/spend-cards-filter';
import { SpendCategoryFilter } from './filters/spend-category-filter';
import { SpenMerchantFilter } from './filters/spend-merchant-filter';
import useModal from '@common/modal/modal-hook';
import { SpendStatusFilter } from './filters/spend-status-filter';
import { FilterModal } from '@common/filter/filter-modal';
import { SpendSyncStatusFilter } from './filters/spend-sync-status-filter';
import { useEffect, useRef } from 'react';
import { TransactionData } from '@services/flexbase/spend-plans.model';

type Props = {
  data: TransactionData[];
};

const SpendTransactionsTableHeader = ({ data }: Props) => {
  const modal = useModal();
  const { classes } = useStyles();
  const {
    applyAllFilters: applyAllModalFilters,
    activeFilters: activeModalFilters,
  } = useSpendTransactionModalFilters();

  const {
    applyAllFilters: applyAllTableFilters,
    removeAllFilters,
    activeFilters: activeTableFilters,
    activeFiltersArray: activeTableFiltersArray,
    getFilterByKey,
    addFilter,
    removeFilter,
  } = useSpendTransactionFilters();

  const activeModalFiltersRef = useRef(activeModalFilters);

  useEffect(() => {
    activeModalFiltersRef.current = activeModalFilters;
  }, [activeModalFilters]);

  const filters = [
    {
      key: 'sync',
      header: 'Sync status',
      component: <SpendSyncStatusFilter />,
    },
    {
      key: 'status',
      header: 'Transaction status',
      component: <SpendStatusFilter />,
    },
    {
      key: 'date',
      header: 'Date',
      component: <SpendDateRangeFilter />,
    },
    {
      key: 'name',
      header: 'Cardholder',
      component: <SpendNameFilter transactions={data || []} />,
    },
    {
      key: 'cards',
      header: 'Cards',
      component: <SpendCardsFilter transactions={data || []} />,
    },
    {
      key: 'caategory',
      header: 'Category',
      component: <SpendCategoryFilter transactions={data || []} />,
    },
    {
      key: 'attachments',
      header: 'Attachments',
      component: <SpendAttachmentsFilter />,
    },
    {
      key: 'merchant',
      header: 'Merchant',
      component: <SpenMerchantFilter transactions={data || []} />,
    },
    {
      key: 'amount',
      header: 'Amount',
      component: <SpendAmountFilter />,
    },
  ];

  const onSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(transaction)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const handleModalSave = () => {
    applyAllTableFilters(activeModalFiltersRef.current);
    modal.closeAllModals();
  };

  const handleModalCancel = () => {
    modal.closeAllModals();
  };

  const filterChips = activeTableFiltersArray.filter((f) => f.showChip);

  const openModal = () => {
    applyAllModalFilters(activeTableFilters);

    screen.width <= 767
      ? modal.openFullModal(
          <FilterModal
            filters={filters}
            onSave={handleModalSave}
            onCancel={handleModalCancel}
          />,
        )
      : modal.openRightModal(
          <FilterModal
            filters={filters}
            onSave={handleModalSave}
            onCancel={handleModalCancel}
          />,
        );
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing.md,
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        })}
        mb="md"
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: theme.spacing.md,
          })}
        >
          <TextInput
            placeholder="Search transactions"
            icon={<SearchIcon width={20} height={20} />}
            value={getFilterByKey('search')?.filterValue || ''}
            onChange={(e) => onSearchChange(e.target.value)}
            sx={(theme) => ({
              width: 368,
              [theme.fn.smallerThan('md')]: {
                width: '100%',
              },
            })}
          />
          <Button
            variant="outline"
            rightIcon={<IoFunnelOutline />}
            onClick={() => openModal()}
            disabled={data.length === 0}
          >
            Filters
          </Button>
        </Box>
      </Box>
      {filterChips.length > 0 && (
        <Box
          sx={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}
          mih={30}
          mb="md"
        >
          {filterChips.map((p) => (
            <Box
              sx={(theme) => ({
                height: 30,
                borderRadius: theme.radius.xs,
                border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
                backgroundColor: theme.fn.themeColor('neutral', 2),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                padding: `0px ${theme.spacing.md}`,
                gap: 4,
                [theme.fn.smallerThan('md')]: {
                  height: 'auto',
                  padding: `0px ${theme.spacing.xl}`,
                },
              })}
              onClick={() => {
                removeFilter(p.key);
              }}
              key={p.key}
            >
              <Text color="black" fz={14} fw={500}>
                {p.label}
              </Text>
              <CloseIcon color="black" style={{ marginLeft: 'auto' }} />
            </Box>
          ))}
          <Text
            className={classes.clearFilters}
            onClick={() => {
              removeAllFilters();
            }}
          >
            Clear filters
          </Text>
        </Box>
      )}
    </>
  );
};

export default SpendTransactionsTableHeader;

const useStyles = createStyles((theme) => ({
  clearFilters: {
    cursor: 'pointer',
    color: theme.fn.themeColor('primarySecondarySuccess', 2),
  },
}));
