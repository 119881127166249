import { useState } from 'react';
import { rem } from '@mantine/core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';

import {
  CompanySelector,
  ProductOnboardingBtnLoaderState,
} from 'states/application/product-onboarding';
import useModal from 'components/modal/modal-hook';
import { useLogout } from 'services/logout/logout-hook';
import BankingModal, {
  UnqualifiedReason,
} from './components/offer-banking-modal';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { without } from 'underscore';
import { ProductApplicationRoutes } from './onboarding.constants';
import { useRouteSectionContext } from '@common/routes/route-context';

const useShowBankingModal = () => {
  const logout = useLogout();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const { openSizedCenterModal, closeAllModals } = useModal();
  const setLoading = useSetRecoilState(ProductOnboardingBtnLoaderState);
  const { optedProducts } = useRecoilValue(CompanySelector);
  const { setSectionAndNavigate } = useRouteSectionContext();

  const continueWithBanking = async (onContinueWithBankingOnly: () => void) => {
    setLoading(true);
    try {
      onContinueWithBankingOnly();
      setSectionAndNavigate(
        'application',
        ProductApplicationRoutes.BANKING_ONLY,
      );
      closeAllModals();
    } catch (err) {
      console.error('Error creating banking application', err);
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelApplication = async () => {
    try {
      await flexbaseOnboardingClient.updateCompany({
        // removing 'BANKING' from optedProducts and with appStatus.reason:'unqualified',
        // we can check that the user canceled the application (for now)
        optedProducts: without(optedProducts, 'BANKING'),
      });
      showNotification({
        message: 'The application was canceled',
        color: 'flexbase-teal',
      });
      await logout({
        onLogout: () => navigate('/register'),
      });
      closeAllModals();
    } catch (err) {
      console.error('Error canceling application', err);
    }
  };

  return ({
    unqualifiedReason,
    onContinueWithBankingOnly,
  }: {
    unqualifiedReason: UnqualifiedReason;
    onContinueWithBankingOnly: () => void;
  }) => {
    const handleContinueWithBanking = () => {
      continueWithBanking(onContinueWithBankingOnly);
    };

    openSizedCenterModal(
      <BankingModal
        errorMessage={error}
        reason={unqualifiedReason}
        onApplyForBanking={handleContinueWithBanking}
        onCancelApplication={handleCancelApplication}
      />,
      {
        margin: '0px',
        width: rem(500),
      },
    );
  };
};

export default useShowBankingModal;
