import {
  Box,
  Button,
  createStyles,
  Group,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import {
  CloseIcon,
  DollarCircleIcon,
  PlusSignIcon,
  SearchIcon,
  StarIcon,
  TeamsIcon,
} from 'assets/svg';
import NewAccountModal from './new-account.modal';
import { useMediaQuery } from '@mantine/hooks';
import {
  useBankingAccountsFilters,
  useBankingAccountsFiltersModal,
} from './use-banking-filters';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { BankingAccountsStatusFilter } from './filters/banking-accounts-status-filter';
import { BankingAccountsBalanceFilter } from './filters/banking-accounts-balance-filter';
import { BankingAccountsNameFilter } from './filters/banking-accounts-nickname-filter';
import { useGetUser } from '@queries/use-users';
import { UserIdState } from 'areas/onboarding/onboarding-form.state';
import { isTruthyString } from 'utilities/validators/validate-string';
import useModal from '@common/modal/modal-hook';
import { FilterModal } from '@common/filter/filter-modal';
import { IoFunnelOutline } from 'react-icons/io5';

const BankingAccountsHeader = () => {
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [addModal, setAddModal] = useState(false);
  const [roles, setRoles] = useState<string[]>([]);
  const userId = useRecoilValue(UserIdState);
  const { data, isSuccess } = useGetUser(userId);
  const modal = useModal();
  const { classes } = useStyles();

  const {
    applyAllFilters: applyAllModalFilters,
    activeFilters: activeModalFilters,
  } = useBankingAccountsFiltersModal();

  const {
    applyAllFilters: applyAllTableFilters,
    removeAllFilters,
    activeFilters: activeTableFilters,
    activeFiltersArray: activeTableFiltersArray,
    getFilterByKey,
    addFilter,
    removeFilter,
  } = useBankingAccountsFilters();

  const activeModalFiltersRef = useRef(activeModalFilters);
  const activeFilterTableRef = useRef(activeTableFilters);

  const onSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(transaction)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const filters = [
    {
      key: 'status',
      header: 'Status',
      icon: <StarIcon />,
      component: <BankingAccountsStatusFilter />,
    },
    {
      key: 'balance',
      header: 'Balance',
      icon: <DollarCircleIcon />,
      component: <BankingAccountsBalanceFilter />,
    },
    {
      key: 'nickName',
      header: 'Account',
      icon: <TeamsIcon />,
      component: <BankingAccountsNameFilter />,
    },
  ];

  const handleModalSave = () => {
    applyAllTableFilters(activeModalFiltersRef.current);
    modal.closeAllModals();
  };

  const handleModalCancel = () => {
    modal.closeAllModals();
  };

  const filterChips = activeTableFiltersArray.filter((f) => f.showChip);

  const openModal = () => {
    applyAllModalFilters(activeTableFilters);
    useMobileView
      ? modal.openFullModal(
          <FilterModal
            filters={filters}
            onSave={handleModalSave}
            onCancel={handleModalCancel}
          />,
        )
      : modal.openRightModal(
          <FilterModal
            filters={filters}
            onSave={handleModalSave}
            onCancel={handleModalCancel}
          />,
        );
  };

  useEffect(() => {
    if (isSuccess && data?.roles) {
      setRoles(data.roles || []);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    activeModalFiltersRef.current = activeModalFilters;
    activeFilterTableRef.current = activeTableFilters;
  }, [activeModalFilters, activeTableFilters]);

  return (
    <>
      <Group position="apart" mb="md">
        <Box
          sx={() => ({
            display: 'flex',
            gap: theme.spacing.md,
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          })}
          mb="md"
        >
          <Box
            sx={() => ({
              display: 'flex',
              gap: theme.spacing.md,
              [theme.fn.smallerThan('sm')]: {
                flexDirection: 'column',
                width: '100%',
              },
            })}
          >
            <TextInput
              w={useMobileView ? '100%' : 368}
              placeholder="Search accounts"
              onChange={(e) => onSearchChange(e.target.value)}
              icon={<SearchIcon width={20} height={20} />}
              value={getFilterByKey('search')?.filterValue || ''}
            />
            <Button
              variant="outline"
              rightIcon={<IoFunnelOutline />}
              onClick={() => openModal()}
            >
              Filters
            </Button>
          </Box>
        </Box>
        <Group>
          <Button
            leftIcon={<PlusSignIcon width={20} />}
            variant="light"
            onClick={() => {
              setAddModal(true);
            }}
            mt={useMobileView ? '1rem' : 0}
            mb={useMobileView ? '1rem' : 0}
            fullWidth={useMobileView}
            disabled={!roles.includes('ADMIN')}
          >
            Open Flex account
          </Button>
          <NewAccountModal addModal={addModal} setAddModal={setAddModal} />
        </Group>
      </Group>
      {filterChips.length > 0 && (
        <Box
          sx={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}
          mih={30}
          mb="md"
        >
          {filterChips.map((p) => (
            <Box
              sx={() => ({
                height: 30,
                borderRadius: theme.radius.xs,
                border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
                backgroundColor: theme.fn.themeColor('neutral', 2),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                padding: `0px ${theme.spacing.md}`,
                gap: 4,
                [theme.fn.smallerThan('md')]: {
                  height: 'auto',
                  padding: `0px ${theme.spacing.xl}`,
                },
              })}
              onClick={() => {
                removeFilter(p.key);
              }}
              key={p.key}
            >
              <Text color="black" fz={14} fw={500}>
                {p.label}
              </Text>
              <CloseIcon color="black" style={{ marginLeft: 'auto' }} />
            </Box>
          ))}
          <Text
            className={classes.clearFilters}
            onClick={() => {
              removeAllFilters();
            }}
          >
            Clear filters
          </Text>
        </Box>
      )}
    </>
  );
};

export default BankingAccountsHeader;

const useStyles = createStyles((theme) => ({
  clearFilters: {
    cursor: 'pointer',
    color: theme.fn.themeColor('primarySecondarySuccess', 2),
  },
}));
