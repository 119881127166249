import { useQuery } from '@tanstack/react-query';
import { flexbaseOnboardingClient } from 'services/flexbase-client';

const QUERY_KEY = 'creditLines';

export const useCreditLines = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getLinesOfCredit();
    },
    meta: {
      errorMessage: 'Could not retrieve card programs at this time.',
    },
  });
};
