import { Avatar, Badge, Box } from '@mantine/core';
import { useBillPayStyles } from './bills.styles';
import { FlexbaseTable } from '@common/table';
import { useMemo } from 'react';
import { BillpayInvoice } from 'states/bill-pay/bill-pay';
import { useBillpayInvoicesFilters } from './use-billpay-bills-filters';
import { TableColumn } from 'react-data-table-component';
import { getInitialsOfNames } from '@utilities/extensions/object';
import { useMediaQuery } from '@mantine/hooks';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters/format-strings';
import { getMonthDayYear, sortDateFromSQL } from '@utilities/dates/dates';
import { formatCurrency } from '@utilities/formatters/format-currency';
import { DateTime } from 'luxon';
import EmptyBillTable from './empty-bill-table';
import { useBillpayInvoicesWithRecipients } from '../use-bill-pay';
import { SkeletonLoading } from '@flexbase-eng/web-components';
import Header from '../header/header';
import { useNavigate } from 'react-router-dom';
import { filterStatus } from './filters/filters';

export const sortInvoiceAmount = (a: string, b: string) => {
  const amountA = parseFloat(a) / 100;
  const amountB = parseFloat(b) / 100;
  return amountB - amountA;
};

const Bills = () => {
  const { classes, theme } = useBillPayStyles();
  const { data, isLoading, isSuccess } = useBillpayInvoicesWithRecipients();
  const { activeFiltersArray } = useBillpayInvoicesFilters();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const navigate = useNavigate();

  const getDueInDays = (dueDate: string) => {
    const due = DateTime.fromISO(dueDate);
    const now = DateTime.now();
    const diffDays = due.diff(now, 'days').days;

    if (diffDays > 0) {
      return `${Math.ceil(diffDays)} days`;
    } else if (diffDays === 0) {
      return 'Due today';
    } else {
      return `${Math.abs(Math.floor(diffDays))} days ago`;
    }
  };

  const invoices: BillpayInvoice[] = useMemo(() => {
    if (!isSuccess) return [];

    return (data.invoices ?? []).filter((cc) =>
      activeFiltersArray.every((filter) => filter.fn(cc)),
    );
  }, [data, activeFiltersArray]);

  const columns: TableColumn<BillpayInvoice>[] = [
    {
      id: 'dueDate',
      name: 'Due date',
      selector: (invoice) => invoice.dueDate,
      format: (invoice) => getMonthDayYear(invoice.dueDate),
      sortFunction: (a, b) => sortDateFromSQL(a.dueDate, b.dueDate),
      sortable: true,
    },
    {
      id: 'vendor',
      name: 'Vendor',
      cell: (row) => (
        <Box className={classes.containerVendor}>
          <Avatar radius="xl" mr={15} w={45} h={45}>
            {getInitialsOfNames(row.recipientName ?? '')}
          </Avatar>
          {row.recipientName}
        </Box>
      ),
      sortable: true,
      selector: (row) => row.recipientName ?? '',
      grow: useMobileView ? 2 : 1,
    },
    {
      id: 'invoiceNumber',
      name: 'Invoice number',
      selector: (invoice) => invoice.invoiceNumber ?? '',
      sortable: true,
    },
    {
      id: 'invoiceAmount',
      name: 'Invoice amount',
      selector: (invoice) => invoice.total,
      format: (invoice) => formatCurrency(invoice.total / 100),
      sortable: true,
      sortFunction: (a, b) =>
        sortInvoiceAmount(a.total.toString(), b.total.toString()),
    },
    {
      id: 'createdAt',
      name: 'Created on',
      selector: (invoice) => invoice.createdAt,
      format: (invoice) => getMonthDayYear(invoice.createdAt),
      sortFunction: (a, b) => sortDateFromSQL(a.createdAt, b.createdAt),
      sortable: true,
    },
    {
      id: 'dueIn',
      name: 'Due in',
      selector: (invoice) => getDueInDays(invoice.dueDate),
      format: (invoice) => getDueInDays(invoice.dueDate),
      sortFunction: (a, b) => sortDateFromSQL(a.dueDate, b.dueDate),
      sortable: true,
    },
    {
      id: 'status',
      name: 'Invoice status',
      cell: (invoice) => (
        <Badge>{capitalizeOnlyFirstLetter(invoice.status)}</Badge>
      ),
      selector: (invoice) => invoice.status,
      sortable: true,
    },
  ];

  const handleRowClick = (invoice: BillpayInvoice) => {
    navigate(`/bill-pay/${invoice.id}/edit`);
  };

  if (isLoading) {
    return <SkeletonLoading />;
  }

  if (data.invoices && data.invoices.length === 0) {
    return (
      <EmptyBillTable headingText="There are no unpaid bills to display" />
    );
  }

  return (
    <Box className={classes.card} mb="lg">
      <Header
        placeholder="Search bills"
        useFilters={useBillpayInvoicesFilters}
        data={data.invoices}
        filtersData={filterStatus(data.invoices || [])}
        bills={true}
      />
      <FlexbaseTable
        defaultSortFieldId="createdAt"
        columns={columns}
        data={invoices}
        isFetchingData={isLoading}
        onRowClicked={handleRowClick}
      />
    </Box>
  );
};

export default Bills;
