import { Alert, Box, Group } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';
import { FlexIconNoPadding, RedAlertIcon } from 'assets/svg';
import { useStyles } from './integration-modal.styles';
import { useMediaQuery } from '@mantine/hooks';
import { useLoginFormContext } from 'areas/login/login-form.context';
import Authenticated from './authenticated';
import { useEffect } from 'react';
import { useAuthToken } from 'states/auth/auth-token';
import { useIntegrationModal } from './integration-modal-hooks';
import { integrationModalState } from './integration-modal-state';
import { useSetRecoilState } from 'recoil';

export const IntegrationModalContainer = () => {
  const { classes } = useStyles();
  const useMobileView = useMediaQuery('(max-width: 768px)');
  const { state, currentStep } = useLoginFormContext();
  const [searchParams] = useSearchParams();
  const { userIsAuthorized } = useAuthToken();
  const { handleOtp, logedByflow } = useIntegrationModal();

  const setIntegrationModalState = useSetRecoilState(integrationModalState);

  useEffect(() => {
    const redirectUri = searchParams.get('redirect_uri');
    if (redirectUri) {
      setIntegrationModalState((prev) => ({
        ...prev,
        redirectUrl: redirectUri,
      }));
    }
  }, []);

  useEffect(() => {
    if (userIsAuthorized && !logedByflow) {
      handleOtp();
    }
  }, [userIsAuthorized]);

  return (
    <Box className={classes.container}>
      <Group position="center" mt={useMobileView ? 20 : 50}>
        <Box className={classes.formContainer} p="xl" w="30.4rem">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: state.loading ? 'center' : 'flex-start',
              alignItems: state.loading ? 'center' : 'flex-start',
            }}
          >
            <FlexIconNoPadding width="7rem" />
          </Box>
          <Authenticated loading={state.loading} />
          {!state.loading && currentStep?.element}
          {state.error && (
            <Alert
              icon={<RedAlertIcon />}
              className={classes.alert}
              my="lg"
              withCloseButton={false}
            >
              {state.error}
            </Alert>
          )}
        </Box>
      </Group>
    </Box>
  );
};
