import { FlexWizard } from '@common/wizard/components/flex-wizard';
import { Button } from '@mantine/core';
import { memo, useState } from 'react';
import InvoiceWizardDialog from './invoice-wizard-dialog';
import { useNavigate } from 'react-router-dom';
import { InvoiceWizardState, useInvoiceWizard } from './invoice-wizard';
import { useUpdateBillpayInvoice } from '@queries/use-bill-pay';
import AdditionalInformationStep from './steps/additional-information-step';

function InvoiceWizardHeader() {
  const { state, setState, triggerEvent, currentStep, isFinalStep } =
    useInvoiceWizard();
  const { existingInvoiceId, error, isInvoiceDraft, isBookkeeper } = state;
  const { mutate: mutateBillpayInvoice, isPending } = useUpdateBillpayInvoice();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleDialogOpen = () => {
    if (isFinalStep) {
      navigate('/bill-pay/bills');
    } else {
      setIsDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    navigate('/bill-pay');
  };

  /**
   * borrowed from the wizard footer:
   * Steps can return updated state fields from the `onSaveEdits` method.
   * Return false from handler to indicate that the save should not proceed.
   */
  const handleSaveEdits = async () => {
    const updatedStateFields = await triggerEvent('onSaveEdits');
    if (updatedStateFields === false) {
      setIsDialogOpen(false);
      setState({
        error: {
          message:
            'An unexpected error occurred while saving the invoice. Please try again.',
          errorType: 'update',
        },
      });
      return;
    }

    const newStateValues = {
      ...state,
      ...((updatedStateFields || {}) as Partial<InvoiceWizardState>),
    };
    mutateBillpayInvoice(
      {
        id: existingInvoiceId!,
        recipientId: newStateValues.recipient?.id,
        invoiceNumber: newStateValues.invoiceNumber,
        description: newStateValues.memo,
        dueDate: newStateValues?.invoiceDetails?.dueDate,
        documentId: newStateValues.existingDocumentId,
        total: newStateValues.invoiceTotal?.cents,
      },
      {
        onSuccess: (_data) => {
          navigate('/bill-pay/bills');
        },
        onError: (err) => {
          setIsDialogOpen(false);
          setState({
            error: {
              message:
                err?.message ||
                'An unexpected error occurred while saving the invoice. Please try again.',
              errorType: 'update',
            },
          });
        },
      },
    );
  };

  return (
    <>
      <FlexWizard.Header
        actions={
          !error &&
          !isFinalStep && (
            <Button variant="subtle" onClick={handleDialogOpen}>
              Cancel
            </Button>
          )
        }
      />
      {isDialogOpen && (
        <InvoiceWizardDialog
          onClose={() => setIsDialogOpen(false)}
          title="You have unsaved changes"
          message={`You have unsaved changes on this bill. ${
            existingInvoiceId ? 'Save now' : 'Finish this step'
          } to keep your updates, or discard to leave without saving.`}
          options={[
            {
              label: 'Continue Editing',
              onClick: () => setIsDialogOpen(false),
              variant: 'subtle',
              disabled: isPending,
            },
            {
              label: 'Discard and Exit',
              onClick: handleDialogClose,
              variant: 'outline',
              disabled: isPending,
            },
            ...(existingInvoiceId
              ? [
                  {
                    label: 'Save and Exit',
                    onClick: handleSaveEdits,
                    variant: 'light',
                    disabled:
                      isPending ||
                      !isInvoiceDraft ||
                      (isBookkeeper &&
                        currentStep?.id !== AdditionalInformationStep.stepId),
                    loading: isPending,
                  },
                ]
              : []),
          ]}
        />
      )}
    </>
  );
}

export default memo(InvoiceWizardHeader);
