import { Badge, CloseButton, Divider, Flex, Group, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { DownloadIcon } from 'assets/svg';
import { DateTime } from 'luxon';
import AttachtmentsSection from './attachments-section';
import { useStyles } from './styles';
import ManagePayment, { getIsCancelable } from './manage-payment';
import { IsAdmin } from 'states/application/product-onboarding';
import { useRecoilValue } from 'recoil';
import { isFuturePayment } from 'utilities/dates/dates';
import PaymentTimeline from './payment-timeline';
import { AvailableCurrencies } from '../send-payment/international-payments/util/types';
import {
  IntlPaymentStatus,
  MoneyMovementStatus,
} from '@services/flexbase/banking.model';

export type Details = {
  createdAt: string;
  amount: any;
  type: string;
  status: string;
  exactStatus: MoneyMovementStatus;
  direction: string;
  description: string;
  recipient: string;
  id: string;
  notes: string;
  userId: string;
  approvedBy?: string;
  requestedBy?: string;
  accountName: string;
  scheduledFor?: string;
  internationalPaymentId?: string;
  currency?: AvailableCurrencies;
  fxAmount?: string | null;
  rate?: string;
  intlPaymentStatus?: IntlPaymentStatus;
  estimatedAmount?: string | number;
};

type Props = {
  row: Details;
  onClose: () => void;
};

const confirmationCopy = `By clicking 'Approve', I authorize Flex to initiate the transaction detailed above. Flex is powered by Flexbase Technologies, Inc`;

const PaymentDetails = ({ row, onClose }: Props) => {
  const { classes, cx } = useStyles();
  const useMobileView = useMediaQuery('(max-width: 755px)');
  const isAdmin = useRecoilValue(IsAdmin);
  const createdAt = DateTime.fromSQL(row.createdAt);
  const formattedDate = `${createdAt.toFormat('DDD')} at ${createdAt.toFormat(
    'ttt',
  )}`;
  const deliveryFormattedDate = row.scheduledFor
    ? DateTime.fromFormat(row.scheduledFor, 'yyyy-MM-dd').toFormat('DD')
    : null;
  const futurePayment = isFuturePayment(row.scheduledFor ?? '');

  const needsAction = getIsCancelable(row.exactStatus, row.type);
  const showActionSection = isAdmin && needsAction;
  const showConfirmationDisclaimer =
    row.exactStatus === 'AwaitingApproval' ||
    row.exactStatus === 'AwaitingConfirmation';
  const showDownloadReceiptButton = row.direction !== 'Debit' && !futurePayment;
  const isIntlPayment = row.type === 'Outbound ACH for intl wire';

  return (
    <div className={classes.paymentDetailsModal}>
      <div className={cx(classes.header, classes.rowContent)}>
        <div>
          <div style={{ display: 'flex', gap: 14, alignItems: 'center' }}>
            <Text size={useMobileView ? 20 : 36}>{row.amount}</Text>
            <Badge>{row.status}</Badge>
          </div>
          <Text size={useMobileView ? 10 : 14}>{formattedDate}</Text>
        </div>
        <div>
          <CloseButton
            color="neutral.0"
            variant="transparent"
            onClick={onClose}
            size={useMobileView ? 20 : 30}
          />
        </div>
      </div>
      {isIntlPayment && <PaymentTimeline {...row} />}
      <Group
        spacing="xl"
        grow
        mx={useMobileView ? 20 : 50}
        align="initial"
        mt={24}
      >
        <div>
          <Text className={classes.smallText}>Recipient</Text>
          <Text mt={5} className={classes.bigText}>
            {row.recipient}
          </Text>
        </div>

        {row.requestedBy && (
          <div>
            <Text className={classes.smallText}>Sender</Text>
            <Text mt={5} className={classes.bigText}>
              {row.requestedBy}
            </Text>
          </div>
        )}
      </Group>

      <Divider my={24} />
      <Group grow spacing="xl" mx={useMobileView ? 20 : 50} align="flex-start">
        <div>
          <Text className={classes.smallText}>Type</Text>
          <Text mt={5} className={classes.bigText}>
            {row.type}
          </Text>
        </div>
        <div>
          <Text className={classes.smallText}>From account</Text>
          <Text mt={5} className={classes.bigText}>
            {row.accountName}
          </Text>
        </div>
      </Group>

      <Divider my={24} />
      <Group grow spacing="xl" mx={useMobileView ? 20 : 50}>
        <div>
          <Text className={classes.smallText}>External Notes</Text>
          <Text className={classes.bigText}>{row.description}</Text>
        </div>
        {row.scheduledFor && (
          <div>
            <Text className={classes.smallText}>Scheduled to be sent on</Text>
            <Text className={classes.bigText}>{deliveryFormattedDate}</Text>
          </div>
        )}
      </Group>
      {row.notes !== '' && (
        <>
          <Divider my={24} />
          <div className={classes.detailsContainer}>
            <Text className={classes.smallText}>Internal Notes</Text>
            <Text className={classes.bigText}>{row.notes}</Text>
          </div>
        </>
      )}

      <AttachtmentsSection {...{ row }} />

      {showDownloadReceiptButton && (
        <>
          <Divider my={24} />
          <div className={classes.detailsContainer}>
            <Text className={classes.smallText}>Payment receipt</Text>
            <div
              className={classes.receiptSection}
              onClick={() => window.open(`/payment/receipt/${row?.id}`)}
            >
              <DownloadIcon width={15} />
              <Text>Download</Text>
            </div>
          </div>
        </>
      )}

      {showActionSection && (
        <>
          <Divider my={24} />

          {showConfirmationDisclaimer && (
            <>
              <div className={classes.detailsContainer}>
                <Text className={classes.smallText}>Action required</Text>
              </div>
              <Text className={classes.paymentConfirmationDisclaimer}>
                {confirmationCopy}
              </Text>
            </>
          )}

          <Flex justify="end" mt="sm">
            <ManagePayment
              paymentId={row.id}
              createdBy={row.userId}
              approvedBy={row.approvedBy}
              status={row.exactStatus}
              type={row.type}
              onSuccess={onClose}
            />
          </Flex>
        </>
      )}
    </div>
  );
};

export default PaymentDetails;
