/**
 * Copied from Mantine docs. It actually works!
 */
import { useState } from 'react';
import {
  Box,
  Popover,
  PopoverProps,
  Progress,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import { passwordStrengthChecks } from '@utilities/validators/validate-password';

type Props = {
  children: JSX.Element;
  inputValue: string;
} & PopoverProps;

const PasswordRequirement = ({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) => {
  const theme = useMantineTheme();
  return (
    <Text
      color={meets ? theme.colors.positive[1] : theme.colors.critical[2]}
      sx={{ display: 'flex', alignItems: 'center' }}
      mt={7}
      size="sm"
    >
      {meets ? <IoMdCheckmark /> : <IoMdClose />} <Box ml={10}>{label}</Box>
    </Text>
  );
};

function getStrength(password: string) {
  let multiplier = 0;

  passwordStrengthChecks.forEach((requirement) => {
    if (!requirement.testFn(password)) {
      multiplier += 1;
    }
  });

  return Math.max(
    100 - (100 / (passwordStrengthChecks.length + 1)) * multiplier,
    10,
  );
}

const PasswordStrengthPopover = ({
  children,
  inputValue,
  ...popoverProps
}: Props) => {
  const theme = useMantineTheme();
  const [popoverOpened, setPopoverOpened] = useState(false);
  const checks = passwordStrengthChecks.map((check, index) => (
    <PasswordRequirement
      key={index}
      label={check.label}
      meets={check.testFn(inputValue)}
    />
  ));

  const strength = getStrength(inputValue);
  const color =
    strength === 100
      ? theme.fn.themeColor('primarySecondarySuccess', 8)
      : strength > 50
      ? theme.fn.themeColor('tertiary', 2)
      : theme.fn.themeColor('critical', 2);

  return (
    <Popover
      opened={popoverOpened}
      position="bottom-start"
      withArrow
      width="target"
      transitionProps={{ transition: 'pop-top-left' }}
      {...popoverProps}
    >
      <Popover.Target>
        <div
          onFocusCapture={() => setPopoverOpened(true)}
          onBlurCapture={() => setPopoverOpened(false)}
        >
          {children}
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Progress
          color={color}
          value={strength}
          size={5}
          style={{ marginBottom: 10 }}
        />
        {checks}
      </Popover.Dropdown>
    </Popover>
  );
};

export default PasswordStrengthPopover;
