import { flexbaseOnboardingClient } from '@services/flexbase-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  BillpayInvoiceFilters,
  CreateBillpayInvoiceLineItemRequest,
  CreateBillpayInvoiceRequest,
  UpdateBillpayInvoiceLineItemRequest,
  UpdateBillpayInvoiceRequest,
} from 'states/bill-pay/bill-pay';

const QUERY_KEY = 'billPay';
const QUERY_BALANCES = 'balances';
const QUERY_INVOICES = 'invoices';
const QUERY_INVOICE = 'invoice';
const QUERY_LINEITEMS = 'lineItems';

export const useGetBillpayBalance = () => {
  return useQuery({
    queryKey: [QUERY_KEY, QUERY_BALANCES],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getBillpayBalances();
    },
    meta: {
      errorMessage: 'Unable to get bill pay balance data',
    },
  });
};

export const useGetBillpayInvoices = (
  filters?: Partial<BillpayInvoiceFilters>,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, QUERY_INVOICES, filters],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getBillpayInvoices(filters);
    },
    meta: {
      errorMessage: 'Unable to get bill pay invoices data',
    },
  });
};

export const useGetBillpayInvoice = (invoiceId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, QUERY_INVOICE, invoiceId],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getBillpayInvoice(invoiceId);
    },
    enabled: !!invoiceId,
    meta: {
      errorMessage: `Unable to get bill pay invoice data for invoice ID: ${invoiceId}`,
    },
  });
};

export const useGetBillpayInvoiceLineItems = (invoiceId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, QUERY_INVOICE, invoiceId, QUERY_LINEITEMS],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getBillpayInvoiceLineItems(
        invoiceId,
      );
    },
    enabled: !!invoiceId,
    meta: {
      errorMessage: `Unable to get bill pay invoice line item data for invoice ID: ${invoiceId}`,
    },
  });
};

export const useCreateBillpayInvoiceLineItem = () => {
  return useMutation({
    mutationFn: async ({
      lineItem,
    }: {
      lineItem: CreateBillpayInvoiceLineItemRequest;
    }) => {
      return await flexbaseOnboardingClient.createBillpayInvoiceLineItem(
        lineItem,
      );
    },
  });
};

export const useUpdateBillpayInvoiceLineItem = () => {
  return useMutation({
    mutationFn: async ({
      lineItem,
    }: {
      lineItem: UpdateBillpayInvoiceLineItemRequest;
    }) => {
      return await flexbaseOnboardingClient.updateBillpayInvoiceLineItem(
        lineItem,
      );
    },
  });
};

export const useCreateBillpayInvoice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      invoice,
    }: {
      invoice: CreateBillpayInvoiceRequest;
    }) => {
      return await flexbaseOnboardingClient.createBillpayInvoice(invoice);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });
};

export const useUpdateBillpayInvoice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateBillpayInvoiceRequest) => {
      return await flexbaseOnboardingClient.updateBillpayInvoice(body);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });
};

export const useDeleteBillpayInvoice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (invoiceId: string) => {
      return await flexbaseOnboardingClient.deleteBillpayInvoice(invoiceId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });
};
