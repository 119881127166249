import { RadioSelectList } from '@common/radio/radio-select-list';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'states/application/product-onboarding';
import {
  PAYMENT_STEP_VALUES,
  PaymentMethodStepValue,
} from '../../add-recipient';
import {
  AddRecipientWizard,
  useAddRecipientWizard,
} from '../../add-recipient-wizard';
import { useState } from 'react';
import {
  isInternationalPayMethod,
  isInternationalWireUSD,
} from 'areas/payments/components/send-payment/international-payments/util';
import { ACH_COPY } from '@utilities/constants';

const useRadioOptions = () => {
  type RadioOption = {
    value: PaymentMethodStepValue;
    title: string;
    description: string;
  };

  const { domesticACH, domesticWire, internationalWireUSD, internationalWire } =
    PAYMENT_STEP_VALUES;

  const initialRadioOptions: RadioOption[] = [
    {
      value: domesticACH,
      title: 'Domestic ACH',
      description: ACH_COPY,
    },
    {
      value: domesticWire,
      title: 'Domestic Wire',
      description: '1 business day',
    },
  ];

  const intlPaymentRadioOptions: RadioOption[] = [
    {
      value: internationalWireUSD,
      title: 'International Payment - USD',
      description: '3-5 business days',
    },
    {
      value: internationalWire,
      title: 'International Payment - Foreign Exchange',
      description: '3-5 business days',
    },
  ];

  const { productStatus } = useRecoilValue(ApplicationState);

  const hasActiveIntnlPayments =
    productStatus.internationalPayments.status === 'approved';

  const radioOptions = !hasActiveIntnlPayments
    ? initialRadioOptions
    : initialRadioOptions.concat(intlPaymentRadioOptions);

  return radioOptions;
};

export const PaymentTypeStep = () => {
  const { goToNextStep, state, setState } = useAddRecipientWizard();
  const radioOptions = useRadioOptions();
  const [selectedRadioOption, setSelectedRadioOption] = useState(
    state.paymentMethod,
  );

  const handleNext = () => {
    goToNextStep();
  };

  const handleChange = (val: string) => {
    const paymentMethod = val as PaymentMethodStepValue;
    setSelectedRadioOption(paymentMethod);
    setState((prev) => ({
      ...prev,
      paymentMethod,
      showIntlPaymentStep: paymentMethod.includes(
        PAYMENT_STEP_VALUES.internationalWire,
      ),
      beneficiaryRequirementsFormValues:
        isInternationalPayMethod(paymentMethod) &&
        prev.beneficiaryRequirementsFormValues.type !== paymentMethod
          ? {
              ...prev.beneficiaryRequirementsFormValues,
              type: paymentMethod,
              currency: isInternationalWireUSD(paymentMethod)
                ? 'USD'
                : prev.beneficiaryRequirementsFormValues.currency,
            }
          : prev.beneficiaryRequirementsFormValues,
    }));
  };

  return (
    <AddRecipientWizard.Step onNext={handleNext}>
      <RadioSelectList
        selected={selectedRadioOption}
        onRadioCardClick={handleChange}
        radioOptions={radioOptions}
        radioCardProps={{ w: '100%' }}
      />
    </AddRecipientWizard.Step>
  );
};

PaymentTypeStep.stepId = 'payment-type-step';
