import {
  Box,
  Button,
  Text,
  TextInput,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { CloseIcon, PlusSignIcon, SearchIcon } from '../../../../assets/svg';
import { useEffect, useRef } from 'react';
import {
  useCreditCardsFilters,
  useCreditCardsModalFilters,
} from './use-credit-cards-filters';
import IssueCard from '../../issue-card';
import useModal from '@common/modal/modal-hook';
import { CardStatusFilter } from './filters/card-status.filter';
import { CreditCardsCardholderFilter } from './filters/cardholder.filter';
import { CreditCardsDateRangeFilter } from './filters/credit-cards-date-range.filter';
import { CardTypeFilter } from './filters/card-type.filter';
import { CardNumberFilter } from './filters/card-number.filter';
import { CardNameFilter } from './filters/card-name.filter';
import { CardSpentAmountsFilter } from './filters/card-spent-amounts.filter';
import { useRecoilValue } from 'recoil';
import { IsAdmin } from '../../../../states/application/product-onboarding';
import { FilterModal } from '@common/filter/filter-modal';
import { IoFunnelOutline } from 'react-icons/io5';
import { isTruthyString } from '@utilities/validators/validate-string';
import { cardIsChargeCard, CreditCard } from '@services/flexbase/card.model';
import IssueChargeCard from 'areas/charge-account/charge-cards/issue-charge-cards';

type Props = {
  onCardCreated: () => void;
  cardsData: CreditCard[];
  isChargeCardProgramPath: boolean;
};

export function CreditCardsTableHeader({
  onCardCreated,
  cardsData,
  isChargeCardProgramPath,
}: Props) {
  const isAdmin = useRecoilValue(IsAdmin);
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { openFullModal, closeAllModals } = useModal();
  const modal = useModal();

  const chargeCards = cardsData.filter((card) => cardIsChargeCard(card));
  const creditCards = cardsData.filter((card) => !cardIsChargeCard(card));
  const filteredCardsData = isChargeCardProgramPath ? chargeCards : creditCards;

  const {
    applyAllFilters: applyAllModalFilters,
    activeFilters: activeModalFilters,
  } = useCreditCardsModalFilters();

  const {
    applyAllFilters: applyAllTableFilters,
    removeAllFilters,
    activeFilters: activeTableFilters,
    activeFiltersArray: activeTableFiltersArray,
    getFilterByKey,
    addFilter,
    removeFilter,
  } = useCreditCardsFilters();

  const activeModalFiltersRef = useRef(activeModalFilters);

  useEffect(() => {
    activeModalFiltersRef.current = activeModalFilters;
  }, [activeModalFilters]);

  const onSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(transaction)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const onIssueCardClick = () => {
    openFullModal(
      isChargeCardProgramPath ? (
        <IssueChargeCard
          addCompanyCard={onCardCreated}
          closeModal={closeAllModals}
        />
      ) : (
        <IssueCard closeModal={closeAllModals} addCompanyCard={onCardCreated} />
      ),
    );
  };

  const filters = [
    {
      key: 'status',
      header: 'Status',
      component: <CardStatusFilter />,
    },
    {
      key: 'type',
      header: 'Type',
      component: <CardTypeFilter />,
    },
    {
      key: 'cardholder',
      header: 'Cardholder',
      component: <CreditCardsCardholderFilter cards={filteredCardsData} />,
    },
    {
      key: 'number',
      header: 'Number',
      component: <CardNumberFilter cards={filteredCardsData} />,
    },
    {
      key: 'name',
      header: 'Card name',
      component: <CardNameFilter cards={filteredCardsData} />,
    },
    {
      key: 'spent',
      header: 'Monthly usage',
      component: <CardSpentAmountsFilter />,
    },
    {
      key: 'date',
      header: 'Last modified',
      component: <CreditCardsDateRangeFilter />,
    },
  ];

  const handleModalSave = () => {
    applyAllTableFilters(activeModalFiltersRef.current);
    modal.closeAllModals();
  };

  const handleModalCancel = () => {
    modal.closeAllModals();
  };

  const filterChips = activeTableFiltersArray.filter((f) => f.showChip);

  const openModal = () => {
    applyAllModalFilters(activeTableFilters);

    screen.width <= 767
      ? modal.openFullModal(
          <FilterModal
            filters={filters}
            onSave={handleModalSave}
            onCancel={handleModalCancel}
          />,
        )
      : modal.openRightModal(
          <FilterModal
            filters={filters}
            onSave={handleModalSave}
            onCancel={handleModalCancel}
          />,
        );
  };

  const buttonLabel = isChargeCardProgramPath
    ? 'New charge card'
    : 'New credit card';

  return (
    <>
      <Box
        sx={() => ({
          display: 'flex',
          gap: theme.spacing.md,
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        })}
        mb="md"
      >
        <Box
          sx={() => ({
            display: 'flex',
            gap: theme.spacing.md,
            [theme.fn.smallerThan('sm')]: {
              flexDirection: 'column',
              width: '100%',
            },
          })}
        >
          <TextInput
            width={368}
            placeholder="Search cards"
            icon={<SearchIcon width={20} height={20} />}
            value={getFilterByKey('search')?.filterValue || ''}
            onChange={(e) => onSearchChange(e.target.value)}
          />
          <Button
            variant="outline"
            rightIcon={<IoFunnelOutline />}
            onClick={() => openModal()}
          >
            Filters
          </Button>
          {isAdmin && (
            <Button
              variant="outline"
              leftIcon={<PlusSignIcon />}
              onClick={onIssueCardClick}
            >
              {buttonLabel}
            </Button>
          )}
        </Box>
      </Box>
      {filterChips.length > 0 && (
        <Box
          sx={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}
          mih={30}
          mb="md"
        >
          {filterChips.map((p) => (
            <Box
              sx={() => ({
                height: 30,
                borderRadius: theme.radius.xs,
                border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
                backgroundColor: theme.fn.themeColor('neutral', 2),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                padding: `0px ${theme.spacing.md}`,
                gap: 4,
                [theme.fn.smallerThan('md')]: {
                  height: 'auto',
                  padding: `0px ${theme.spacing.xl}`,
                },
              })}
              onClick={() => {
                removeFilter(p.key);
              }}
              key={p.key}
            >
              <Text color="black" fz={14} fw={500}>
                {p.label}
              </Text>
              <CloseIcon color="black" style={{ marginLeft: 'auto' }} />
            </Box>
          ))}
          <Text
            className={classes.clearFilters}
            onClick={() => {
              removeAllFilters();
            }}
          >
            Clear filters
          </Text>
        </Box>
      )}
    </>
  );
}

const useStyles = createStyles((theme) => ({
  clearFilters: {
    cursor: 'pointer',
    color: theme.fn.themeColor('primarySecondarySuccess', 2),
  },
}));
