import { useForm } from '@mantine/form';
import { validateRequired } from '@utilities/validators/validate-required';
import ValidateRoutingNumber from '@utilities/validators/validate-routing-number';
import {
  DomesticAchRecipient,
  IndividualOrCompany,
} from 'areas/payments/components/send-payment/international-payments/util/types';

export const useDomesticAchRecipientForm = (
  initialValues: DomesticAchRecipient,
  recipientType: IndividualOrCompany,
) => {
  const form = useForm<DomesticAchRecipient>({
    initialValues: {
      ...initialValues,
      type: 'ach',
      recipientType,
    },
    validate: {
      routingNumber: (value) =>
        ValidateRoutingNumber(value)
          ? null
          : 'Routing number must be 9 digits and match an existing bank routing number.',
      accountNumber: (value) =>
        !!value && value.length <= 17 ? null : 'Account number is required.',
      accountType: (value) =>
        validateRequired(value) ? null : 'Account type is required.',
    },
  });

  return form;
};
