import {
  Box,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Tooltip,
  createStyles,
} from '@mantine/core';
import {
  useApprovePayment,
  useCancelPayment,
  useConfirmPayment,
  useSendCodeConfirmPayment,
} from '@queries/use-payments';
import { UserIdState } from 'areas/onboarding/onboarding-form.state';
import { ToastCheckIcon } from 'assets/svg';
import TwoFactorAuth from 'components/auth/two-factor-auth';
import { PropsWithChildren, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { MdErrorOutline } from 'react-icons/md';
import { useRecoilValue } from 'recoil';
import { RolesSelector } from 'states/application/product-onboarding';
import { useStyles } from './styles';
import { showNotification } from '@mantine/notifications';

const useManagePaymentStyles = createStyles((theme) => ({
  buttonOutline: {
    border: `1px solid ${theme.colors.neutral[3]} !important`,
  },
  closeIcon: {
    color: theme.colors.neutral[6],
  },
  closeIconButton: {
    border: `1px solid ${theme.colors.neutral[3]} !important`,
  },
  compactTwoFactorAuthContainer: {
    minWidth: '220px',
  },
}));

// Payment statuses that can be canceled for *any* payment type
const cancelableStates = [
  'AwaitingApproval',
  'AwaitingConfirmation',
  'Scheduled',
  'Queued',
];

// Payment statuses that can be canceled for ACH payments
const achCancelableStates = ['Pending', 'PendingReview'];

export const getIsCancelable = (status: string, type: string) => {
  return (
    cancelableStates.includes(status) ||
    (type === 'ACH payment' && achCancelableStates.includes(status))
  );
};

type ConditionalContainerProps = PropsWithChildren & {
  isCompact: boolean;
};

const TwoFactorContainer = ({
  isCompact,
  children,
}: ConditionalContainerProps) => {
  const useTwoFactorContainerStyles = createStyles(() => ({
    compactContainer: {
      display: 'flex',
      gap: '.5rem',
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    container: {
      display: 'flex',
      alignItems: 'baseline',
      gap: '.5rem',
    },
  }));
  const { classes } = useTwoFactorContainerStyles();
  const conditionalWrapper = isCompact
    ? classes.compactContainer
    : classes.container;

  return <div className={conditionalWrapper}>{children}</div>;
};

const PrimaryActionContainer = ({
  isCompact,
  children,
}: ConditionalContainerProps) => {
  const { classes } = useStyles();

  return isCompact ? (
    children
  ) : (
    <Flex justify="end" className={classes.detailsContainer}>
      {children}
    </Flex>
  );
};

const getCancellationCopy = (isOwnPayment: boolean) => {
  return isOwnPayment
    ? {
        buttonText: 'Cancel',
        successText: 'Payment request canceled.',
      }
    : {
        buttonText: 'Deny',
        successText: 'Payment request denied.',
      };
};

// Manage the lifecycle of the Payment through Approval, Confirmation, or Cancellation
type ManagePaymentProps = {
  paymentId: string;
  createdBy: string;
  approvedBy?: string;
  status: string;
  type: string;
  isCompact?: boolean;
  onSuccess: () => void;
};

type IntermediaryStepProps = {
  onUndo: () => void;
  onApprove: () => void;
};

const IntermediaryStep = ({ onUndo, onApprove }: IntermediaryStepProps) => {
  const { classes: managePaymentStyles } = useManagePaymentStyles();
  const commonButtonProps = {
    variant: 'outline',
    className: managePaymentStyles.buttonOutline,
  };

  return (
    <Group spacing="xs">
      <Button onClick={onUndo} {...commonButtonProps}>
        Undo
      </Button>
      <Button onClick={onApprove} {...commonButtonProps}>
        Confirm approval
      </Button>
    </Group>
  );
};

const ManagePayment = ({
  paymentId,
  createdBy,
  approvedBy,
  status,
  type,
  isCompact = false,
  onSuccess,
}: ManagePaymentProps) => {
  const roles = useRecoilValue(RolesSelector);
  const userId = useRecoilValue(UserIdState);

  const sendCodeRequest = useSendCodeConfirmPayment();
  const confirmRequest = useConfirmPayment();
  const approveRequest = useApprovePayment();
  const cancelRequest = useCancelPayment();
  const { classes: managePaymentStyles } = useManagePaymentStyles();

  const [showIntermediaryStep, setShowIntermediaryStep] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  // initiate a 2FA challenge for the approving user
  const send2FACode = () => {
    if (paymentId) {
      sendCodeRequest.mutateAsync(paymentId).then(() => setIsConfirming(true));
    }
  };

  // handle changes to the 2FA input
  const onChange2FA = (code: string) => {
    if (paymentId && code.length === 6) {
      confirmRequest
        .mutateAsync({
          id: paymentId,
          code,
        })
        .then(() => {
          onSuccess();
          setIsConfirming(false);
          showNotification({
            title: 'Success!',
            message: (
              <Box display="flex" style={{ alignItems: 'center' }}>
                <ToastCheckIcon /> Payment request approved!
              </Box>
            ),
            color: 'flexbase-teal',
          });
        });
    }
  };

  // approve this payment and immediately queue it up for confirmation
  const approvePayment = () => {
    if (paymentId) {
      approveRequest.mutateAsync(paymentId).then((res) => {
        if (res.status === 'AwaitingConfirmation') {
          send2FACode();
        } else {
          onSuccess();
        }
      });
    }
  };

  const handleConditionalApproval = (requiresIntermediaryStep: boolean) => {
    if (requiresIntermediaryStep) {
      setShowIntermediaryStep((prev) => !prev);
    } else {
      approvePayment();
    }
  };

  const handleUndo = () => {
    setShowIntermediaryStep(false);
  };

  // reject this payment via cancelation
  const cancelPayment = () => {
    if (paymentId) {
      cancelRequest.mutateAsync(paymentId).then(() => {
        onSuccess();
        showNotification({
          title: 'Success!',
          message: (
            <Box display="flex" style={{ alignItems: 'center' }}>
              <ToastCheckIcon /> {successText}
            </Box>
          ),
          color: 'flexbase-teal',
        });
      });
    }
  };

  // derive important request states from the union of all requests
  const isLoading =
    sendCodeRequest.isPending ||
    confirmRequest.isPending ||
    approveRequest.isPending ||
    cancelRequest.isPending;

  const errorMessage =
    sendCodeRequest.error?.message ||
    approveRequest.error?.message ||
    cancelRequest.error?.message;

  // derive permissions from Payment progenitors and caller role
  const isOwnPayment = userId === createdBy;
  const isApprovedByCaller = userId === approvedBy;
  const canRequest = roles.includes('ADMIN');
  const canApprove = roles.includes('COMPTROLLER');
  const canConfirm = canApprove && (isApprovedByCaller || isOwnPayment);
  const canCancel = (canRequest && isOwnPayment) || canApprove;
  const isCancelable =
    cancelableStates.includes(status) ||
    (type === 'ACH payment' && achCancelableStates.includes(status));

  // derive shorthands for what needs to be done
  const needsApproval = status === 'AwaitingApproval';
  const needsConfirmation = status === 'AwaitingConfirmation';
  const needsIntermediaryStep = type === 'Wire' && needsApproval;
  const { buttonText, successText } = getCancellationCopy(isOwnPayment);

  // derive a set of components to render from Payment needs and User abilities
  let primaryAction;

  if (isConfirming) {
    primaryAction = (
      <TwoFactorContainer isCompact={isCompact}>
        <TwoFactorAuth
          hasRetry={false}
          errorMsg={confirmRequest.error?.message || ''}
          onChange={onChange2FA}
          onResendCode={send2FACode}
          loading={isLoading}
          containerStyles={
            isCompact ? managePaymentStyles.compactTwoFactorAuthContainer : ''
          }
        />
        <Button
          onClick={() => setIsConfirming(false)}
          variant="outline"
          mt={isCompact ? 0 : 20}
          className={managePaymentStyles.closeIconButton}
          compact={isCompact}
        >
          {isCompact ? (
            <AiOutlineClose
              className={managePaymentStyles.closeIcon}
              size={20}
            />
          ) : (
            'Cancel 2FA'
          )}
        </Button>
      </TwoFactorContainer>
    );
  } else {
    const buttons = [];
    const commonButtonProps = {
      variant: 'outline',
      className: managePaymentStyles.buttonOutline,
    };

    if (needsApproval && canApprove) {
      buttons.push(
        <Button
          onClick={() => handleConditionalApproval(needsIntermediaryStep)}
          key="approve"
          {...commonButtonProps}
        >
          Approve
        </Button>,
      );
    }

    if (needsConfirmation && canConfirm) {
      buttons.push(
        <Button onClick={send2FACode} key="confirm" {...commonButtonProps}>
          Confirm
        </Button>,
      );
    }

    if (isCancelable && canCancel) {
      buttons.push(
        <Button onClick={cancelPayment} key="cancel" {...commonButtonProps}>
          {buttonText}
        </Button>,
      );
    }

    if (buttons.length > 0) {
      primaryAction = (
        <>
          <LoadingOverlay visible={isLoading} />
          {needsIntermediaryStep && showIntermediaryStep ? (
            <IntermediaryStep onUndo={handleUndo} onApprove={approvePayment} />
          ) : (
            <Group spacing="xs">{buttons}</Group>
          )}
          {errorMessage && (
            <Tooltip label={errorMessage} withArrow multiline>
              <Flex ml={16} mt={isCompact ? 'initial' : 8}>
                <MdErrorOutline size={20} color="red" />
              </Flex>
            </Tooltip>
          )}
        </>
      );
    }
  }

  return primaryAction ? (
    <PrimaryActionContainer isCompact={isCompact}>
      {primaryAction}
    </PrimaryActionContainer>
  ) : null;
};

export default ManagePayment;
