import { Box, Button, Group, Stack, Text, createStyles } from '@mantine/core';
import { Fragment, ReactNode } from 'react';
import { VscChromeClose } from 'react-icons/vsc';

export type FilterConfig = {
  header: string;
  icon?: ReactNode;
  key: string;
  component: ReactNode;
};

type Props = {
  filters: FilterConfig[];
  onSave: () => void;
  onCancel: () => void;
};

export const FilterModal = ({ filters, onSave, onCancel }: Props) => {
  const { classes, theme } = useStyles();

  const filterMap = filters.reduce<Record<string, ReactNode>>((p, current) => {
    p[current.key] = current.component;
    return p;
  }, {});

  const handleApply = () => onSave();
  const handleCancel = () => onCancel();
  const handleClose = () => onCancel();

  return (
    <>
      <Group className={classes.headerRow1}>
        <Stack spacing="xs">
          <Text className={classes.title}>Filters</Text>
        </Stack>
        <button className={classes.closeButton} onClick={handleClose}>
          <VscChromeClose size={34} color="#FFFF" />
        </button>
      </Group>
      <Box
        sx={{
          width: '100%',
          backgroundColor: theme.fn.themeColor('neutral', 2),
        }}
        p={35}
      >
        {filters.map((f) => (
          <Fragment key={f.key}>
            <Text fw={500}>{f.header}</Text>
            <Box mt={'0.5rem'} mb={'1rem'} w="100%">
              {filterMap[f.key]}
            </Box>
          </Fragment>
        ))}
        <Box mt="3rem">
          <Group className={classes.floatingButtonGroup}>
            <Button
              classNames={{ root: classes.btnCancel }}
              variant="outline"
              onClick={handleCancel}
              bg="neutral.0"
            >
              Cancel
            </Button>
            <Button onClick={handleApply}>Apply filters</Button>
          </Group>
        </Box>
      </Box>
    </>
  );
};

export const useStyles = createStyles((theme) => ({
  headerRow1: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 35,
    backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 8),
    '> div:last-of-type': {
      marginRight: '-11px',
    },
    flexWrap: 'nowrap',
  },
  closeButton: {
    display: 'flex',
    width: '34px',
    boxSizing: 'content-box',
    flexDirection: 'column',
    color: theme.fn.themeColor('neutral', 5),
    cursor: 'pointer',
    borderRadius: '8px',
    alignItems: 'center',
    backgroundColor: 'unset',
    border: '1px solid transparent',
    '&:focus': {
      outline: 'none',
    },
  },
  title: {
    height: '43px',
    color: theme.fn.themeColor('neutral', 0),
    fontFamily: theme.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '2.25rem',
  },
  floatingButtonGroup: {
    position: 'fixed',
    bottom: '1rem',
    right: '20px',
    justifyContent: 'right',
  },
  btnCancel: {
    ':hover': {
      background: 'white !important',
    },
  },
}));
