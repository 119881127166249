import { useRecoilValue } from 'recoil';
import { useMediaQuery } from '@mantine/hooks';
import { Box, Grid, LoadingOverlay } from '@mantine/core';
import ChargeLimit from './charge-limit';
import ChargePaymentCard from './charge-payment';
import { ApplicationState } from 'states/application/product-onboarding';
import { useGetChargeCardAccounts } from '@queries/use-charge-card-accounts';
import CreditTransactions from 'areas/credit/components/credit-transactions/credit-transactions';
import CreditTransactionsSm from 'areas/credit/components/credit-transactions/credit-transactions-sm/credit-transactions-sm';
import {
  useChargeCardTransactionFilters,
  useChargeCardTransactionModalFilters,
} from 'areas/credit/components/credit-transactions/credit-transactions-header/use-credit-transaction-filters';

const ChargeCardsOverview = () => {
  const { user } = useRecoilValue(ApplicationState);
  const isMobile = useMediaQuery('(max-width: 767px)');

  const {
    data: chargeAccountsData,
    isError,
    isLoading,
    refetch: refetchChargeAccounts,
  } = useGetChargeCardAccounts();
  const activeChargeAccounts =
    chargeAccountsData?.accounts.filter((acc) => acc.status !== 'Closed') ?? [];

  const allowedRoles = new Set(['ADMIN', 'ACCOUNTANT', 'OWNER']);
  const userHasAllowedRole = user.roles.some((role) => allowedRoles.has(role));

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <Box>
      {userHasAllowedRole && (
        <Grid mb="xl">
          <Grid.Col md={6}>
            <ChargeLimit chargeBalance={activeChargeAccounts} />
          </Grid.Col>
          <Grid.Col md={6}>
            <ChargePaymentCard
              isError={isError}
              // for v1 the company will have one charge account
              chargeBalance={activeChargeAccounts[0]}
              refetchChargeAccounts={refetchChargeAccounts}
            />
          </Grid.Col>
        </Grid>
      )}
      {isMobile ? (
        <CreditTransactionsSm lineOfCredit="unit" />
      ) : (
        <CreditTransactions
          lineOfCredit="unit"
          useFilters={useChargeCardTransactionFilters}
          useModalFilters={useChargeCardTransactionModalFilters}
        />
      )}
    </Box>
  );
};

export default ChargeCardsOverview;
