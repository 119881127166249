import { Box, Flex, Text, Progress } from '@mantine/core';
import { useStyles } from './credit-limit-widget.styles';
import { formatCurrency } from '@utilities/formatters/format-currency';

type CreditBalance = {
  hold: number;
  total: number;
  spent: number;
  available: number;
  utilization: number;
};

type WidgetProps = {
  creditBalance: CreditBalance;
  frozenSection?: JSX.Element;
  hasDelinquentAmount?: boolean;
};

const CreditLimitWidget = ({
  creditBalance,
  frozenSection,
  hasDelinquentAmount,
}: WidgetProps) => {
  const { classes, theme } = useStyles({ hasDelinquentAmount });

  return (
    <Box className={classes.creditContainer}>
      {frozenSection}
      <Box className={classes.totalBalanceContainer}>
        <Box>
          <Text c="blackish.0" fw={500}>
            Available credit
          </Text>
          <Text size={26} fw={500} mt="xs">
            {formatCurrency(creditBalance.available)}
          </Text>
        </Box>
        <Box>
          <Progress
            size="md"
            value={creditBalance.utilization}
            color={theme.fn.primaryColor()}
          />
          <Flex align="end" justify="space-between" mt="md">
            <Box>
              <Text fs="sm" c="neutral.7">
                {formatCurrency(creditBalance.spent.toString())} spent
              </Text>
              <Text size="xs" c="neutral.5">
                {formatCurrency(creditBalance.hold)} on hold
              </Text>
            </Box>
            <Text fs="sm" c="neutral.7">
              of {formatCurrency(creditBalance.total.toString())} limit
            </Text>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default CreditLimitWidget;
