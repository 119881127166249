import { formatCurrency } from '@flexbase-eng/web-components';
import {
  Button,
  Divider,
  Flex,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import FlexNumberInput from 'areas/payments/components/common/flex-number-input';
import { useEffect, useMemo, useRef, useState } from 'react';
import { IoMdAdd } from 'react-icons/io';
import { InvoiceDetails, useInvoiceWizard } from '../../invoice-wizard';
import { IoCalendarClearOutline, IoTrashOutline } from 'react-icons/io5';
import { useForm } from '@mantine/form';
import {
  useCreateBillpayInvoiceAndLineItems,
  useUpdateBillpayInvoiceLineItems,
} from 'areas/billpay/use-bill-pay';
import { useParams } from 'react-router-dom';
import { DateInput } from '@mantine/dates';
import WizardErrorBanner from '@common/error/wizard-error-banner';
import { useUpdateBillpayInvoice } from '@queries/use-bill-pay';
import LineItemsTotal from './line-items-total';

const AddLineItems = () => {
  const theme = useMantineTheme();
  const [showError, setShowError] = useState(false);
  const { state, setState, onEvent, onNext, goToNextStep, onBack } =
    useInvoiceWizard();
  const { isInvoiceDraft, isActionDisabled } = state;
  const { id: existingInvoiceId } = useParams();

  const {
    mutate: createBillpayInvoiceAndLineItems,
    error: createBillpayInvoiceAndLineItemsError,
  } = useCreateBillpayInvoiceAndLineItems();

  const { mutate: updateBillpayInvoice } = useUpdateBillpayInvoice();

  const {
    mutateAsync: updateBillpayLineItems,
    error: updateBillpayInvoiceAndLineItemsError,
  } = useUpdateBillpayInvoiceLineItems();
  const topOfPageRef = useRef<HTMLDivElement>(null);

  const form = useForm<InvoiceDetails>({
    validateInputOnBlur: true,
    initialValues: state.invoiceDetails,
    validate: {
      lineItems: {
        description: (value) => (value ? null : 'Description is required'),
        total: (value) => (value ? null : 'Amount is required'),
        quantity: (value) => (value ? null : 'Quantity is required'),
      },
      dueDate: (value) => (value ? null : 'Due date is required'),
    },
  });

  const activeLineItems = useMemo(() => {
    return form.values.lineItems?.filter((item) => item.status !== 'inactive');
  }, [form.values.lineItems]);

  const invoiceTotal = useMemo(() => {
    // sum up line items
    const lineItemsTotal = activeLineItems?.reduce(
      (acc, item) =>
        acc + (Number(item.total) || 0) * (Number(item.quantity) || 0),
      0,
    );
    // add credits and tax
    const total =
      (lineItemsTotal ?? 0) +
      (Number(form.values.credits) || 0) * -1 +
      (Number(form.values.tax) || 0);

    if (
      Math.round(total * 100) >= 5000 &&
      state.recipientAccount?.type === 'wire'
    ) {
      setShowError(false);
    }

    return {
      formatted: formatCurrency(total),
      cents: Math.round(total * 100),
    };
  }, [activeLineItems, form.values.tax, form.values.credits]);

  const createLineItem = () => {
    form.insertListItem('lineItems', {
      description: undefined,
      quantity: undefined,
      total: undefined,
      timestamp: Date.now(),
      status: 'active',
    });
  };

  onEvent('onSaveEdits', async () => {
    form.validate();
    if (!form.isDirty()) {
      return form.isValid() && { invoiceDetails: form.values, invoiceTotal };
    }
    if (form.isValid()) {
      await updateBillpayLineItems({
        lineItems: form.values.lineItems,
        invoiceId: existingInvoiceId!,
      });
      setState((prevState) => ({
        ...prevState,
        invoiceDetails: form.values,
        invoiceTotal,
      }));
      return { invoiceDetails: form.values, invoiceTotal };
    }
    return false;
  });

  // save the form values on back
  onBack(() => {
    setState((prevState) => ({
      ...prevState,
      invoiceTotal,
      invoiceDetails: form.values,
    }));
  });

  onNext(async () => {
    form.validate();
    const invoiceTotalError =
      state.recipientAccount?.type === 'wire' &&
      form.values.lineItems.length > 0 &&
      invoiceTotal.cents < 5000;
    if (!form.isDirty()) {
      setState((prevState) => ({
        ...prevState,
        invoiceDetails: form.values,
        invoiceTotal,
      }));
      if (invoiceTotalError) {
        setShowError(true);
        topOfPageRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }
      goToNextStep();
      return;
    }
    if (form.isValid() && form.values.dueDate && state.existingDocumentId) {
      if (invoiceTotalError) {
        setShowError(true);
        topOfPageRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }

      if (existingInvoiceId) {
        try {
          await updateBillpayLineItems({
            lineItems: form.values.lineItems,
            invoiceId: existingInvoiceId!,
          });
          updateBillpayInvoice(
            {
              id: existingInvoiceId!,
              recipientId: state.recipient?.id,
              dueDate: form.values?.dueDate,
              documentId: state.existingDocumentId,
              total: invoiceTotal?.cents,
            },
            {
              onSuccess: () => {
                setState((prevState) => ({
                  ...prevState,
                  invoiceDetails: form.values,
                  invoiceTotal,
                }));
                goToNextStep();
              },
              onError: () => {
                topOfPageRef.current?.scrollIntoView({
                  behavior: 'smooth',
                });
              },
            },
          );
        } catch (err) {
          topOfPageRef.current?.scrollIntoView({
            behavior: 'smooth',
          });
        }
      } else {
        createBillpayInvoiceAndLineItems(
          {
            recipientId: state.recipient?.id,
            dueDate: form.values.dueDate,
            lineItems: form.values.lineItems,
            invoiceTotal: invoiceTotal.cents,
            existingDocumentId: state.existingDocumentId,
          },
          {
            onError: () => {
              topOfPageRef.current?.scrollIntoView({
                behavior: 'smooth',
              });
            },
            onSuccess: (invoiceId) => {
              setState((prevState) => ({
                ...prevState,
                invoiceTotal,
                existingInvoiceId: invoiceId,
                invoiceDetails: {
                  ...form.values,
                },
              }));
              goToNextStep();
            },
          },
        );
      }
    }
  });

  useEffect(() => {
    setState({ isNextEnabled: true });
  }, []);

  const isDisabled = !isInvoiceDraft || isActionDisabled;

  const handleDeleteLineItem = (index: number) => {
    form.setFieldValue(`lineItems.${index}.status`, 'inactive');
  };

  return (
    <Stack ref={topOfPageRef}>
      {(createBillpayInvoiceAndLineItemsError ||
        updateBillpayInvoiceAndLineItemsError) && (
        <WizardErrorBanner
          message={
            (
              createBillpayInvoiceAndLineItemsError ||
              updateBillpayInvoiceAndLineItemsError
            )?.message
          }
        />
      )}
      {form.values.lineItems?.map((item, index) =>
        item?.status === 'inactive' ? null : (
          <Stack key={`${index}-${item.timestamp}-${item.id}`}>
            <Flex direction="row" gap="md">
              <Flex sx={{ flexBasis: '50%' }}>
                <TextInput
                  disabled={isDisabled}
                  label="Description"
                  placeholder="Description"
                  w="100%"
                  {...form.getInputProps(`lineItems.${index}.description`)}
                />
              </Flex>
              <Flex sx={{ flexBasis: '15%' }}>
                <FlexNumberInput
                  disabled={isDisabled}
                  icon=" "
                  iconWidth={10}
                  label="Qty"
                  thousandSeparator
                  decimalScale={0}
                  allowNegative={false}
                  placeholder="0"
                  variant="unstyled"
                  {...form.getInputProps(`lineItems.${index}.quantity`)}
                  onChange={() => null}
                  onValueChange={(value) => {
                    form.setFieldValue(
                      `lineItems.${index}.quantity`,
                      value?.floatValue,
                    );
                  }}
                />
              </Flex>
              <Flex sx={{ flexBasis: '35%' }}>
                <FlexNumberInput
                  disabled={isDisabled}
                  label="Amount"
                  placeholder="0.00"
                  thousandSeparator
                  decimalScale={2}
                  allowNegative={false}
                  icon="$"
                  iconWidth={24}
                  variant="unstyled"
                  {...form.getInputProps(`lineItems.${index}.total`)}
                  onChange={() => null}
                  onValueChange={(value) => {
                    form.setFieldValue(
                      `lineItems.${index}.total`,
                      value?.floatValue,
                    );
                  }}
                />
              </Flex>
            </Flex>
            {activeLineItems.length > 1 && (
              <Flex justify="flex-end">
                <Flex
                  onClick={() => handleDeleteLineItem(index)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: theme.fn.themeColor('red.1', 0),
                    },
                  }}
                  gap="xxs"
                  align="center"
                  py="xxs"
                  px="xs"
                >
                  <Text size={12} c="red.4">
                    Remove
                  </Text>
                  <IoTrashOutline
                    size={14}
                    color={theme.fn.themeColor('red', 4)}
                  />
                </Flex>
              </Flex>
            )}
            <Divider />
          </Stack>
        ),
      )}
      <Flex>
        <Button
          disabled={isDisabled}
          variant="subtle"
          leftIcon={
            <IoMdAdd
              size={20}
              color={theme.fn.themeColor('primarySecondarySuccess', 4)}
            />
          }
          onClick={createLineItem}
        >
          <Text c="primarySecondarySuccess.4" size={14}>
            Add Another Line Item
          </Text>
        </Button>
      </Flex>

      <Flex direction="row" gap="md">
        <FlexNumberInput
          disabled={isDisabled}
          label="Credits"
          thousandSeparator
          decimalScale={2}
          allowNegative={false}
          icon="$"
          iconWidth={24}
          placeholder="0.00"
          variant="unstyled"
          w="100%"
          {...form.getInputProps('credits')}
          onChange={() => null}
          onValueChange={(value) => {
            form.setFieldValue('credits', value?.floatValue);
          }}
        />
        <FlexNumberInput
          disabled={isDisabled}
          label="Tax"
          thousandSeparator
          decimalScale={2}
          allowNegative={false}
          icon="$"
          iconWidth={24}
          placeholder="0.00"
          variant="unstyled"
          data-testid="tax"
          w="100%"
          {...form.getInputProps('tax')}
          onChange={() => null}
          onValueChange={(value) => {
            form.setFieldValue('tax', value?.floatValue);
          }}
        />
      </Flex>
      <Flex direction="row" gap="md" mb={20}>
        <DateInput
          disabled={isDisabled}
          label="Due Date"
          w="100%"
          icon={<IoCalendarClearOutline size={18} />}
          placeholder="Select due date"
          {...form.getInputProps('dueDate')}
          value={form.values.dueDate ? new Date(form.values.dueDate) : null}
          onChange={(date) =>
            form.setFieldValue('dueDate', date?.toISOString())
          }
        />
      </Flex>
      <LineItemsTotal
        title="Invoice total"
        description="To send a wire to this recipient, this payment must meet the
              minimum wire amount of $50.00."
        invoiceTotal={invoiceTotal.formatted}
        isError={showError}
      />
    </Stack>
  );
};

export default AddLineItems;
