import { FlexWizard } from '@common/wizard/components/flex-wizard';
import { Button, Divider, Flex, Group, Space, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  useDeleteBillpayInvoice,
  useUpdateBillpayInvoice,
} from '@queries/use-bill-pay';
import { memo, PropsWithChildren, useState } from 'react';
import { FiArrowLeft, FiArrowRight, FiTrash } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { InvoiceWizardState, useInvoiceWizard } from './invoice-wizard';
import { useMakePayment } from '@queries/use-payments';
import { v4 as uuidv4 } from 'uuid';
import { isFuturePayment } from '@utilities/dates/dates';
import {
  CreateACHMoneyMovement,
  CreateWireMoneyMovement,
  MoneyMovement,
} from '@services/flexbase/banking.model';
import ReviewPayStep from './steps/review-pay-step/review-pay-step';
import { DateTime } from 'luxon';
import InvoiceWizardDialog from './invoice-wizard-dialog';
import AdditionalInformationStep from './steps/additional-information-step';

type PayInvoiceParams = {
  scheduledForDate: Date;
  sourceAccountId: string;
  invoiceMemo: string;
  recipientPaymentMethod: string;
  amount: string;
  recipientId: string;
  notes?: string;
  billpayInvoicesId: string;
};

const buildMakePaymentParams = ({
  scheduledForDate,
  sourceAccountId,
  invoiceMemo,
  recipientPaymentMethod,
  amount,
  recipientId,
  notes,
  billpayInvoicesId,
}: PayInvoiceParams) => {
  const idempotencyToken = uuidv4();
  const formattedScheduledFor =
    DateTime.fromJSDate(scheduledForDate).toISODate()?.toString() ?? '';
  const scheduledFor = isFuturePayment(formattedScheduledFor)
    ? scheduledForDate
    : null;

  const commonParams = {
    idempotencyToken,
    accountId: sourceAccountId,
    direction: 'Credit',
    description: invoiceMemo,
    notes,
    scheduledFor,
    billpayInvoicesId,
  };

  const domesticPaymentParams = {
    ...commonParams,
    type: recipientPaymentMethod,
    amount,
    counterpartyId: recipientId,
  } as CreateWireMoneyMovement | CreateACHMoneyMovement;

  return { domesticPaymentParams };
};

type FooterWrapperProps = {
  isMobile: boolean;
} & PropsWithChildren;

const FooterWrapper = ({ children, isMobile }: FooterWrapperProps) => {
  return (
    <>
      <FlexWizard.Divider />
      <Group
        p="lg"
        position="apart"
        sx={{
          flexDirection: isMobile ? 'column-reverse' : undefined,
        }}
      >
        {children}
      </Group>
    </>
  );
};

const InvoiceWizardFooter = () => {
  const navigate = useNavigate();
  const {
    hasPreviousStep,
    goToPreviousStep,
    isMobile,
    hasNextStep,
    goToNextStep,
    state,
    triggerEvent,
    setState,
    getEventHandler,
    currentStep,
    isFinalStep,
  } = useInvoiceWizard();
  const {
    isNextEnabled,
    existingInvoiceId,
    error,
    isActionDisabled,
    isInvoiceDraft,
    isBookkeeper,
  } = state;
  const { mutate: mutateBillpayInvoice, isPending: isPendingUpdate } =
    useUpdateBillpayInvoice();
  const { mutate: deleteBillpayInvoice, isPending: isPendingDelete } =
    useDeleteBillpayInvoice();
  const { mutate: makePayment, isPending: isPendingMakePayment } =
    useMakePayment();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleBack = hasPreviousStep ? () => goToPreviousStep() : undefined;
  const handleNext = hasNextStep
    ? () => {
        const handler = getEventHandler('next');
        if (handler) {
          return handler();
        }
        goToNextStep();
      }
    : undefined;

  const handlePayBill = () => {
    if (
      !state.amountSource ||
      !state.invoiceDetails ||
      !state.memo ||
      !state.invoiceTotal
    ) {
      return;
    }

    const initialDomesticParams: PayInvoiceParams = {
      scheduledForDate: state.amountSource.sendOn,
      sourceAccountId: state.amountSource.sendFrom.id,
      invoiceMemo: state.memo,
      notes: state.notes,
      recipientPaymentMethod: state.recipientAccount!.type,
      amount: state.invoiceTotal.formatted,
      recipientId: state.recipientAccount!.id,
      billpayInvoicesId: existingInvoiceId!,
    };

    const { domesticPaymentParams } = buildMakePaymentParams(
      initialDomesticParams,
    );

    const updateSubmissionsDetailsAndGoToLastStep = (
      details: Error | MoneyMovement,
    ) => {
      setState((prev) => ({
        ...prev,
        paymentSubmissionDetails: details,
      }));
      goToNextStep();
    };

    makePayment(domesticPaymentParams, {
      onSuccess: (data) => {
        updateSubmissionsDetailsAndGoToLastStep(data);
      },
      onError: (err) => {
        updateSubmissionsDetailsAndGoToLastStep(err);
      },
    });
  };

  const handleDelete = () => {
    deleteBillpayInvoice(existingInvoiceId!, {
      onSuccess: (_data) => {
        notifications.show({
          color: 'primarySecondarySuccess.2',
          title: 'Success',
          message: 'Invoice deleted successfully',
        });
        navigate('/bill-pay/bills');
      },
      onError: (err) => {
        setState({
          error: {
            message:
              err?.message ||
              'An unexpected error occurred while deleting invoice. Please try again.',
            errorType: 'delete',
          },
        });
      },
    });
  };
  /**
   * Steps can return updated state fields from the `onSaveEdits` method.
   * Return false from handler to indicate that the save should not proceed.
   */
  const handleSaveEdits = async () => {
    const updatedStateFields = await triggerEvent('onSaveEdits');
    if (updatedStateFields === false) {
      setState({
        error: {
          message:
            'An unexpected error occurred while editing invoice. Please try again.',
          errorType: 'update',
        },
      });
      return;
    }

    const newStateValues = {
      ...state,
      ...((updatedStateFields || {}) as Partial<InvoiceWizardState>),
    };
    mutateBillpayInvoice(
      {
        id: existingInvoiceId!,
        recipientId: newStateValues.recipient?.id,
        invoiceNumber: newStateValues.invoiceNumber,
        description: newStateValues.memo,
        dueDate: newStateValues?.invoiceDetails?.dueDate,
        documentId: newStateValues.existingDocumentId,
        total: newStateValues.invoiceTotal?.cents,
      },
      {
        onSuccess: (_data) => {
          notifications.show({
            color: 'primarySecondarySuccess.2',
            title: 'Success',
            message: 'Invoice updated successfully',
          });
          navigate('/bill-pay/bills');
        },
        onError: (err) => {
          setState({
            error: {
              message:
                err?.message ||
                'An unexpected error occurred while editing invoice. Please try again.',
              errorType: 'update',
            },
          });
        },
      },
    );
  };

  if ((!isNextEnabled && !hasPreviousStep) || !!error || isFinalStep)
    return null;

  if (currentStep?.id === ReviewPayStep.stepId) {
    return (
      <FooterWrapper isMobile={isMobile}>
        {hasPreviousStep ? (
          <Button
            variant="outline"
            leftIcon={<FiArrowLeft />}
            onClick={handleBack}
            miw={isMobile ? '100%' : undefined}
            disabled={isPendingUpdate || isPendingDelete}
          >
            Back
          </Button>
        ) : isMobile ? null : (
          <Space />
        )}

        {isNextEnabled && (
          <Flex align="center" gap="xs">
            <Text size="xs" c="neutral.7">
              By clicking &apos;Pay bill&apos;, I authorize Flex to initiate the
              transaction detailed above.
            </Text>
            <Button
              variant="default"
              onClick={handlePayBill}
              miw={isMobile ? '100%' : undefined}
              disabled={
                isPendingUpdate || isPendingMakePayment || isActionDisabled
              }
            >
              Pay bill
            </Button>
          </Flex>
        )}
      </FooterWrapper>
    );
  }

  return (
    <>
      <FooterWrapper isMobile={isMobile}>
        {hasPreviousStep && hasNextStep ? (
          <Button
            variant="outline"
            leftIcon={<FiArrowLeft />}
            onClick={handleBack}
            miw={isMobile ? '100%' : undefined}
            disabled={isPendingUpdate || isPendingDelete}
          >
            Back
          </Button>
        ) : isMobile ? null : (
          <Space />
        )}
        <Group miw={isMobile ? '100%' : undefined}>
          {existingInvoiceId && (
            <>
              <Group spacing={0} miw={isMobile ? '100%' : undefined}>
                <Button
                  variant="subtle"
                  leftIcon={<FiTrash />}
                  onClick={handleDialogOpen}
                  miw={isMobile ? '100%' : undefined}
                  c="critical.6"
                  disabled={
                    isPendingUpdate || isPendingDelete || isActionDisabled
                  }
                  loading={isPendingUpdate || isPendingDelete}
                >
                  Delete
                </Button>
                {!isMobile && <Divider orientation="vertical" />}
              </Group>

              <Button
                variant="outline"
                onClick={handleSaveEdits}
                miw={isMobile ? '100%' : undefined}
                disabled={
                  isPendingUpdate ||
                  isPendingDelete ||
                  !isInvoiceDraft ||
                  (isBookkeeper &&
                    currentStep?.id !== AdditionalInformationStep.stepId)
                }
                c="primarySecondarySuccess"
                sx={(theme) => ({
                  border: `1px solid ${theme.fn.themeColor(
                    'primarySecondarySuccess',
                    6,
                  )}`,
                })}
              >
                Save Changes
              </Button>
            </>
          )}
          {isNextEnabled && hasNextStep && (
            <Button
              variant="default"
              rightIcon={<FiArrowRight />}
              onClick={handleNext}
              miw={isMobile ? '100%' : undefined}
              disabled={isPendingUpdate || isPendingDelete}
            >
              Continue
            </Button>
          )}
        </Group>
      </FooterWrapper>
      {isDialogOpen && (
        <InvoiceWizardDialog
          onClose={handleDialogClose}
          title="Are you sure you want to delete this bill?"
          message="Deleting this bill will remove it from your list of bills. This action can only be undone by reaching out to support."
          options={[
            {
              label: 'Continue Editing',
              onClick: handleDialogClose,
              variant: 'subtle',
              disabled: isPendingDelete,
            },
            {
              label: 'Delete',
              onClick: handleDelete,
              variant: 'light',
              disabled: isPendingDelete,
              loading: isPendingDelete,
            },
          ]}
        />
      )}
    </>
  );
};

export default memo(InvoiceWizardFooter);
