import { Text, Button, Badge, Avatar, Flex } from '@mantine/core';
import { PlusSignIcon, SearchIcon } from 'assets/svg';
import FlexbaseInput from 'components/input/flexbase-input';
import { FlexbaseTable } from 'components/table';
import { useMediaQuery } from '@mantine/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { sortAmount, sortDate } from 'utilities/dates/dates';
import { formatApiStrings } from 'utilities/formatters/format-api-strings';
import { UserInfoState } from 'states/user/user-info';
import { useRecoilValue } from 'recoil';
import GenericError from 'areas/banking/utils/generic-error';
import { TableColumn } from 'react-data-table-component';
import {
  generateBillPaymentRows,
  TablePayment,
} from 'areas/payments/components/common/payments-rows';
import PaymentDetailsModal from 'areas/payments/components/common/payment-details-modal';
import { useStyles } from './action-required.styles';
import { useBillpayPayments } from '@utilities/custom-hooks/use-payments';
import { getInitialsOfNames } from '@utilities/extensions/object';

const columns: TableColumn<TablePayment>[] = [
  {
    name: 'Date submitted',
    selector: (payment) => payment.dateSubmitted ?? '',
    sortable: true,
    sortFunction: sortDate,
  },
  {
    name: 'Vendor',
    cell: (row) => (
      <Flex align="center">
        <Avatar radius="xl" mr={15} w={45} h={45}>
          {getInitialsOfNames(row.vendor ?? '')}
        </Avatar>
        {row.vendor}
      </Flex>
    ),
    selector: (payment) => payment.vendor ?? '',
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'Amount',
    selector: (payment) => payment.amount,
    sortable: true,
    sortFunction: sortAmount,
  },
  {
    name: 'Payment method',
    selector: (payment) => payment.type,
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'Submitted by',
    cell: (payment) => (
      <Flex align="center">
        <Avatar radius="xl" mr={15} w={45} h={45}>
          {getInitialsOfNames(payment.requestedBy ?? '')}
        </Avatar>
        {payment.requestedBy}
      </Flex>
    ),
    selector: (payment) => payment.requestedBy ?? '',
    sortable: true,
    grow: 1.4,
  },
  {
    name: 'Invoice number',
    cell: (payment) => payment.invoiceNumber ?? 'N/A',
    selector: (payment) => payment.invoiceNumber ?? '',
    sortable: true,
  },
  {
    name: 'Due date',
    selector: (payment) => payment.expectedCompletionDate ?? '',
    sortable: true,
  },
  {
    name: 'Status',
    sortable: true,
    selector: (payment) => payment.status,
    cell: (payment) => (
      <Badge bg="tertiary.0" color="tertiary.5">
        {formatApiStrings(payment.status)}
      </Badge>
    ),
  },
];

const columnsSm: TableColumn<TablePayment>[] = [
  {
    name: 'Date submitted',
    selector: (payment) => payment.dateSubmitted ?? '',
    sortable: true,
    grow: 1.8,
  },
  {
    name: 'Vendor',
    cell: (row) => (
      <Flex align="center">
        <Avatar radius="xl" mr={15} w={45} h={45}>
          {getInitialsOfNames(row.vendor ?? '')}
        </Avatar>
        {row.vendor}
      </Flex>
    ),
    selector: (payment) => payment.vendor ?? '',
    sortable: true,
    grow: 1.8,
  },
  {
    name: 'Amount',
    selector: (payment) => payment.amount,
    sortable: true,
    sortFunction: sortAmount,
  },
  {
    name: 'Payment method',
    selector: (payment) => payment.type,
    sortable: true,
    grow: 2,
  },
  {
    name: 'Submitted by',
    cell: (payment) => (
      <Flex align="center">
        <Avatar radius="xl" mr={15} w={45} h={45}>
          {getInitialsOfNames(payment.requestedBy ?? '')}
        </Avatar>
        {payment.requestedBy}
      </Flex>
    ),
    selector: (payment) => payment.requestedBy ?? '',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Invoice number',
    selector: (payment) => payment.invoiceNumber ?? '',
    sortable: true,
    grow: 1.8,
  },
  {
    name: 'Due date',
    selector: (payment) => payment.expectedCompletionDate ?? '',
    sortable: true,
    grow: 1.8,
  },
  {
    name: 'Status',
    sortable: true,
    selector: (payment) => payment.status,
    cell: (payment) => (
      <Badge bg="tertiary.0" color="tertiary.5">
        {formatApiStrings(payment.status)}
      </Badge>
    ),
    grow: 2.4,
  },
];

const ActionRequired = () => {
  const { id } = useParams();
  const user = useRecoilValue(UserInfoState);
  const isBookkeeper = user.roles.includes('ACCOUNTANT');
  const navigate = useNavigate();
  const { classes } = useStyles();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [searchTerm, setSearchTerm] = useState<string>();

  const { users, payments, depositAccounts, isError, loading, refetch } =
    useBillpayPayments();

  const handleSearchChange = (val: string) => setSearchTerm(val.toLowerCase());

  const rows = generateBillPaymentRows({
    users,
    payments,
    depositAccounts,
  }).filter((payment) => {
    if (!searchTerm) {
      return payment;
    } else {
      return (
        payment?.dateSubmitted?.toLowerCase().includes(searchTerm) ||
        payment.accountName?.toLowerCase().includes(searchTerm) ||
        payment.amount.toLowerCase().includes(searchTerm) ||
        payment.status.toLowerCase().includes(searchTerm) ||
        payment.description.toLowerCase().includes(searchTerm) ||
        payment.requestedBy?.toLowerCase().includes(searchTerm) ||
        payment.recipient?.toLowerCase().includes(searchTerm) ||
        payment.notes?.toLowerCase().includes(searchTerm) ||
        payment.type.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
        payment.invoiceNumber
          ?.toLowerCase()
          .startsWith(searchTerm.toLowerCase()) ||
        payment.expectedCompletionDate
          ?.toLowerCase()
          .startsWith(searchTerm.toLowerCase()) ||
        payment.vendor?.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
    }
  });

  if (isError) {
    return (
      <GenericError>
        <p>Error</p>
        <Button variant="light" onClick={refetch} mt={20}>
          Please try again
        </Button>
      </GenericError>
    );
  }

  const openPayment = rows.find((row) => row.id === id);

  const awaitingApprovalRows = rows
    .filter(
      (row) =>
        row.status === 'Approval Needed' ||
        (row.status === '2FA Required' &&
          (row.approvedBy === user.id || row.userId === user.id)),
    )
    .sort((a, b) => sortDate(a, b, false));

  const handleCreateInvoice = () => {
    navigate('/bill-pay/new');
  };

  return (
    <>
      {openPayment && <PaymentDetailsModal {...openPayment} />}
      <div className={classes.container}>
        <div className={classes.header}>
          <FlexbaseInput
            w={isMobile ? '100%' : '300px'}
            placeholder="Search payments"
            onChange={(e) => handleSearchChange(e.target.value)}
            icon={<SearchIcon width={20} height={20} />}
          />
          {!isBookkeeper && (
            <Button
              bg={'primarySecondarySuccess.6'}
              onClick={handleCreateInvoice}
              leftIcon={<PlusSignIcon width={12} height={12} />}
            >
              Add New Bill
            </Button>
          )}
        </div>
        <FlexbaseTable
          columns={isMobile ? columnsSm : columns}
          data={awaitingApprovalRows}
          pagination={awaitingApprovalRows && awaitingApprovalRows?.length > 8}
          onRowClicked={(row) => navigate(`${row.id}`)}
          isFetchingData={loading}
          noDataComponent={
            <Text size={24} fw={500} mt="lg">
              No actions required
            </Text>
          }
        />
      </div>
    </>
  );
};

export default ActionRequired;
