import { createWizard } from '@common/wizard/create-wizard';
import { FileWithPath } from '@mantine/dropzone';
import { MoneyMovement } from '@services/flexbase/banking.model';
import {
  DepositAccount,
  PlaidAccount,
} from 'areas/banking/move-funds/move-funds.model';
import { ParsedAccount } from 'areas/payments/pages/recipient-details/types';
import { LineItemStatus } from 'states/bill-pay/bill-pay';
import { Recipient } from 'states/recipient/recipient';

export type LineItemInput = {
  id?: string;
  description?: string;
  quantity?: number;
  total?: number;
  timestamp?: number;
  status?: LineItemStatus;
};

export type InvoiceDetails = {
  lineItems: LineItemInput[];
  credits?: number;
  dueDate?: string;
  tax?: number;
};

export type AmountSource = {
  sendFrom: DepositAccount | PlaidAccount;
  sendOn: Date;
};

export type InvoiceWizardError = {
  message: string;
  errorType: 'delete' | 'update' | 'create';
};

export type InvoiceWizardState = {
  existingInvoiceId?: string;
  recipient: Recipient;
  existingDocument?: FileWithPath;
  uploadedDocument?: FileWithPath;
  existingDocumentId?: string;
  invoiceDetails?: InvoiceDetails;
  amountSource?: AmountSource;
  isNextEnabled: boolean;
  invoiceNumber?: string;
  memo: string;
  notes?: string;
  error?: InvoiceWizardError;
  paymentSubmissionDetails: Error | MoneyMovement;
  invoiceTotal?: { formatted: string; cents: number };
  isInvoiceDraft?: boolean;
  isActionDisabled?: boolean;
  isBookkeeper?: boolean;
  recipientAccount?: ParsedAccount;
};

export const [InvoiceWizard, useInvoiceWizard] =
  createWizard<InvoiceWizardState>();
