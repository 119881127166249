import { Outlet } from 'react-router-dom';
import FlexbaseDefaultHeader from 'components/header/current/flexbase-header';
import { NavTab } from 'components/tabs/nav-tabs';
import FlexbaseMainLayout from 'components/layouts/main';
import { useRecoilValue } from 'recoil';
import {
  IsAccountant,
  IsEmployee,
} from '../../states/application/product-onboarding';
import { NavTabContainer } from '@common/tabs/nav-tab-container';

const CompanySettings = () => {
  const isAccountant = useRecoilValue(IsAccountant);
  const isEmployee = useRecoilValue(IsEmployee);

  const tabs: NavTab[] = [
    { label: 'Personal profile', route: '/settings/profile' },
    {
      label: 'Company profile',
      route: '/settings/company-profile',
      disabled: isEmployee ? true : false,
    },
    {
      label: 'SMS Alerts',
      route: '/settings/notifications',
      disabled: isAccountant ? true : false,
    },
    {
      label: 'Integrations',
      route: '/settings/integrations',
      disabled: isEmployee ? true : false,
    },
    {
      label: 'Legal',
      route: '/settings/user-agreements',
    },
  ];

  return { tabs };
};

const Credit = () => {
  return (
    <FlexbaseMainLayout>
      <FlexbaseDefaultHeader title={'Settings'} />
      <NavTabContainer tabs={CompanySettings().tabs}>
        <Outlet />
      </NavTabContainer>
    </FlexbaseMainLayout>
  );
};

export default Credit;
