import { capitalizeOnlyFirstLetter } from '@flexbase-eng/web-components';
import { useMantineTheme } from '@mantine/core';
import { MoneyMovementStatus } from '@services/flexbase/banking.model';
import { PayMethod } from 'areas/payments/components/send-payment/payment.states';

export const getPaymentStatusDisplay = (status: MoneyMovementStatus) => {
  const theme = useMantineTheme();
  let backgroundColor: string, textColor: string, displayStatus: string;

  switch (status) {
    case 'AwaitingApproval':
    case 'AwaitingConfirmation':
    case 'Pending':
    case 'PendingReview':
      displayStatus =
        status === 'AwaitingApproval'
          ? 'Needs approval'
          : status === 'AwaitingConfirmation'
          ? 'Awaiting confirmation'
          : status === 'Pending'
          ? 'Pending'
          : 'Pending review';
      backgroundColor = theme.fn.themeColor('tertiary', 0);
      textColor = theme.fn.themeColor('tertiary', 5);
      break;

    case 'Scheduled':
    case 'Queued':
    case 'Clearing':
    case 'Sent':
    case 'Returned':
      displayStatus = status;
      backgroundColor = theme.fn.themeColor('flexbaseGrey', 0);
      textColor = theme.fn.themeColor('neutral', 7);
      break;

    case 'Rejected':
    case 'Canceled':
      displayStatus = status;
      backgroundColor = theme.fn.themeColor('critical', 0);
      textColor = theme.fn.themeColor('critical', 6);
      break;

    default:
      displayStatus = status;
      backgroundColor = theme.fn.themeColor('flexbaseGrey', 0);
      textColor = theme.fn.themeColor('neutral', 7);
  }

  return { displayStatus, backgroundColor, textColor };
};

export const formatPaymentType = (type: PayMethod) => {
  switch (type) {
    case 'ach':
      return 'ACH';
    case 'wire':
    case 'book':
      return capitalizeOnlyFirstLetter(type);
    case 'internationalWire':
    case 'internationalWireUSD':
      return 'International wire';
    default:
      return type;
  }
};
