import { Box, Button, Text, TextInput, createStyles } from '@mantine/core';
import { CloseIcon, PlusSignIcon, SearchIcon } from 'assets/svg';
import { useMediaQuery } from '@mantine/hooks';
import { isTruthyString } from '@utilities/validators/validate-string';
import { IoFunnelOutline } from 'react-icons/io5';
import useModal from '@common/modal/modal-hook';
import { FilterModal } from '@common/filter/filter-modal';
import { useEffect, useMemo, useRef } from 'react';
import {
  useSpendCardFilters,
  useSpendCardModalFilters,
} from './use-spend-cards-filter';
import { SpendCardholderFilter } from './filters/cardholder-filter';
import { SpendCardNameFilter } from './filters/spend-card-name-filter';
import { RepeatFundsFilter } from './filters/repeat-funds-filter';
import { AmountAssignedFilter } from './filters/amount-assiged-filter';
import { SpendPlanCardsResponse } from '@services/flexbase/spend-plans.model';
import IssueCardHeader from 'areas/cards/issue-card';
import {
  useGetAdminSpendPlan,
  useGetSpendPlan,
} from '@queries/use-spend-plans';
import { useParams } from 'react-router-dom';
import { useCreditCards } from '@queries/use-credit-cards';
import { useRecoilValue } from 'recoil';
import { IsAdmin } from 'states/application/product-onboarding';
import { usePlatformPersonContext } from 'providers/platform-person.context';

type Props = {
  data: SpendPlanCardsResponse[];
};

const SpendCardsTableHeader = ({ data }: Props) => {
  const { classes, theme } = useStyles();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const { openFullModal, closeAllModals } = useModal();
  const modal = useModal();
  const {
    applyAllFilters: applyAllModalFilters,
    activeFilters: activeModalFilters,
  } = useSpendCardModalFilters();

  const {
    applyAllFilters: applyAllTableFilters,
    removeAllFilters,
    activeFilters: activeTableFilters,
    activeFiltersArray: activeTableFiltersArray,
    getFilterByKey,
    addFilter,
    removeFilter,
  } = useSpendCardFilters();

  const activeModalFiltersRef = useRef(activeModalFilters);

  useEffect(() => {
    activeModalFiltersRef.current = activeModalFilters;
  }, [activeModalFilters]);

  const { id: spendPlanId = '' } = useParams();
  const { person } = usePlatformPersonContext();
  const accountId = person?.accountId || '';
  const isAdmin = useRecoilValue(IsAdmin);

  const { data: spendPlan } = useGetSpendPlan({
    accountId: accountId,
    spendPlanId: spendPlanId,
    isAdmin,
  });

  const { data: adminSpendPlan } = useGetAdminSpendPlan({
    accountId: accountId,
    spendPlanId: spendPlanId || '',
    isAdmin,
  });
  const { refetch } = useCreditCards();

  const refetchCards = () => {
    refetch();
  };

  const plan = useMemo(() => {
    if (isAdmin) {
      return adminSpendPlan;
    } else {
      return spendPlan;
    }
  }, [adminSpendPlan, spendPlan]);

  const onIssueCardClick = () => {
    openFullModal(
      <IssueCardHeader
        closeModal={closeAllModals}
        addCompanyCard={refetchCards}
        spendPlan={plan}
        method={'spendPlan'}
      />,
    );
  };

  const filters = [
    {
      key: 'cardholder',
      header: 'Cardholder',
      component: <SpendCardholderFilter cards={data || []} />,
    },
    {
      key: 'repeatFunds',
      header: 'Repeat funds',
      component: <RepeatFundsFilter />,
    },
    {
      key: 'cardName',
      header: 'Card name',
      component: <SpendCardNameFilter cards={data || []} />,
    },
    {
      key: 'remainingPtd',
      header: 'Assigned (remaining)',
      component: <AmountAssignedFilter />,
    },
  ];

  const onSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (card) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(card)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const handleModalSave = () => {
    applyAllTableFilters(activeModalFiltersRef.current);
    modal.closeAllModals();
  };

  const handleModalCancel = () => {
    modal.closeAllModals();
  };

  const filterChips = activeTableFiltersArray.filter((f) => f.showChip);

  const openModal = () => {
    applyAllModalFilters(activeTableFilters);

    useMobileView
      ? modal.openFullModal(
          <FilterModal
            filters={filters}
            onSave={handleModalSave}
            onCancel={handleModalCancel}
          />,
        )
      : modal.openRightModal(
          <FilterModal
            filters={filters}
            onSave={handleModalSave}
            onCancel={handleModalCancel}
          />,
          theme.fn.themeColor('neutral', 2),
        );
  };

  return (
    <>
      <Box
        sx={() => ({
          display: 'flex',
          gap: theme.spacing.md,
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        })}
        mb="md"
      >
        <Box
          sx={() => ({
            display: 'flex',
            gap: theme.spacing.md,
          })}
        >
          <TextInput
            w={useMobileView ? '100%' : 368}
            placeholder="Search cards"
            icon={<SearchIcon width={20} height={20} />}
            value={getFilterByKey('search')?.filterValue || ''}
            onChange={(e) => onSearchChange(e.target.value)}
          />
          <Button
            variant="outline"
            rightIcon={<IoFunnelOutline />}
            onClick={() => openModal()}
            disabled={data.length === 0}
          >
            Filters
          </Button>
          {isAdmin && !!plan?.isPlanActive && (
            <Button
              variant="outline"
              leftIcon={<PlusSignIcon />}
              onClick={onIssueCardClick}
            >
              New credit card
            </Button>
          )}
        </Box>
      </Box>
      {filterChips.length > 0 && (
        <Box
          sx={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}
          mih={30}
          mb="md"
        >
          {filterChips.map((p) => (
            <Box
              sx={() => ({
                height: 30,
                borderRadius: theme.radius.xs,
                border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
                backgroundColor: theme.fn.themeColor('neutral', 2),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                padding: `0px ${theme.spacing.md}`,
                gap: 4,
                [theme.fn.smallerThan('md')]: {
                  height: 'auto',
                  padding: `0px ${theme.spacing.xl}`,
                },
              })}
              onClick={() => {
                removeFilter(p.key);
              }}
              key={p.key}
            >
              <Text color="black" fz={14} fw={500}>
                {p.label}
              </Text>
              <CloseIcon color="black" style={{ marginLeft: 'auto' }} />
            </Box>
          ))}
          <Text
            className={classes.clearFilters}
            onClick={() => {
              removeAllFilters();
            }}
          >
            Clear filters
          </Text>
        </Box>
      )}
    </>
  );
};

export default SpendCardsTableHeader;

const useStyles = createStyles((theme) => ({
  clearFilters: {
    cursor: 'pointer',
    color: theme.fn.themeColor('primarySecondarySuccess', 2),
  },
}));
