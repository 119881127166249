import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  CardByUser,
  CardStatus,
  IssueDebitCard,
  UpdateCardRequest,
} from '@services/flexbase/banking.model';
import { updateQueryData } from './helpers';
import { flexbaseBankingClient } from '@services/flexbase-client';
import { QUERY_KEY as CREDIT_CARDS_QUERY_KEY } from './use-credit-cards';
import { cardIsChargeCard, FlexCard } from '@services/flexbase/card.model';

const QUERY_KEY = 'debitCards';

const onMutateSuccess = (queryClient: QueryClient, data: CardByUser) => {
  updateQueryData<CardByUser[]>(queryClient, [QUERY_KEY], (prev) => {
    if (!prev) {
      return [];
    }

    const prevArray: CardByUser[] = Array.isArray(prev)
      ? prev
      : Array.from(prev as Set<CardByUser>);

    return prevArray.map((card: CardByUser) =>
      card.id === data.id ? { ...card, ...data } : card,
    );
  });
};

export const useGetDebitCards = (accountId?: string, enabled?: boolean) => {
  return useQuery({
    queryKey: [QUERY_KEY, accountId],
    queryFn: async () => {
      const { cards } = await flexbaseBankingClient.getDebitCards(
        true,
        accountId,
      );
      return cards;
    },
    meta: {
      errorMessage: 'We are unable to retrieve debit cards at the moment.',
    },
    enabled: enabled ?? true,
  });
};

export const useIssueDebitCard = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (debitCardForm: IssueDebitCard) => {
      return await flexbaseBankingClient.issueDebitCard(debitCardForm);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });
};

export const useUpdateDebitCard = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (card: UpdateCardRequest) => {
      return await flexbaseBankingClient.updateBankingDebitCard(card);
    },
    onSuccess: (data) => {
      const card = data.card;
      onMutateSuccess(queryClient, card);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });
};

type UpdateCardStatusArgs = { cardId: string; status: CardStatus };

export const useUpdateDebitCardStatus = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ cardId, status }: UpdateCardStatusArgs) => {
      return await flexbaseBankingClient.cardStatus(cardId, status);
    },
    onSuccess: (data) => {
      const card = data.card;
      onMutateSuccess(queryClient, card);
      const isChargeCard = cardIsChargeCard(card as unknown as FlexCard);
      // we use credit cards query to display charge cards
      const queryKey = isChargeCard ? CREDIT_CARDS_QUERY_KEY : QUERY_KEY;
      queryClient.invalidateQueries({ queryKey: [queryKey] });
    },
  });
};

export const useGetPinStatus = (cardId: string, cardStatus: string) => {
  const isDebitCardActive = cardStatus === 'active';

  return useQuery({
    queryKey: [QUERY_KEY, 'pinStatus', cardId],
    queryFn: async () => {
      const { success, status } = await flexbaseBankingClient.getPinStatus(
        cardId,
      );
      if (success) {
        const pinStatus = status.data.attributes.status;
        return pinStatus;
      }
    },
    meta: {
      errorMessage: 'Error getting the PIN status for the debit card.',
    },
    enabled: !!isDebitCardActive,
  });
};
