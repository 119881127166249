import { useWizardMobile } from '@common/wizard/utils/use-wizard-mobile';
import { useInvoiceWizard } from './invoice-wizard';
import { useWizardStyles } from '@common/wizard/wizard.styles';
import { Box, Group, rem } from '@mantine/core';
import { memo } from 'react';
import InvoiceWizardStepError from './steps/invoice-wizard-step-error';

function WizardStepWithPreviewIfDefined() {
  const isMobile = useWizardMobile();
  const { currentStep, state, setState, isFinalStep } = useInvoiceWizard();
  const { classes } = useWizardStyles();
  const { error } = state;
  const hasPreviewStep = !!currentStep?.previewElement;

  const onClear = () => {
    setState({ error: undefined });
  };

  return (
    <Group
      className={isFinalStep ? classes.finalStepBody : classes.body}
      spacing={rem(100)}
    >
      <Box className={classes.contentPanel} maw={hasPreviewStep ? 400 : '100%'}>
        {error ? (
          <InvoiceWizardStepError submissionError={error} onClear={onClear} />
        ) : (
          currentStep?.element
        )}
      </Box>
      {isMobile || !hasPreviewStep ? null : currentStep.previewElement}
    </Group>
  );
}

export default memo(WizardStepWithPreviewIfDefined);
