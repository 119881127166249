import { Box, Button, Container, Text, Title } from '@mantine/core';
import { useCheatSheetFeatureFlag } from '@utilities/feature-flags';
import { Link, Navigate } from 'react-router-dom';
import Form from './form';
import WizardErrorCard from '@common/error/wizard-error-card';
import ErrorIcon from '@common/error/error-icon';

const CheatSheet = () => {
  const isFeatureFlagEnabled = useCheatSheetFeatureFlag();
  if (!isFeatureFlagEnabled) {
    return <Navigate to="/" />;
  }

  return (
    <Container>
      <Title>Cheat Sheet</Title>
      <Text>
        This page is an intentionally lo-fi cheat sheet, meant to help kickstart
        your UI workflow.
      </Text>

      <Box mt="xl">
        <Title order={2}>Forms</Title>
        <Text>Sample form #1</Text>
        <Form />
      </Box>

      <Box mt="xl">
        <Title order={2}>Wizard</Title>
        <Link to="/cheat-sheet/wizard">Sample wizard here</Link>
      </Box>

      <Box mt="xl">
        <Title order={2}>Wizard error card</Title>
        <WizardErrorCard
          icon={<ErrorIcon />}
          title="Error title"
          subtitle="Error subtitle"
        >
          <Button size="sm">Try again</Button>
        </WizardErrorCard>
      </Box>
    </Container>
  );
};

export default CheatSheet;
