import PasswordLoginStep from './password-login-step';
import AuthVerifyCode from './verify-code';
import {
  KEY_REMEMBER_USER,
  KEY_USER_EMAIL_STORAGE,
} from 'states/auth/auth-token';
import { LoginFormProvider } from 'areas/login/login-form.context';
import { LoginContextProvider } from 'areas/login/use-login';
import { IntegrationModalContainer } from './integration-modal-container';

export enum AddOauthSteps {
  PASSWORD_LOGIN = 'password-login',
  VERIFY_CODE = 'verify-code',
  LOADING = 'loading',
}

const IntegrationModal = () => {
  return (
    <LoginFormProvider
      initialState={{
        error: null,
        loading: false,
        otpStatus: 'sent',
        email: localStorage?.getItem(KEY_USER_EMAIL_STORAGE) ?? '',
        password: '',
        factorList: [],
        selectedFactor: null,
        rememberUser: localStorage?.getItem(KEY_REMEMBER_USER) === 'true',
        pkce: null,
      }}
      steps={[
        {
          element: <PasswordLoginStep />,
          metadata: {
            title: 'Sign into Flex to continue.',
            id: AddOauthSteps.PASSWORD_LOGIN,
            nextButtonText: 'Submit',
            testId: 'button-auth-submit',
          },
        },
        {
          element: <AuthVerifyCode />,
          metadata: {
            title: 'Enter the 6-digit code we sent to your cellphone.',
            id: AddOauthSteps.VERIFY_CODE,
            nextButtonText: 'Submit',
            testId: 'button-auth-submit',
          },
        },
      ]}
    >
      <LoginContextProvider>
        <IntegrationModalContainer />
      </LoginContextProvider>
    </LoginFormProvider>
  );
};

export default IntegrationModal;
