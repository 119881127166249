import WizardErrorBanner from '@common/error/wizard-error-banner';
import { Box, Center, Divider, Loader, Paper, Text } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import { useExternalAccounts } from '@utilities/custom-hooks/use-external-accounts';
import { isHoliday, isWeekend } from '@utilities/dates/dates';
import {
  DepositAccount,
  PlaidAccount,
} from 'areas/banking/move-funds/move-funds.model';
import AccountSelection from 'areas/payments/components/account-selection';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import {
  AmountSource,
  InvoiceWizard,
  useInvoiceWizard,
} from '../../invoice-wizard';

// Derived from 'send payment' flow
const MAX_PAYMENT_DATE = DateTime.now().plus({ years: 2 }).toJSDate();
const MIN_PAYMENT_DATE = DateTime.now().toJSDate();

const usePaymentAccounts = () => {
  const { data: depositAccounts, isLoading: loadingDepositAccounts } =
    useGetDepositAccounts();
  const { data: plaidAccounts, isLoading: loadingPlaidDepositAccounts } =
    useExternalAccounts();

  const paymentAccounts = [
    ...(depositAccounts?.accounts || [])
      .filter((account) => account.status === 'Open')
      .sort((a, b) => b.balance - a.balance)
      .map((account) => ({ ...account, plaidOrDeposit: 'deposit' as const })),
    ...(plaidAccounts?.reduce((accounts, account) => {
      if (
        !account.unlinked &&
        !accounts.some(({ last4 }) => account.last4 === last4)
      ) {
        accounts.push({ ...account, plaidOrDeposit: 'plaid' as const });
      }
      return accounts;
    }, [] as PlaidAccount[]) || []),
  ];

  return {
    paymentAccounts,
    isLoading: loadingDepositAccounts || loadingPlaidDepositAccounts,
  };
};

const AmountSourceStep = () => {
  const { paymentAccounts, isLoading } = usePaymentAccounts();
  return (
    <AmountSourceStepContent
      paymentAccounts={paymentAccounts}
      isLoading={isLoading}
    />
  );
};

type AmountSourceStepContentProps = {
  paymentAccounts: (PlaidAccount | DepositAccount)[];
  isLoading: boolean;
};

const AmountSourceStepContent = ({
  paymentAccounts,
  isLoading,
}: AmountSourceStepContentProps) => {
  const { state, setState, onNext, onBack, goToNextStep, goToPreviousStep } =
    useInvoiceWizard();

  const [notEnoughFundsError, setNotEnoughFundsError] = useState<
    string | undefined
  >(undefined);

  const { amountSource, isActionDisabled = false } = state || {};

  const initialSendFrom =
    (amountSource && amountSource.sendFrom) || paymentAccounts[0];

  const form = useForm<AmountSource>({
    initialValues: {
      sendFrom: initialSendFrom,
      sendOn: amountSource?.sendOn ?? DateTime.now().toJSDate(),
    },
    validate: {
      sendFrom: (value) => (value ? null : 'Select a source account'),
      sendOn: (value) =>
        value instanceof Date && !isNaN(value.getTime())
          ? null
          : 'Select a valid date',
    },
  });

  useEffect(() => {
    if (initialSendFrom && !form.values.sendFrom) {
      form.setFieldValue('sendFrom', initialSendFrom);
    }
  }, [initialSendFrom, form.values]);

  const onAccountChange = (value: string) => {
    const account = paymentAccounts.find((acc) => acc.id === value);
    form.setFieldValue('sendFrom', account as DepositAccount);
    validateHasEnoughFunds(
      state.invoiceTotal?.cents || 0,
      Number(account?.available) || 0,
    );
  };

  const validateHasEnoughFunds = (
    invoiceTotal: number,
    availableFunds: number,
  ) => {
    if (availableFunds < invoiceTotal) {
      setNotEnoughFundsError('Selected account has insufficient funds.');
      return false;
    }
    setNotEnoughFundsError(undefined);
    return true;
  };

  onNext(() => {
    form.validate();
    if (
      form.isValid() &&
      validateHasEnoughFunds(
        state.invoiceTotal?.cents || 0,
        Number(form.values.sendFrom?.available) || 0,
      )
    ) {
      setState((prev) => ({ ...prev, amountSource: form.values }));
      goToNextStep();
    }
  });

  onBack(() => {
    setState((prev) => ({ ...prev, amountSource: form.values }));
    goToPreviousStep();
  });

  if (isLoading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <InvoiceWizard.Step hideBack hideNext>
      <Paper radius="xs" bg="neutral.0">
        <Box w="100%" p="xxl">
          <Text weight="500" size="sm">
            Recipient gets
          </Text>
          <Text size={40} lh={1}>
            {state.invoiceTotal?.formatted}
          </Text>
          <Divider />
        </Box>
      </Paper>

      <Box mt="xxxl">
        <Text size="sm">Send from</Text>
        {form.values.sendFrom && (
          <AccountSelection
            disabled={isActionDisabled}
            currentAccount={form.values.sendFrom}
            accounts={paymentAccounts}
            onAccountChange={(account) => onAccountChange(account.id)}
          />
        )}

        {notEnoughFundsError && (
          <WizardErrorBanner message={notEnoughFundsError} />
        )}
      </Box>

      <DatePickerInput
        mt="xl"
        label="Send on"
        valueFormat="MMM DD, YYYY"
        minDate={MIN_PAYMENT_DATE}
        maxDate={MAX_PAYMENT_DATE}
        excludeDate={(date) => isWeekend(date) || isHoliday(date)}
        {...form.getInputProps('sendOn')}
        disabled={isActionDisabled}
      />
      <Text size="xs" color={'neutral.7'}>
        Estimated payment of one day
      </Text>
    </InvoiceWizard.Step>
  );
};

AmountSourceStep.stepId = 'amount-source';

export default AmountSourceStep;
