import { showNotification } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { flexbaseBankingClient } from '@services/flexbase-client';

const QUERY_KEY = 'chargeCardAccounts';

export const useGetChargeCardAccounts = (enabled?: boolean) => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      return await flexbaseBankingClient.getChargeCardAccounts();
    },
    meta: {
      errorMessage: 'Unable to retrieve charge card accounts at this time.',
    },
    enabled: enabled ?? true,
  });
};

type UpdateAutoPay = {
  accountId: string;
  depositAccountId: string;
};

const mutationUpdateAutoPay = async (autopay: UpdateAutoPay) => {
  const { accountId, depositAccountId } = autopay;
  return await flexbaseBankingClient.updateChargeAccount(accountId, {
    repaymentConfigurations: {
      autoPay: { depositAccountId },
    },
  });
};

export function useUpdateAutoPayForChargeAccount() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: mutationUpdateAutoPay,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] }),
    onError: (error) => {
      showNotification({
        color: 'red',
        title: 'Error updating the account',
        message: `${error.message}`,
      });
    },
  });
}
