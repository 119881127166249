import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  card: {
    padding: theme.spacing.xl,
    color: theme.colors.neutral[1],
    borderRadius: theme.defaultRadius,
    position: 'relative',
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    marginTop: theme.spacing.lg,
  },
  containerPayment: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
}));
