import { InvoiceWizard, useInvoiceWizard } from '../invoice-wizard';
import { Group, Stack, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import FlexbaseInput from '@common/input/flexbase-input';

type AdditionalInformationForm = {
  invoiceNumber?: string;
  memo: string;
  notes?: string;
};

const AdditionalInformationStep = () => {
  const {
    state,
    setState,
    onEvent,
    onStepChange: handleStepChange,
  } = useInvoiceWizard();
  const { isInvoiceDraft, isActionDisabled } = state;

  const form = useForm<AdditionalInformationForm>({
    initialValues: {
      invoiceNumber: state.invoiceNumber,
      memo: state.memo || '',
      notes: state.notes,
    },
    validate: {
      memo: (value) => {
        if (!isActionDisabled) {
          return value ? null : 'Memo is required';
        }
      },
    },
  });

  onEvent('onSaveEdits', () => {
    setState((prevState) => ({
      ...prevState,
      invoiceNumber: form.values.invoiceNumber,
      memo: form.values.memo,
      notes: form.values.notes,
    }));
    return { invoiceNumber: form.values.invoiceNumber, memo: form.values.memo };
  });

  handleStepChange((prev) => {
    form.validate();

    if (!form.isValid()) {
      return false;
    }

    setState({
      ...prev,
      invoiceNumber: form.values.invoiceNumber,
      memo: form.values.memo,
      notes: form.values.notes,
    });
    return true;
  });

  const textareaStyles = {
    input: {
      height: 90,
    },
  };

  return (
    <InvoiceWizard.Step hideBack hideNext>
      <Stack spacing={24}>
        <FlexbaseInput
          disabled={!isInvoiceDraft || isActionDisabled}
          label={<TextInputLabel label="Invoice number" optional />}
          placeholder="Invoice number"
          autoFocus
          maxLength={50}
          {...form.getInputProps('invoiceNumber')}
        />
        <Stack spacing="xxs">
          <Textarea
            styles={textareaStyles}
            label={<TextInputLabel label="External memo (max 10 characters)" />}
            placeholder="Memo"
            maxLength={10}
            {...form.getInputProps('memo')}
            disabled={isActionDisabled}
          />
          <Text size="xs" c="neutral.6">
            Sent to recipient&apos;s bank
          </Text>
        </Stack>
        <Textarea
          styles={textareaStyles}
          label={<TextInputLabel label="Internal notes" optional />}
          placeholder="Internal notes"
          {...form.getInputProps('notes')}
          disabled={!isInvoiceDraft}
        ></Textarea>
      </Stack>
    </InvoiceWizard.Step>
  );
};

const TextInputLabel = ({
  label,
  optional,
}: {
  label: string;
  optional?: boolean;
}) => {
  return (
    <Group spacing="xxs">
      <Text>{label}</Text>
      {optional && <Text c="neutral.6">(optional)</Text>}
    </Group>
  );
};

AdditionalInformationStep.stepId = 'additional-information';

export default AdditionalInformationStep;
