import { Outlet } from 'react-router-dom';
import { NavTabContainer } from '@common/tabs/nav-tab-container';
import BillpaySummary from './billpay-summary';
import ActionRequiredBadge from './billpay-action-required-badge';

const BillpayPage = () => {
  const BILLPAY_TABS = [
    { label: 'Bills', route: '/bill-pay/bills' },
    {
      label: 'Action required',
      route: '/bill-pay/action-required',
      count: <ActionRequiredBadge />,
    },
    {
      label: 'Payments',
      route: '/bill-pay/payments',
    },
    {
      label: 'Vendors & recipients',
      route: '/bill-pay/vendors',
    },
  ];

  return (
    <>
      <BillpaySummary />
      <NavTabContainer tabs={BILLPAY_TABS}>
        <Outlet />
      </NavTabContainer>
    </>
  );
};

export default BillpayPage;
